import React from 'react';

import Comment from '../../components/auction/Comment';
import Bid from '../../components/auction/Bid';
import ViewMoreContainer from '../common/ViewMoreContainer';
import AuctionSystemMessage from '../auction/AuctionSystemMessage';

function BidsAndComments({
    bids,
    comments,
    systemMessages,
    sellerUsername,
    replyComment,
    anchorId,
}) {
    let items = <div></div>;

    let _comments = [];
    let _bids = [];
    let _system = [];

    if (comments) {
        _comments = comments.map((item) => (
            <Comment
                item={item}
                sellerUsername={sellerUsername}
                key={`comment-${item.id}`}
                replyComment={replyComment}
                anchorId={anchorId}
            />
        ));
    }

    if (bids) {
        _bids = bids.map((item, i) => (
            <Bid item={item} key={`bid-${item.id}`} i={i} />
        ));
    }

    if (systemMessages) {
        _system = systemMessages.map((item) => (
            <AuctionSystemMessage item={item} key={`system-${item.id}`} />
        ));
    }

    // Combine and sort
    items = _comments.concat(_bids).concat(_system);
    items.sort((a, b) => {
        const pinA = a.props.item.pinned === true;
        const pinB = b.props.item.pinned === true;

        // Majority of items will be sorted by their createdAt date
        if (pinA === pinB) {
            return (
                new Date(b.props.item.createdAt) -
                new Date(a.props.item.createdAt)
            );
        }

        // Pin item if needed (system messages)
        return pinB - pinA;
    });

    return (
        <ViewMoreContainer height={500} expanded={anchorId !== null}>
            {items}
        </ViewMoreContainer>
    );
}

export default React.memo(BidsAndComments);
