import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import axios from 'axios';

import PaymentMethodCard from '../../payment/PaymentMethodCard';
import AddPaymentModal from '../../payment/AddPaymentModal';

import CloseIcon from '@mui/icons-material/Close';
import PopupForm from '../../common/PopupForm';
import Loading from '../../common/Loading';
import ClientAccountManager from '../../payment/ClientAccountManager';

function PaymentInfo() {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState();
    const [showAddMethod, setShowAddMethod] = useState(false);

    const [coupons, setCoupons] = useState();

    const [methodToRemove, setMethodToRemove] = useState();
    const [showRemove, setShowRemove] = useState(false);
    const [removeErrors, setRemoveErrors] = useState(false);
    const [removeProcessing, setRemoveProcessing] = useState(false);

    const [ready, setReady] = useState(false);

    useEffect(() => {
        getPaymentMethods();

        // Get coupon info
        axios
            .post(`/coupon/getUser`)
            .then((res) => {
                setCoupons(res.data);
            })
            .catch(function () {});
    }, []);

    const getPaymentMethods = () => {
        setPaymentMethod(null);

        axios
            .get(`/stripe/methods`)
            .then((res) => {
                if (res.data.default) {
                    setPaymentMethod(res.data.default);
                } else if (res.data.methods.length > 0) {
                    setPaymentMethod(res.data.methods[0]);
                }
                setPaymentMethods(res.data.methods);

                setReady(true);
            })
            .catch(function () {});
    };

    const addPaymentMethodSuccess = () => {
        getPaymentMethods();
        setShowAddMethod(false);
    };

    const handleRemoveMethod = () => {
        setRemoveProcessing(true);

        axios
            .post(`/stripe/removeMethod`, { id: methodToRemove.id })
            .then(function () {
                getPaymentMethods();
                setMethodToRemove(null);
                setShowRemove(false);
            })
            .catch(function () {
                setRemoveErrors({
                    server: 'Error: Failed to remove payment method.',
                });
            })
            .finally(function () {
                setRemoveProcessing(false);
            });
    };

    if (ready) {
        return (
            <Box>
                <Box>
                    <Typography
                        fontSize={20}
                        fontWeight="medium"
                        marginBottom={2}
                        marginTop={3}
                    >
                        Credit Cards
                    </Typography>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        sx={{
                            flexDirection: { xs: 'column', lg: 'row' },
                            flexWrap: 'wrap',
                        }}
                    >
                        <Box
                            sx={{
                                marginRight: { xs: 0, lg: 3 },
                                width: 300,
                                minWidth: 300,
                                paddingBottom: 3,
                            }}
                        >
                            <Box
                                height={125}
                                border="1px solid #ddd"
                                position="relative"
                            >
                                <DefaultPaymentMethod
                                    paymentMethod={paymentMethod}
                                    setShowAddMethod={setShowAddMethod}
                                />
                                <Typography
                                    position="absolute"
                                    top={-10}
                                    left={10}
                                    backgroundColor="white"
                                    paddingLeft={1}
                                    paddingRight={1}
                                    fontSize={14}
                                    color="text.light"
                                    letterSpacing={0.25}
                                >
                                    Default Payment Method
                                </Typography>
                            </Box>
                            {paymentMethod && (
                                <NewCard setShowAddMethod={setShowAddMethod} />
                            )}
                        </Box>
                        <PaymentMethodList
                            paymentMethods={paymentMethods}
                            setMethodToRemove={setMethodToRemove}
                            setShowRemove={setShowRemove}
                        />
                        <AddPaymentModal
                            show={showAddMethod}
                            onClose={() => setShowAddMethod(false)}
                            success={addPaymentMethodSuccess}
                        />
                        <PopupForm
                            title="Remove payment method?"
                            body={
                                <Box display="flex" flexDirection="column">
                                    <div>
                                        Are you sure you want to remove this
                                        payment method from your account?
                                    </div>
                                    <Box
                                        marginTop={3}
                                        marginBottom={1}
                                        marginLeft="auto"
                                        marginRight="auto"
                                    >
                                        <PaymentMethodCard
                                            paymentMethod={methodToRemove}
                                        />
                                    </Box>
                                </Box>
                            }
                            action={handleRemoveMethod}
                            show={showRemove}
                            setShow={setShowRemove}
                            yes="Remove"
                            processing={removeProcessing}
                            errors={removeErrors}
                            setErrors={setRemoveErrors}
                        />
                    </Grid>
                </Box>

                <Box>
                    <Typography
                        fontSize={20}
                        fontWeight="medium"
                        marginBottom={2}
                        marginTop={3}
                    >
                        Bank Accounts
                    </Typography>
                    <ClientAccountManager />
                </Box>

                {/* <Box>
                    <Typography
                        fontSize={20}
                        fontWeight="medium"
                        marginBottom={2}
                        marginTop={3}
                    >
                        Coupons
                    </Typography>
                    <CouponInput coupons={coupons} setCoupons={setCoupons} />
                    <CardList
                        items={coupons}
                        Component={CouponCard}
                        xs={12}
                        md={12}
                        lg={12}
                        padding={0}
                        spacing={1.5}
                    >
                        <NoData description="You have no coupons to display." />
                    </CardList>
                </Box> */}
            </Box>
        );
    } else {
        return <Loading height={50} />;
    }
}

const NewCard = ({ setShowAddMethod }) => {
    return (
        <Box display="flex" height={50} justifyContent="center" marginTop={1.5}>
            <Button
                margin="auto"
                onClick={() => setShowAddMethod(true)}
                variant="dark"
                fullWidth
            >
                Add Card
            </Button>
        </Box>
    );
};

const DefaultPaymentMethod = ({ paymentMethod, setShowAddMethod }) => {
    if (paymentMethod) {
        return (
            <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop={0.5}
            >
                <PaymentMethodCard paymentMethod={paymentMethod} />
            </Box>
        );
    } else {
        return (
            <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Button onClick={() => setShowAddMethod(true)} variant="dark">
                    Add Card
                </Button>
            </Box>
        );
    }
};

const PaymentMethodList = ({
    paymentMethods,
    setMethodToRemove,
    setShowRemove,
}) => {
    return (
        <Box display="flex" flexDirection="column">
            {paymentMethods.map((method) => (
                <Box
                    key={method.id}
                    display="flex"
                    justifyContent="space-between"
                    maxWidth={325}
                >
                    <PaymentMethodCard paymentMethod={method} />
                    <Box width={25} height={25} marginLeft={2}>
                        <IconButton
                            sx={{ color: 'dark' }}
                            onClick={() => {
                                setMethodToRemove(method);
                                setShowRemove(true);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default PaymentInfo;
