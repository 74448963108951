import moment from 'moment';

const fontSize = (font, string, width) => {
    string = string.split(' ').join('');
    const length = string?.length || 1;
    const maxFont = width / length;

    return font <= maxFont ? font : font / 1.75;
};

const stringToCamel = (str) => {
    var split = str.toLowerCase().split(' ');
    for (let i = 1; i < split.length; i++) {
        split[i] = split[i].charAt(0).toUpperCase() + split[i].substring(1);
    }
    split = split.join('');
    return split;
};

const setTitle = (title) => {
    if (title) {
        document.title = `${title} | Upside`;
    }
};

function formatText(value, type, emptyString = '---') {
    // Don't format if no value available
    if (!value) {
        return emptyString;
    }

    switch (type) {
        // Money
        case formatTypes.MONEY:
            return `$${value.toLocaleString()}`;

        // Date
        case formatTypes.DATE:
            return moment(value).format('YYYY-MM-DD');

        // Date Time
        case formatTypes.DATETIME:
            return moment(value).format('YYYY-MM-DD h:mm:ss A Z');

        // None
        default:
            return value;
    }
}

const formatTypes = {
    MONEY: 1,
    DATE: 2,
    DATETIME: 3,
};

export { fontSize, stringToCamel, setTitle, formatText, formatTypes };
