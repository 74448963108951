import React, { useState } from 'react';
import {
    useStripe,
    useElements,
    PaymentElement,
} from '@stripe/react-stripe-js';
import { Spinner } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Typography } from '@mui/material';

const SetupForm = ({ success }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setProcessing(true);

        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: window.location.href,
            },
        });

        // Error
        if (error) {
            setErrorMessage(error.message);
        }
        // Success
        else {
            success();
        }

        setProcessing(false);
    };

    return (
        <form
            style={{
                position: 'relative',
            }}
            onSubmit={handleSubmit}
        >
            <div style={{ minHeight: 279 }}>
                <div
                    style={{
                        zIndex: 500,
                        position: 'relative',
                        backgroundColor: 'white',
                    }}
                >
                    <PaymentElement />
                </div>
            </div>
            <CircularProgress
                style={{
                    position: 'absolute',
                    top: '35%',
                    left: `calc(50% - 20px)`,
                }}
            />
            <Button
                variant="dark"
                fullWidth
                style={{
                    marginTop: 30,
                }}
                onClick={handleSubmit}
                disabled={!stripe || !elements}
            >
                {processing ? (
                    <Spinner size="sm" animation="border" />
                ) : (
                    'Submit'
                )}
            </Button>

            {errorMessage && (
                <Typography marginTop={2} color="red">
                    {errorMessage}
                </Typography>
            )}
        </form>
    );
};

export default SetupForm;
