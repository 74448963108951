import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { Box } from '@mui/material';

function CollapseContainer({
    children,
    title,
    expandDefault = true,
    height,
    backgroundColor = 'black',
    containerBackgroundColor = '#eee',
    color = 'white',
}) {
    const [expand, setExpand] = useState(expandDefault);

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        float: 'left',
        color: color,
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    return (
        <Box
            className="collapse-menu"
            backgroundColor={containerBackgroundColor}
        >
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: backgroundColor,
                    color: color,
                    height: '40px',
                }}
                className="clickable"
                onClick={() => setExpand(!expand)}
            >
                <h5
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        marginLeft: '10px',
                    }}
                >
                    {title}
                </h5>
                <ExpandMore
                    expand={expand}
                    aria-expanded={expand}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </Box>
            <Collapse
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    overflow: 'auto',
                    maxHeight: height || 'auto',
                }}
                in={expand}
                timeout="auto"
                unmountOnExit
            >
                {children}
            </Collapse>
        </Box>
    );
}

export default CollapseContainer;
