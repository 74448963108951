import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';

import ModalCard from '../modal/ModalCard';
import { useYup } from '../../scripts/cms';
import InputForm from './InputForm';

function PopupForm({
    title,
    body,
    yes = 'Yes',
    action,
    show,
    setShow,
    inputs = [],
    errors,
    setErrors = () => {},
    processing,
    width = 350,
    height,
    defaults,
    disabled,
    children,
    delay = 500,
    _yup,
}) {
    const [confirmationAllowed, setConfirmationAllowed] = useState(false);

    const yup = useYup(inputs, defaults, _yup);

    useEffect(() => {
        setErrors();
    }, [show, setErrors]);

    useEffect(() => {
        // Reset inputs when defaults change
        yup.reset();

        // Update defaults
        if (defaults) {
            for (let attribute in defaults) {
                yup.setValue(attribute, defaults[attribute]);
            }
        }
    }, [defaults]);

    useEffect(() => {
        // Single error (convert to server)
        if (typeof errors === 'string') {
            setErrors({ server: errors });
        }
        // Multiple errors
        else {
            for (let key in errors) {
                yup.setError(key, { message: errors[key] });
            }
        }
    }, [errors]);

    // Delay before allowing the user to press the "YES" button
    useEffect(() => {
        setConfirmationAllowed(false);
        const timeout = setTimeout(() => {
            setConfirmationAllowed(true);
        }, delay);
        return () => clearTimeout(timeout);
    }, [show]);

    const onClose = () => {
        setShow(false);
    };

    return (
        <Modal open={show} onClose={onClose} className="modal-center">
            <ModalCard
                show={show}
                onClose={onClose}
                width={width}
                height={height}
            >
                <Typography fontSize={25} fontWeight="medium" paddingBottom={2}>
                    {title}
                </Typography>
                {body && (
                    <Typography
                        fontSize={16}
                        letterSpacing={0.5}
                        lineHeight={1.75}
                        component="div"
                        marginBottom={1}
                    >
                        {body}
                    </Typography>
                )}

                <Box
                    display="flex"
                    flexDirection="column"
                    component="form"
                    onSubmit={yup.handleSubmit(() => action(yup.getValues()))}
                >
                    {/* <Divider key={i} sx={{ marginTop: 2, marginBottom: 0 }} /> */}
                    {inputs.length > 0 && (
                        <Box marginTop={1}>
                            <InputForm inputs={inputs} yup={yup} />
                        </Box>
                    )}

                    {children && <Box marginTop={2}>{children}</Box>}

                    {errors?.server && (
                        <Typography marginTop={2} color="red">
                            {errors.server}
                        </Typography>
                    )}

                    {action && (
                        <Button
                            variant="dark"
                            fullWidth
                            disabled={processing || !confirmationAllowed}
                            sx={{ marginTop: 2 }}
                            type="submit"
                        >
                            {yes}
                        </Button>
                    )}
                </Box>
            </ModalCard>
        </Modal>
    );
}

export default PopupForm;
