import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, Grid, Link, Typography } from '@mui/material';
import Input from './Input';
import axios from 'axios';
import PopupForm from '../../common/PopupForm';
import Loading from '../../common/Loading';
import { provinces } from '../../../scripts/validation';
import { toast } from 'react-toastify';

function BrokerInfo() {
    const [broker, setBroker] = useState();
    const [requests, setRequests] = useState([]);
    const [clients, setClients] = useState([]);
    const [brokers, setBrokers] = useState([]);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState();
    const [dialogBody, setDialogBody] = useState();
    const [dialogAction, setDialogAction] = useState();

    useEffect(() => {
        axios
            .get(`/broker/getPrivate`)
            .then((res) => {
                setBroker(res.data);
            })
            .catch(function () {});

        axios
            .get(`/broker/getPairingRequests`)
            .then((res) => {
                setRequests(res.data);
            })
            .catch(function () {});

        axios
            .get(`/broker/getClients`)
            .then((res) => {
                setClients(res.data);
            })
            .catch(function () {});

        axios
            .get(`/broker/getBrokers`)
            .then((res) => {
                setBrokers(res.data);
            })
            .catch(function () {});
    }, []);

    // Display loading indication
    if (broker === null || broker === undefined) {
        return <Loading height={50} />;
    }

    const handleApproveClient = (pairing) => {
        showDialog(
            'Approve Client Request?',
            <div>
                Are you sure you want to register{' '}
                <strong>{`${pairing.user.username}`}</strong> as your client?
            </div>,
            () => {
                axios
                    .post(`/broker/approvePairing/${pairing.userId}`)
                    .then(() => {
                        addClient(pairing);
                        removeRequest(pairing);

                        setShowConfirmationDialog(false);
                    })
                    .catch(function () {});
            }
        );
    };

    const handleRejectClient = (pairing) => {
        const body = pairing.approved ? (
            <div>
                Are you sure you want to remove{' '}
                <strong>{`${pairing.user.username}`}</strong> from your list of
                clients?
            </div>
        ) : (
            <div>
                Are you sure you want to reject the pairing request from{' '}
                <strong>{`${pairing.user.username}`}</strong>?
            </div>
        );
        showDialog('Remove Client?', body, () => {
            axios
                .post(`/broker/rejectPairing/${pairing.userId}`)
                .then(() => {
                    pairing.approved
                        ? removeClient(pairing)
                        : removeRequest(pairing);

                    setShowConfirmationDialog(false);
                })
                .catch(function (err) {
                    toast.error(err?.response?.data || err?.message);
                    setShowConfirmationDialog(false);
                });
        });
    };

    const handleRemoveBroker = (broker) => {
        showDialog(
            'Remove Broker?',
            <div>
                Are you sure you want to remove{' '}
                <strong>{`${broker.user.username}`}</strong> from your list of
                brokers?
            </div>,
            () => {
                axios
                    .post(`/broker/removeBroker/${broker.id}`)
                    .then(() => {
                        removeBroker(broker);

                        setShowConfirmationDialog(false);
                    })
                    .catch(function (err) {
                        toast.error(err?.response?.data || err?.message);
                        setShowConfirmationDialog(false);
                    });
            }
        );
    };

    const addClient = (client) => {
        const _clients = [...clients];
        const _client = { ...client };
        _client.approved = true;

        _clients.unshift(_client);
        setClients(_clients);
    };

    const removeRequest = (item) => {
        setRequests(requests.filter((request) => request.id !== item.id));
    };

    const removeClient = (item) => {
        setClients(clients.filter((client) => client.id !== item.id));
    };

    const removeBroker = (item) => {
        setBrokers(brokers.filter((broker) => broker.id !== item.id));
    };

    const showDialog = (title, body, action) => {
        setShowConfirmationDialog(true);
        setDialogTitle(title);
        setDialogBody(body);
        setDialogAction(() => action);
    };

    // Approved as a broker
    if (broker?.approved) {
        // Active
        if (!broker.disabled) {
            return (
                <Box>
                    {/* <YourDetails broker={broker} setBroker={setBroker} /> */}
                    <YourClients
                        clients={clients}
                        handleApproveClient={handleApproveClient}
                        handleRejectClient={handleRejectClient}
                    />
                    <ClientRequests
                        requests={requests}
                        handleApproveClient={handleApproveClient}
                        handleRejectClient={handleRejectClient}
                    />
                    <hr />
                    <YourBrokers
                        brokers={brokers}
                        handleRemoveBroker={handleRemoveBroker}
                    />
                    <PopupForm
                        title={dialogTitle}
                        body={dialogBody}
                        action={dialogAction}
                        show={showConfirmationDialog}
                        setShow={setShowConfirmationDialog}
                    />
                </Box>
            );
        }
        // Disabled
        else {
            return (
                <Box>
                    <YourBrokers
                        brokers={brokers}
                        handleRemoveBroker={handleRemoveBroker}
                    />
                    <PopupForm
                        title={dialogTitle}
                        body={dialogBody}
                        action={dialogAction}
                        show={showConfirmationDialog}
                        setShow={setShowConfirmationDialog}
                    />
                </Box>
            );
        }
    }

    // Not a broker, or not approved (show submission form)
    return (
        <Box>
            <YourBrokers
                brokers={brokers}
                handleRemoveBroker={handleRemoveBroker}
            />
            <BrokerRegistration />
            <PopupForm
                title={dialogTitle}
                body={dialogBody}
                action={dialogAction}
                show={showConfirmationDialog}
                setShow={setShowConfirmationDialog}
            />
        </Box>
    );
}

const Client = ({ pairing, handleApproveClient, handleRejectClient }) => {
    const username = pairing.user.username;
    const fullName = pairing.user.firstName
        ? `${pairing.user.firstName} ${pairing.user.lastName}`
        : null;
    return (
        <Box
            display="flex"
            backgroundColor="#f4f4f4"
            padding={1}
            paddingLeft={2}
            marginBottom={1}
        >
            <Typography
                display="flex"
                margin="auto auto auto 0"
                component={Link}
                href={`/user/${pairing.user.username}/purchases`}
                className="link"
                color="dark"
            >
                {`${username}${fullName ? ` - ${fullName}` : ''}`}
            </Typography>
            {!pairing.approved && (
                <Button
                    width={80}
                    variant="blue"
                    size="tiny"
                    style={{ marginRight: 5 }}
                    onClick={() => handleApproveClient(pairing)}
                >
                    Accept
                </Button>
            )}
            <Button
                width={80}
                variant="danger"
                size="tiny"
                onClick={() => handleRejectClient(pairing)}
            >
                {pairing.approved ? 'Remove' : 'Reject'}
            </Button>
        </Box>
    );
};

const Broker = ({ broker, handleRemoveBroker }) => {
    return (
        <Box
            display="flex"
            backgroundColor="#f4f4f4"
            padding={1}
            marginBottom={1}
        >
            <Typography
                display="flex"
                margin="auto auto auto 0"
                //component={Link}
                //href={`/user/${broker.user.username}/comments`}
                //className="link"
                color="dark"
            >
                {broker.user.username}
            </Typography>
            <Button
                width={80}
                variant="danger"
                size="tiny"
                onClick={() => handleRemoveBroker(broker)}
            >
                Remove
            </Button>
        </Box>
    );
};

const YourBrokers = ({ brokers, handleRemoveBroker }) => {
    const [showAdd, setShowAdd] = useState(false);

    const handleSubmitBroker = (form) => {
        axios
            .post(`/broker/submitPairingRequest`, form)
            .then((res) => {
                setShowAdd(false);
            })
            .catch(function (err) {});
    };

    return (
        <Box marginBottom={4}>
            <Typography fontSize={20} fontWeight="medium" marginBottom={1}>
                Current Brokers
            </Typography>
            {brokers.map((broker) => (
                <Broker
                    key={broker.id}
                    broker={broker}
                    handleRemoveBroker={handleRemoveBroker}
                />
            ))}
            {brokers.length === 0 && (
                <Typography color="text.secondary">
                    You have no brokers to display.
                </Typography>
            )}

            <Grid container gap={1} spacing={1} marginTop={2}>
                <Button item variant="dark" onClick={() => setShowAdd(true)}>
                    Add Broker
                </Button>
            </Grid>

            <PopupForm
                title="Add a Broker"
                yes="Submit"
                show={showAdd}
                setShow={setShowAdd}
                action={handleSubmitBroker}
                inputs={[[{ id: 'email', label: 'Email Address' }]]}
                body={
                    <div>
                        <div style={{ marginBottom: 8 }}>
                            Please enter the email address of your broker.
                        </div>
                        <div>
                            If the email address is correct, your broker will be
                            notified and can approve the request in their
                            profile.
                        </div>
                    </div>
                }
            />
        </Box>
    );
};

const ClientRequests = ({
    requests,
    handleApproveClient,
    handleRejectClient,
}) => {
    return (
        <Box marginBottom={4}>
            <Typography fontSize={20} fontWeight="medium" marginBottom={1}>
                Client Requests
            </Typography>
            {requests.map((pairing) => (
                <Client
                    key={pairing.id}
                    pairing={pairing}
                    handleApproveClient={handleApproveClient}
                    handleRejectClient={handleRejectClient}
                />
            ))}
            {requests.length === 0 && (
                <Typography color="text.secondary">
                    You have no pending pairing requests.
                </Typography>
            )}
        </Box>
    );
};

/*const YourDetails = ({ broker, setBroker }) => {
    const [showForm, setShowForm] = useState(false);
    const [errors, setErrors] = useState();
    const [processing, setProcessing] = useState(false);

    const handleSubmit = (form) => {
        setErrors(null);
        setProcessing(true);

        axios
            .post(`/broker/update`, form)
            .then((res) => {
                const { firstName, middleName, lastName } = res.data;

                setShowForm(false);
                setBroker({
                    firstName,
                    middleName,
                    lastName,
                });
            })
            .catch(function (err) {
                setErrors(err?.response?.data?.errors);
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    return (
        <Grid container spacing={1} marginBottom={4}>
            <Input label="First Name" value={broker?.firstName} md={4} />
            <Input label="Middle Name" value={broker?.middleName} md={4} />
            <Input label="Last Name" value={broker?.lastName} md={4} />
            <Button variant="dark" onClick={() => setShowForm(true)}>
                Edit
            </Button>
            <PopupForm
                title="Edit Broker Information"
                action={handleSubmit}
                show={showForm}
                setShow={setShowForm}
                yes="Save"
                inputs={[
                    [
                        {
                            id: 'First Name',
                            required: true,
                            size: 12,
                        },
                    ],
                    [
                        {
                            id: 'Middle Name',
                            required: false,
                            size: 12,
                        },
                    ],
                    [
                        {
                            id: 'Last Name',
                            required: true,
                            size: 12,
                        },
                    ],
                ]}
                errors={errors}
                setErrors={setErrors}
                processing={processing}
                width={300}
                defaults={broker}
            />
        </Grid>
    );
};*/

const YourClients = ({ clients, handleApproveClient, handleRejectClient }) => {
    return (
        <Box marginBottom={4}>
            <Typography fontSize={20} fontWeight="medium" marginBottom={1}>
                Clients
            </Typography>
            {clients.map((pairing) => (
                <Client
                    key={pairing.id}
                    pairing={pairing}
                    handleApproveClient={handleApproveClient}
                    handleRejectClient={handleRejectClient}
                />
            ))}
            {clients.length === 0 && (
                <Typography color="text.secondary">
                    You have no clients to display.
                </Typography>
            )}
        </Box>
    );
};

const BrokerRegistration = () => {
    const [loading, setLoading] = useState(true);
    const [showRegister, setShowRegister] = useState(false);
    const [broker, setBroker] = useState();

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    useEffect(() => {
        axios
            .get(`/broker/getRegistration`)
            .then((res) => {
                setBroker(res.data);
            })
            .catch(function () {})
            .finally(function () {
                setLoading(false);
            });
    }, []);

    const handleSubmitRegistration = (form) => {
        axios
            .post(`/broker/submitRegistration`, form)
            .then(() => {
                setBroker({ ...form, response: null });
                setShowRegister(false);
            })
            .catch(function (err) {
                toast.error(
                    err?.response?.data?.message ||
                        'Unable to process your request.'
                );
            });
    };

    const handleCancelRegistration = () => {
        axios
            .post(`/broker/cancelRegistration`)
            .then(() => {
                setShowConfirmDelete(false);
                setBroker();
            })
            .catch(function (err) {});
    };

    // Needed a state update otherwise the masked inputs wouldn't render with a value
    if (loading) {
        return <Loading height={50} />;
    }

    return (
        <Box maxWidth={700}>
            <Typography fontSize={20} fontWeight="medium" marginBottom={1}>
                Broker Registration
            </Typography>
            <Typography marginBottom={2}>
                Submit a request to become a broker on Upside.
            </Typography>

            {broker?.response && (
                <Box
                    marginBottom={2}
                    display="flex"
                    flex={1}
                    padding={1}
                    backgroundColor="background.danger"
                    border="2px dashed #C40B37"
                >
                    <Typography
                        marginRight={1}
                        fontWeight="bold"
                        color="status.danger"
                    >
                        Response:
                    </Typography>
                    <Typography>{broker.response}</Typography>
                </Box>
            )}
            {broker && !broker?.response && (
                <Box
                    marginBottom={2}
                    display="flex"
                    flex={1}
                    padding={1}
                    backgroundColor="background.info"
                    border="2px dashed #1874FF"
                >
                    <Typography
                        marginRight={1}
                        fontWeight="bold"
                        color="status.info"
                    >
                        Response Pending
                    </Typography>
                </Box>
            )}

            <Grid container gap={1} spacing={1}>
                <Grid item>
                    <Button
                        item
                        variant="dark"
                        onClick={() => setShowRegister(true)}
                    >
                        {broker ? 'Edit Registration' : 'Register Now'}
                    </Button>
                </Grid>
                {broker && (
                    <Grid item>
                        <Button
                            item
                            variant="danger"
                            onClick={() => setShowConfirmDelete(true)}
                        >
                            Cancel Registration
                        </Button>
                    </Grid>
                )}
            </Grid>

            <PopupForm
                title="Broker Registration"
                yes="Submit"
                width={500}
                show={showRegister}
                setShow={setShowRegister}
                action={handleSubmitRegistration}
                inputs={[
                    [
                        { id: 'brokerage' },
                        {
                            id: 'brokeragePhone',
                            label: 'Brokerage Phone Number',
                            mask: '+99999999999',
                        },
                    ],
                    [
                        {
                            id: 'brokerageFax',
                            label: 'Brokerage Fax Number',
                            optional: true,
                        },
                        {
                            id: 'email',
                            label: 'Broker Email',
                        },
                    ],
                    [
                        {
                            id: 'title',
                            label: 'Broker Title',
                        },
                        { id: 'firstName' },
                    ],
                    [{ id: 'middleName', optional: true }, { id: 'lastName' }],
                    [
                        { id: 'country', disabled: true },
                        {
                            id: 'province',
                            children: provinces,
                        },
                    ],
                    [
                        { id: 'city' },
                        {
                            id: 'postalCode',
                            mask: 'a9a 9a9',
                        },
                    ],
                    [
                        {
                            id: 'streetAddress',
                            label: 'Address',
                        },
                        { id: 'unit', optional: true },
                    ],
                    [
                        {
                            id: 'adminEmail',
                        },
                        {
                            id: 'adminFirstName',
                        },
                    ],
                    [
                        {
                            id: 'adminMiddleName',
                            optional: true,
                        },
                        {
                            id: 'adminLastName',
                        },
                    ],
                ]}
                defaults={
                    broker || {
                        country: 'Canada',
                        province: 'ON',
                    }
                }
            />
            <PopupForm
                title="Delete broker request submission?"
                body={
                    <div>
                        Are you sure you want to delete your broker submission?
                    </div>
                }
                action={() => handleCancelRegistration()}
                show={showConfirmDelete}
                setShow={setShowConfirmDelete}
            />
        </Box>
    );
};

export default BrokerInfo;
