import React from 'react';

import ProjectForm from '../components/cms/ProjectForm';
import AddressForm from '../components/cms/AddressForm';
import ModelForm from '../components/cms/ModelForm';
import AuctionForm from '../components/cms/AuctionForm';
import DeveloperForm from '../components/cms/DeveloperForm';
import BuilderForm from '../components/cms/BuilderForm';
import AmenityForm from '../components/cms/AmenityForm';
import PropertyTypeForm from '../components/cms/PropertyTypeForm';
import CouponForm from '../components/cms/CouponForm';
import UpgradeTypeForm from '../components/cms/UpgradeTypeForm';

import NotFound from './NotFound';

import { Routes, Route } from 'react-router-dom';

import {
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import EventNote from '@mui/icons-material/EventNote';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ImageIcon from '@mui/icons-material/Image';
import BuilderIcon from '@mui/icons-material/Groups';
import ProjectIcon from '@mui/icons-material/Apartment';
import AmenityIcon from '@mui/icons-material/EmojiEmotions';
import PropertyTypeIcon from '@mui/icons-material/Home';
import LocalOffer from '@mui/icons-material/LocalOffer';
import ModelIcon from '@mui/icons-material/AccountTree';
import PostPurchaseIcon from '@mui/icons-material/DocumentScanner';
import UpgradeIcon from '@mui/icons-material/Upgrade';

import './Administration.css';
import Dashboard from './administration/Dashboard';
import Project from './administration/Project';
import Auctions from './administration/Auctions';
import Addresses from './administration/Addresses';
import Projects from './administration/Projects';
import Builders from './administration/Builders';
import Amenities from './administration/Amenities';
import PropertyTypes from './administration/PropertyTypes';
import Users from './administration/Users';
import Invite from './administration/Invite';
import Media from './administration/Media';
import Developers from './administration/Developers';
import Coupons from './administration/Coupons';
import Models from './administration/Models';
import PostPurchase from './administration/PostPurchase';
import UpgradeTypes from './administration/UpgradeTypes';

import { setTitle } from '../scripts/style';

function Administration() {
    setTitle('Administration');

    const config = [
        {
            title: 'Dashboard',
            slug: '',
            components: {
                Main: Dashboard,
                Icon: PostPurchaseIcon,
            },
        },
        {
            title: 'Purchase Tracker',
            slug: 'post-purchase',
            components: {
                Main: PostPurchase,
                Form: PostPurchase,
                Icon: PostPurchaseIcon,
            },
            view: 'slug',
        },
        {
            title: 'Users',
            slug: 'users',
            components: {
                Main: Users,
                Icon: AccountCircleIcon,
            },
        },
        {
            title: 'Invite',
            slug: 'invite',
            components: {
                Main: Invite,
                Icon: AccountCircleIcon,
            },
        },
        {
            title: 'Media',
            slug: 'media',
            components: {
                Main: Media,
                Icon: ImageIcon,
            },
            divider: true,
        },
        {
            title: 'Listings',
            slug: 'listings',
            components: {
                Main: Auctions,
                Form: AuctionForm,
                Icon: EventNote,
            },
            create: 'slug',
        },
        {
            title: 'Models',
            slug: 'models',
            components: {
                Main: Models,
                Form: ModelForm,
                Icon: ModelIcon,
            },
            create: 'id',
        },
        {
            title: 'Addresses',
            slug: 'addresses',
            components: {
                Main: Addresses,
                Form: AddressForm,
                Icon: HomeIcon,
            },
            create: 'id',
        },
        {
            title: 'Projects',
            slug: 'projects',
            components: {
                Main: Projects,
                Form: ProjectForm,
                Icon: ProjectIcon,
            },
            create: 'slug',
        },
        {
            title: 'Developers',
            slug: 'developers',
            components: {
                Main: Developers,
                Form: DeveloperForm,
                Icon: AccountCircleIcon,
            },
            create: 'username',
            divider: true,
        },
        {
            title: 'Builders',
            slug: 'builders',
            components: {
                Main: Builders,
                Form: BuilderForm,
                Icon: BuilderIcon,
            },
            create: 'id',
        },
        {
            title: 'Amenities',
            slug: 'amenities',
            components: {
                Main: Amenities,
                Form: AmenityForm,
                Icon: AmenityIcon,
            },
            create: 'id',
        },
        {
            title: 'Property Types',
            slug: 'propertyTypes',
            components: {
                Main: PropertyTypes,
                Form: PropertyTypeForm,
                Icon: PropertyTypeIcon,
            },
            create: 'id',
        },
        {
            title: 'Upgrade Types',
            slug: 'upgradeTypes',
            components: {
                Main: UpgradeTypes,
                Form: UpgradeTypeForm,
                Icon: UpgradeIcon,
            },
            create: 'id',
        },
        {
            title: 'Coupons',
            slug: 'coupons',
            components: {
                Main: Coupons,
                Form: CouponForm,
                Icon: LocalOffer,
            },
            create: 'id',
        },
    ];

    return (
        <div className="admin-container">
            <Sidebar config={config} />
            <Routes>
                {config.map((item, i) => (
                    <Route key={i}>
                        <Route
                            exact
                            path={`/${item.slug}`}
                            element={<item.components.Main />}
                        />
                        {item.create && (
                            <>
                                <Route
                                    exact
                                    path={`/${item.slug}/create`}
                                    element={<item.components.Form />}
                                />
                                <Route
                                    exact
                                    path={`/${item.slug}/create/:${item.create}`}
                                    element={<item.components.Form />}
                                />
                            </>
                        )}
                        {item.view && (
                            <Route
                                exact
                                path={`/${item.slug}/:${item.view}`}
                                element={<item.components.Form />}
                            />
                        )}
                    </Route>
                ))}

                <Route exact path={`/project/:slug`} element={<Project />} />

                <Route exact path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

const NavItem = ({ item }) => {
    return (
        <>
            <ListItem disablePadding>
                <Link
                    href={`/administration/${item.slug}`}
                    className="admin-sidebar-link"
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <item.components.Icon />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </Link>
            </ListItem>

            {item.create && (
                <ListItem disablePadding>
                    <Link
                        href={`/administration/${item.slug}/create`}
                        className="admin-sidebar-link"
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <div className="admin-sidebar-sub">
                                    <AddIcon />
                                </div>
                            </ListItemIcon>
                            <ListItemText primary="Create" />
                        </ListItemButton>
                    </Link>
                </ListItem>
            )}

            {item.divider && <hr />}
        </>
    );
};

const Sidebar = ({ config }) => {
    return (
        <div className="admin-sidebar">
            <List>
                {config.map((item, i) => (
                    <NavItem key={i} item={item} />
                ))}
            </List>
        </div>
    );
};

export default Administration;
