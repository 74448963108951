import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import HTMLParse from './HTMLParse';
import Field from '../cms/Field';

function InputForm({
    body,
    inputs,
    yup,
    parentAttribute,
    index,
    editing,
    locked,
    slim,
}) {
    const isArray = index !== undefined;

    useEffect(() => {
        // Don't indicate form errors on a fresh load
        yup.clearErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getId(input) {
        const prefix = parentAttribute ? `${parentAttribute}.` : '';

        return isArray
            ? `${prefix}${index}.${input.id}`
            : `${prefix}${input.id}`;
    }

    return (
        <Box>
            <Typography component="div">
                <HTMLParse value={body} />
            </Typography>
            {inputs.map((inputGroup, i) => (
                <Box
                    key={i}
                    className="input-panel-container"
                    marginBottom={slim ? 0.5 : undefined}
                >
                    {inputGroup.map((input, j) => (
                        <Field
                            key={`${getId(input)}.${j}`}
                            id={getId(input)}
                            input={input}
                            yup={yup}
                            editing={editing}
                            parentAttribute={parentAttribute}
                            locked={locked}
                        />
                    ))}
                </Box>
            ))}
        </Box>
    );
}

export default InputForm;
