import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import InputForm from '../common/InputForm';
import { useEffect } from 'react';
import { inspectArrayWithString } from '../../scripts/cms';

function ToggleInputField({ input, yup, parentAttribute, locked }) {
    const { id, toggle } = input;

    const realToggleId = parentAttribute ? `${parentAttribute}.${id}` : id;
    const activeId = yup.watch(realToggleId);

    function handleToggle(toggledId) {
        // Only worry if the tab changed
        if (toggledId !== activeId) {
            // Update tab
            yup.setValue(realToggleId, toggledId);
        }
    }

    useEffect(() => {
        // Set the first tab as the default (enables conditional validation, only if it hasn't already been set)
        const existingTab = inspectArrayWithString(
            yup.getValues(),
            realToggleId
        );
        if (!existingTab) {
            yup.setValue(realToggleId, Object.keys(toggle)[0]);
        }
    }, []);

    return (
        <Box className="input-panel-item" marginBottom={2}>
            <Grid container flex={1} gap={1}>
                {Object.keys(toggle).map((item, i) => (
                    <ToggleGroup
                        input={toggle[item]}
                        yup={yup}
                        key={`${item}.${i}`}
                        handleToggle={handleToggle}
                        activeId={activeId}
                        locked={locked}
                    />
                ))}
            </Grid>

            {Object.keys(toggle).map((item, i) => (
                <Box
                    marginTop={2}
                    marginBottom={-5}
                    key={`${item}.${i}`}
                    sx={{
                        // Hide the untoggled element completely, without a react re-render
                        visibility: item !== activeId ? 'hidden' : undefined,
                        position: item !== activeId ? 'absolute' : undefined,
                        height: item !== activeId ? 0 : undefined,
                        overflow: item !== activeId ? 'hidden' : undefined,
                    }}
                >
                    <InputForm
                        yup={yup}
                        inputs={toggle[item].inputs}
                        parentAttribute={parentAttribute}
                        locked={locked}
                    />
                </Box>
            ))}
        </Box>
    );
}

function ToggleGroup({ input, handleToggle, activeId, locked }) {
    const { id, label } = input;

    const active = activeId === id;

    return (
        <Grid item flex={1}>
            <Button
                padding={2}
                fullWidth
                variant={active ? 'blue' : 'grey'}
                onClick={() => handleToggle(id)}
                size="small"
                disabled={!active && locked}
            >
                {label || id}
            </Button>
        </Grid>
    );
}

export default ToggleInputField;
