import SuccessIcon from '@mui/icons-material/CheckCircleRounded';
import DangerIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

const modalStatuses = {
    OK: { id: 1, value: 'ok' },
    INFO: { id: 2, value: 'info', icon: <InfoIcon />, colour: '#1874FF' },
    SUCCESS: {
        id: 3,
        value: 'success',
        icon: <SuccessIcon />,
        colour: '#14b157',
    },
    WARNING: { id: 4, value: 'warning' },
    DANGER: { id: 5, value: 'danger', icon: <DangerIcon />, colour: '#ed2e2e' },
};

export default modalStatuses;
