import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryCard from '../cards/CategoryCard';
import CardList from './CardList';

function CategoryList() {
    const [categories, setCategories] = useState();

    useEffect(() => {
        axios
            .get(`/propertyType/getAll`)
            .then((res) => {
                setCategories(res.data);
            })
            .catch(function () {});
    }, []);

    return (
        <CardList
            items={categories}
            Component={CategoryCard}
            lg={4}
            md={4}
            xs={12}
            padding={0}
            vertical={0}
            max={3}
            verticalAdjustment={45}
        />
    );
}

export default CategoryList;
