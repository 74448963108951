import React from 'react';
import moment from 'moment';

import Typography from '@mui/material/Typography';

import Amex from '../../assets/payment/card-amex.svg';
import MasterCard from '../../assets/payment/card-mastercard.svg';
import Visa from '../../assets/payment/card-visa.svg';
import Discover from '../../assets/payment/card-discover.png';
import JCB from '../../assets/payment/card-jcb.svg';
import UnionPay from '../../assets/payment/card-union.svg';
import Diners from '../../assets/payment/card-diners.svg';
//import Unknown from '../../assets/payment/card-unknown.svg';

function PaymentMethodCard({ paymentMethod }) {
    if (!paymentMethod) {
        return null;
    }

    const expiration = moment(
        `${paymentMethod.expYear}-${paymentMethod.expMonth}`,
        'YYYY-MM'
    );

    var brandText =
        paymentMethod.brand.charAt(0).toUpperCase() +
        paymentMethod.brand.slice(1);

    var image;
    switch (paymentMethod.brand) {
        case 'amex':
            image = Amex;
            break;
        case 'mastercard':
            image = MasterCard;
            brandText = 'MasterCard';
            break;
        case 'visa':
            image = Visa;
            break;
        case 'discover':
            image = Discover;
            break;
        case 'jcb':
            image = JCB;
            brandText = 'JCB';
            break;
        case 'unionpay':
            image = UnionPay;
            brandText = 'UnionPay';
            break;
        case 'diners':
            image = Diners;
            break;
        default:
            //image = Unknown;
            break;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <div style={{ marginRight: 25, marginBottom: 4 }}>
                <img
                    width={60}
                    height={46}
                    src={image}
                    alt={paymentMethod.brand}
                    style={{ objectFit: 'contain' }}
                />
            </div>
            <div>
                <Typography fontSize={17} component="span" marginRight={1}>
                    {brandText}
                </Typography>
                <Typography
                    fontSize={17}
                    component="span"
                    marginRight={1}
                    fontFamily="inter"
                >
                    ••••
                </Typography>
                <Typography fontSize={17} component="span">
                    {paymentMethod.last4}
                </Typography>
                <Typography
                    fontSize={15}
                    color="text.secondary"
                    fontWeight="light"
                >
                    {`Expires ${expiration.format('MM/YY')}`}
                </Typography>
            </div>
        </div>
    );
}

export default PaymentMethodCard;
