import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropertyCard from '../../components/cards/PropertyCard';
import CardList from '../../components/common/CardList';
import CollapseContainer from '../../components/common/CollapseContainer';
import AuctionTable from '../../components/common/AuctionTable';
import {
    Box,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import DynamicIcon from '../../components/common/DynamicIcon';

function Auctions() {
    const [hiddenAuctions, setHiddenAuctions] = useState([]);
    const [disabledAuctions, setDisabledAuctions] = useState([]);
    const [active, setActive] = useState([]);
    const [allAuctions, setAllAuctions] = useState([]);
    const [concludedAuctions, setConcludedAuctions] = useState({});
    const [style, setStyle] = useState('table');

    useEffect(() => {
        axios
            .get(`/auction/getHidden`)
            .then((res) => {
                setHiddenAuctions(res.data);
            })
            .catch(function () {});

        axios
            .get(`/auction/getDisabled`)
            .then((res) => {
                setDisabledAuctions(res.data);
            })
            .catch(function () {});

        axios
            .post(`/auction/getAdmin`)
            .then((res) => {
                setActive(res.data);
            })
            .catch(function () {});

        axios
            .post(`/auction/getAdmin`, { all: true })
            .then((res) => {
                setAllAuctions(res.data);
            })
            .catch(function () {});

        axios
            .post(`/auction/getConcluded`)
            .then((res) => {
                setConcludedAuctions(res.data);
            })
            .catch(function () {});
    }, []);

    return (
        <div className="admin-content">
            <Box display="flex">
                <Typography fontSize={20} fontWeight="bold" marginTop={0.5}>
                    Listings
                </Typography>
                <Box marginLeft="auto" marginBottom={1}>
                    <ToggleButtonGroup
                        size="small"
                        value={style}
                        onChange={(e, value) => {
                            if (value) {
                                setStyle(value);
                            }
                        }}
                        exclusive
                        sx={{ backgroundColor: 'white' }}
                    >
                        <ToggleButton value="table">
                            <DynamicIcon icon="TableRows" />
                        </ToggleButton>
                        <ToggleButton value="card">
                            <DynamicIcon icon="GridView" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <AuctionList
                auctions={hiddenAuctions}
                title="Unpublished"
                style={style}
                allowSync={true}
            />
            <AuctionList
                auctions={disabledAuctions}
                title="Disabled"
                style={style}
            />
            <AuctionList
                auctions={concludedAuctions.unsold || []}
                title="Unsold"
                style={style}
            />
            <AuctionList
                auctions={active}
                title="Live and Upcoming"
                style={style}
            />
            <AuctionList
                auctions={concludedAuctions.sold || []}
                title="Sold"
                style={style}
            />
            <AuctionList auctions={allAuctions} title="All" style={style} />
        </div>
    );
}

const AuctionList = ({ auctions, title, style, allowSync }) => {
    if (auctions?.length === 0) {
        return null;
    }
    return (
        <Box marginBottom={2}>
            <Box display={style === 'card' ? 'initial' : 'none'}>
                <CollapseContainer
                    title={`${title} (${auctions?.length || 0})`}
                    expandDefault={false}
                    backgroundColor={auctions?.length === 0 ? '#555' : 'black'}
                >
                    <CardList
                        items={auctions}
                        Component={PropertyCard}
                        lg={4}
                        md={6}
                        padding={3}
                        spacing={3}
                        noCenter
                    />
                </CollapseContainer>
            </Box>
            <Box display={style === 'table' ? 'initial' : 'none'}>
                <AuctionTable
                    auctions={auctions}
                    title={`${title} (${auctions?.length || 0})`}
                    allowSync={allowSync}
                />
            </Box>
        </Box>
    );
};

export default Auctions;
