import { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import PaddedBox from '../components/common/PaddedBox';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHotglue, Connections } from '@hotglue/widget';
import { AccountContext } from '../components/supertokens/Account';

function Developer() {
    const account = useContext(AccountContext);
    const tenantId = account.user.username;

    const [config, setConfig] = useState();

    useEffect(() => {
        axios
            .get(`/hotglue/widget`)
            .then((res) => {
                setConfig(res.data);
            })
            .catch(function () {});
    }, []);

    return (
        <Box paddingTop={5}>
            <PaddedBox>
                <Typography fontSize="18" fontWeight="bold" gutterBottom>
                    Dashboard
                </Typography>
                <Hotglue tenantId={tenantId} config={config} />
                {/* <Connections tenant={tenantId} /> */}
            </PaddedBox>
        </Box>
    );
}

function Hotglue({ tenantId, config }) {
    const { openWidget } = useHotglue();

    return (
        <Box>
            <Button
                onClick={() => {
                    openWidget(tenantId, {
                        jwtToken: config.token,
                        schemas: config.schemas,
                        nextStep: 'mapping',
                    });
                }}
                variant="dark"
            >
                Open CRM Widget
            </Button>
        </Box>
    );
}

export default Developer;
