import React, { useEffect, useState } from 'react';
import Loading from '../../components/common/Loading';
import axios from 'axios';
import { Box, Button, TextField, Typography } from '@mui/material';
import CardList from '../../components/common/CardList';
import HTMLParse from '../../components/common/HTMLParse';

function Dashboard() {
    const [projects, setProjects] = useState([]);
    const [test, setTest] = useState('');

    useEffect(() => {
        axios
            .get(`/project/getAll`)
            .then((res) => {
                setProjects(res.data);
            })
            .catch(function () {});
    }, []);

    useEffect(() => {
        if (test) {
            axios
                .post(`/administration/test`, { value: test })
                .then((res) => {
                    console.log(res.data);
                    navigator.clipboard.writeText(res.data);
                })
                .catch(function (err) {
                    console.log(err);
                });
        }
    }, [test]);

    if (!projects) {
        return <Loading />;
    }

    return (
        <div className="admin-content">
            <CardList items={projects} Component={ProjectCard} noCenter />

            {process.env.REACT_APP_ENV === 'development' && (
                <>
                    <TextField
                        onChange={(e) => setTest(e.target.value)}
                        fullWidth
                        backgroundColor="white"
                    />
                    <Button
                        fullWidth
                        size="tiny"
                        variant="dark"
                        onClick={() =>
                            axios
                                .post(`/administration/test`, { value: test })
                                .then((res) => {
                                    console.log(res.data);
                                })
                                .catch(function (err) {
                                    console.log(err);
                                })
                        }
                    >
                        Test
                    </Button>
                </>
            )}
        </div>
    );
}

function ProjectCard({ item: project }) {
    return (
        <Box
            height={200}
            border="1px solid #707070"
            backgroundColor="#f8f8f8"
            padding={3}
            display="flex"
            flexDirection="column"
            style={{ cursor: 'pointer' }}
            onClick={() =>
                (window.location.href = `/administration/project/${project.slug}`)
            }
        >
            <Typography fontSize={22} fontWeight="medium" marginBottom={3}>
                {project.title}
            </Typography>
            <Typography fontWeight="light" className="clamp3">
                <HTMLParse value={project.description} />
            </Typography>
        </Box>
    );
}

export default Dashboard;
