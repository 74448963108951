import axios from 'axios';
import React, { useState, useEffect } from 'react';
import CardList from '../common/CardList';
import Loading from '../common/Loading';
import PropertyCard from '../cards/PropertyCard';
import ViewAllContainer from './ViewAllContainer';

function Following() {
    const [auctions, setAuctions] = useState();

    useEffect(() => {
        axios
            .post(`/auction/getWatching`)
            .then((res) => {
                setAuctions(res.data);
            })
            .catch(function () {});
    }, []);

    // Display loading indication
    if (auctions === null || auctions === undefined) {
        return <Loading />;
    }

    return (
        <div className="user-content user-content-width">
            <ViewAllContainer
                title="Following"
                length={auctions?.length}
                maxWidth={1}
            >
                <CardList
                    items={auctions}
                    Component={PropertyCard}
                    lg={6}
                    spacing={6.25}
                    padding={0}
                    noCenter
                />
            </ViewAllContainer>
        </div>
    );
}

export default Following;
