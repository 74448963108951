import React from 'react';
import Typography from '@mui/material/Typography';
import BedIcon from '@mui/icons-material/KingBed';
import BathIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/Tag';
import ParkingIcon from '@mui/icons-material/LocalParking';
import { Box } from '@mui/material';

function PropertyFeatures({ auction, full, flex }) {
    return (
        <div
            className={`property-card-horizontal-list`}
            style={{
                display: 'flex',
                marginBottom: full ? 25 : 15,
                marginTop: full ? 4 : 5,
                flexFlow: 'wrap',
            }}
        >
            <Pair
                Icon={BedIcon}
                value={auction.property.model.bedroomCount}
                label="Bed"
                full={full}
                flex={flex}
            />
            {full && auction.property.model.denCount > 0 && (
                <Pair
                    Icon={BedIcon}
                    value={auction.property.model.denCount}
                    label="Den"
                    full={full}
                    flex={flex}
                />
            )}
            <Pair
                Icon={BathIcon}
                value={auction.property.model.bathroomCount}
                label="Bath"
                full={full}
                flex={flex}
            />
            {full && (
                <>
                    {auction.property.parkingCount > 0 && (
                        <Pair
                            Icon={ParkingIcon}
                            value={auction.property.parkingCount}
                            label="Parking"
                            full={full}
                            flex={flex}
                        />
                    )}
                </>
            )}
            <Pair
                Icon={SquareFootIcon}
                value={auction.property.model.interiorSquareFeet || 0}
                label={full ? 'Square Ft.' : 'Sq. Ft.'}
                full={full}
                flex={flex}
                plural=""
            />
        </div>
    );
}

const Pair = ({ Icon, value, label, full, flex, plural = 's' }) => {
    return (
        <Box
            component="li"
            sx={{
                marginLeft: flex ? 'auto' : '5px !important',
                marginRight: flex ? 'auto' : '5px !important',
            }}
            color="dark"
        >
            <Box
                sx={{
                    fontSize: 28,
                    display: 'flex',
                    paddingLeft: 1,
                    paddingRight: 1,
                }}
            >
                <Typography
                    textAlign="center"
                    fontSize={full ? 14 : 14}
                    marginTop={full ? 1 : 0.25}
                    letterSpacing={1.8}
                    color="dark"
                    fontFamily="urbanist"
                    fontWeight="bold"
                >
                    {value.toLocaleString()}
                </Typography>
                <Typography
                    textAlign="center"
                    fontSize={full ? 14 : 14}
                    marginTop={full ? 1 : 0.25}
                    color="text.secondary"
                    marginLeft={1}
                    marginRight={1}
                    fontFamily="urbanist"
                >
                    |
                </Typography>
                <Typography
                    textAlign="center"
                    fontSize={full ? 14 : 14}
                    marginTop={full ? 1 : 0.25}
                    letterSpacing={1.8}
                    color="dark"
                    fontFamily="urbanist"
                    textTransform="uppercase"
                >
                    {label}
                    {value > 1 ? plural : ''}
                </Typography>
            </Box>
        </Box>
    );
};

export default PropertyFeatures;
