const isUUID = (uuid) => {
    const regexExp =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(uuid);
};

const provinces = [
    { id: 'AB' },
    { id: 'BC' },
    { id: 'MB' },
    { id: 'NB' },
    { id: 'NL' },
    { id: 'NS' },
    { id: 'NT' },
    { id: 'NU' },
    { id: 'ON' },
    { id: 'PE' },
    { id: 'QC' },
    { id: 'SK' },
    { id: 'YT' },
];

export { isUUID, provinces };
