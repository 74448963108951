module.exports = {
    auction: {
        endingSoon: 120,
    },
    comment: {
        maxLength: 1000,
    },
    persona: {
        templateId: 'itmpl_tzzUsE8T2oHb7EHYxRBkNVq6',
        production: 'env_MiEChXZsh1DhTgVrkb4pKysi',
        sandbox: 'env_B77zpo8aPcyBGa1DF7PphmQw',
    },
};
