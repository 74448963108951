import React from 'react';
import CouponList from '../../components/administration/CouponList';

function Coupons() {
    return (
        <div className="admin-content">
            <CouponList />
        </div>
    );
}

export default Coupons;
