import React, { useState } from 'react';
import CardList from '../components/common/CardList';
import PropertyCard from '../components/cards/PropertyCard';
import Footer from '../components/common/Footer';
import { Box } from '@mui/material';
import { setTitle } from '../scripts/style';
import PaddedBox from '../components/common/PaddedBox';
import AuctionFilter from '../components/auction/AuctionFilter';

function Marketplace() {
    const [auctions, setAuctions] = useState();

    setTitle('Opportunities');

    return (
        <Box backgroundColor="tint">
            <PaddedBox>
                <Box paddingTop={6} paddingBottom={6}>
                    <AuctionFilter setAuctions={setAuctions} />
                    <CardList
                        items={auctions}
                        Component={PropertyCard}
                        lg={4}
                        md={6}
                        xs={12}
                        padding={0}
                        vertical={6}
                        spacing={6}
                        noCenter
                        noData="You have no current opportunities."
                    />
                </Box>
            </PaddedBox>
            <Footer />
        </Box>
    );
}

export default Marketplace;
