import React from 'react';
import { Grid, TextField } from '@mui/material';

function Input({
    id,
    label,
    type = 'text',
    value,
    width = '100%',
    children,
    disabled,
    required,
    onChange,
    multiline,
    rows = 4,
    marginBottom,
    xs = 12,
    md = 6,
}) {
    return (
        <Grid
            item
            xs={xs}
            md={md}
            marginBottom={marginBottom}
            className="text-input"
        >
            {/* <InputLabel sx={{ fontSize: 14, marginBottom: 0.25 }} htmlFor={id}>
                {label}
            </InputLabel> */}
            <TextField
                id={id}
                name={id}
                type={type}
                label={label}
                className={`form-control`}
                disabled={disabled}
                value={value || ''}
                style={{ width: width }}
                required={required}
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
                multiline={multiline}
                rows={rows}
                InputProps={{
                    style: {
                        fontFamily: type === 'password' ? 'inter' : 'urbanist',
                    },
                }}
            />
            {children}
        </Grid>
    );
}

export default Input;
