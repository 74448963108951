import { Box, IconButton, Typography } from '@mui/material';
import DynamicIcon from '../common/DynamicIcon';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import DepositSchedule from '../payment/DepositSchedule';

function DepositSchedulePreview({ yup }) {
    const [deposits, setDeposits] = useState([]);

    function refresh() {
        axios
            .post(`/deposit/getDepositSchedulePreview`, yup.getValues())
            .then((res) => {
                setDeposits(res.data);
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    return (
        <Box flex={1}>
            <Box display="flex" flex={1}>
                <Typography fontWeight="bold" margin="auto" marginLeft={0}>
                    Preview
                </Typography>
                <IconButton onClick={refresh}>
                    <DynamicIcon icon="Refresh" />
                </IconButton>
            </Box>
            <DepositSchedule
                depositSchedule={{ deposits }}
                error="Fill in the custom schedule and refresh to preview exact due dates."
            />
        </Box>
    );
}

export default DepositSchedulePreview;
