import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';

function CloseModal({ onClose, top = 25, right = 25, colour = 'dark' }) {
    if (!onClose) {
        return null;
    }

    return (
        <Box position="absolute" top={top} right={right} zIndex={1}>
            <IconButton sx={{ color: colour }} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Box>
    );
}

export default CloseModal;
