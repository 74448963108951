import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import NotFound from '../../pages/NotFound.js';

import { toast } from 'react-toastify';
import Field from './Field.js';
import DynamicIcon from '../common/DynamicIcon.js';
import { Link } from '@mui/material';

function AmenityForm() {
    const { id } = useParams();
    const [valid, setValid] = useState(true);

    const [amenityId, setAmenityId] = useState(id);

    const defaultForm = {};
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: defaultForm,
    };
    const {
        register,
        handleSubmit,
        formState,
        getValues,
        setValue,
        control,
        watch,
    } = useForm(formOptions);
    const { errors } = formState;

    useEffect(() => {
        // Get existing auction data (if you are modifying one)
        if (id) {
            axios
                .get(`/amenity/get/${id}`)
                .then((res) => {
                    const amenity = res.data;

                    setValue('title', amenity.title);
                    setValue('icon', amenity.icon);

                    setAmenityId(amenity.id);
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [id, setValue]);

    function onSubmit(form) {
        form.amenity_id = id;

        // Create a new amenity
        if (!id) {
            axios
                .post(`/amenity/create/`, form)
                .then((res) => {
                    window.location.href = '/administration/amenities';
                })
                .catch(function (err) {
                    let message = err.message;
                    if (err.response && err.response.data) {
                        message = err.response.data;
                    }
                    toast.error(message);
                });
        }

        // Update an existing amenity
        else {
            axios
                .post(`/amenity/update/`, form)
                .then((res) => {
                    window.location.href = '/administration/amenities';
                })
                .catch(function (err) {
                    let message = err.message;
                    if (err.response && err.response.data) {
                        message = err.response.data;
                    }
                    toast.error(message);
                });
        }
    }

    if (valid) {
        return (
            <div className="admin-content">
                <AmenityFormInput
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    formState={formState}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    id={amenityId}
                    watch={watch}
                />
            </div>
        );
    } else {
        return <NotFound />;
    }
}

const AmenityFormInput = ({
    onSubmit,
    handleSubmit,
    formState,
    errors,
    register,
    setValue,
    getValues,
    control,
    id,
    watch,
}) => {
    const yup = { register, getValues, setValue, errors, watch };

    return (
        <div>
            <div className="card" style={{ maxWidth: 1000 }}>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h6>{id ? 'Modify Amenity' : 'Create Amenity'}</h6>
                        <div className="input-panel-container">
                            <Field id="title" yup={yup} />
                        </div>

                        <div className="input-panel-container">
                            <DynamicIcon icon={watch('icon')} size={70} />
                            <Field
                                id="icon"
                                yup={yup}
                                input={{ optional: true }}
                            />
                        </div>

                        <Link
                            href="https://mui.com/material-ui/material-icons/"
                            target="_blank"
                        >
                            Icons
                        </Link>

                        <hr />
                        <button
                            disabled={formState.isSubmitting}
                            className="btn btn-primary btn-wide"
                        >
                            {formState.isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AmenityForm;
