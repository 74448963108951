import { useContext } from 'react';
import NotFound from '../../pages/NotFound';
import { AccountContext } from '../supertokens/Account';
import Header from '../common/Header';
import Loading from '../common/Loading';

function RequireAdmin({ children }) {
    const account = useContext(AccountContext);
    return account.loading ? (
        <Loading />
    ) : account.isAdmin() ? (
        children
    ) : (
        <>
            <Header />
            <NotFound />
        </>
    );
}

export default RequireAdmin;
