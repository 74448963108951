import React from 'react';
import { Typography } from '@mui/material';
import ViewMoreContainer from '../common/ViewMoreContainer';
import NoData from '../common/NoData';
import AuctionSection from './AuctionSection';
import HTMLParse from '../common/HTMLParse';

function AuctionParagraph({ title, text, noDivider, paddingV }) {
    // Increase this value if you want to make the paragraph output longer before a container is put around it.
    const MAX = 500;

    // Return nothing if empty
    if (!text) {
        return null;
    }

    if (text?.length > MAX) {
        return (
            <AuctionSection
                title={title}
                noDivider={noDivider}
                paddingV={paddingV}
            >
                <ViewMoreContainer height={170}>
                    <Content text={text} />
                </ViewMoreContainer>
            </AuctionSection>
        );
    } else {
        return (
            <AuctionSection
                title={title}
                noDivider={noDivider}
                paddingV={paddingV}
            >
                <Content text={text} />
            </AuctionSection>
        );
    }
}

const Content = ({ text }) => {
    return (
        <Typography
            className="bullets parser"
            style={{ whiteSpace: 'pre-line' }}
            component="div"
            color="dark"
            lineHeight={1.8}
        >
            {text ? <HTMLParse value={text} /> : <NoData padding={5} />}
        </Typography>
    );
};
export default AuctionParagraph;
