import React, { useContext } from 'react';
import AuctionAdminControls from '../administration/AuctionAdminControls';
import { Box, Link, Typography } from '@mui/material';
import Timer, {
    renderSplit,
    renderLaunching,
    renderWhenDone,
    renderUntilDone,
} from '../common/Timer';
import PropertyFeatures from './PropertyFeatures';

import BidInput from './BidInput';
import BidInfo from './BidInfo';

import moment from 'moment';
import { AccountContext } from '../supertokens/Account';
import AuctionParagraph from './AuctionParagraph';

import UserSummary from '../user/UserSummary';

function AuctionPanel({
    auction,
    setAuction,
    createStatusModal,
    createConfetti,
}) {
    const account = useContext(AccountContext);

    // Simplify data structure
    const property = auction.property;
    const model = auction.property.model;
    const address = property.address;

    return (
        <Box
            sx={{
                padding: { xs: '20px', md: '60px', lg: '60px' },
                paddingBottom: { xs: '0px !important', md: '20px !important' },
            }}
        >
            <Box paddingBottom={1}>
                <div
                    style={{
                        position: 'absolute',
                        top: 70,
                        right: 0,
                    }}
                >
                    <AuctionAdminControls
                        auction={auction}
                        permanent
                        vertical
                    />
                </div>

                {/* <PanelHeader slug={auction.slug} /> */}

                {!auction.disabled && auction.visible && (
                    <Timer endDate={auction.endDate} renderer={renderWhenDone}>
                        <AuctionConcluded auction={auction} />
                    </Timer>
                )}

                {auction.disabled && <AuctionDisabled auction={auction} />}

                <Timer endDate={auction.startDate} renderer={renderLaunching} />

                <Typography
                    fontSize={14}
                    letterSpacing={6}
                    textTransform="uppercase"
                    fontWeight="light"
                    marginBottom={2.5}
                    fontFamily="urbanist"
                >
                    {`${address.city}, ${address.province}`}
                </Typography>
                <Typography
                    fontSize={28}
                    lineHeight={1.25}
                    marginTop="5px"
                    variant="heading"
                    sx={{
                        wordBreak: 'break-word',
                    }}
                >
                    {auction.title}
                </Typography>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    fontSize={16}
                    letterSpacing={0.25}
                    marginTop={2}
                >
                    <Box>
                        <Typography>{address.streetAddress}</Typography>
                        <Typography>{property.unit}</Typography>
                    </Box>
                    <Typography
                        fontWeight="bold"
                        textTransform="uppercase"
                        marginTop="auto"
                        letterSpacing={1.5}
                        component={Link}
                        color="dark"
                        sx={{ textDecoration: 'none' }}
                        // TODO: Re-enable when category page is active
                        // href={`/category/${model.propertyType.slug}`}
                    >
                        {model.propertyType.title}
                    </Typography>
                </Box>
            </Box>

            <PropertyFeatures auction={auction} full flex />

            <Timer endDate={auction.endDate} renderer={renderUntilDone}>
                <BidInfo
                    auction={auction}
                    setAuction={setAuction}
                    concluded={false}
                />
            </Timer>
            <Timer endDate={auction.endDate} renderer={renderWhenDone}>
                <BidInfo
                    auction={auction}
                    setAuction={setAuction}
                    concluded={true}
                />
            </Timer>

            <div className="gap-small" />

            {!auction.disabled && (
                <Timer endDate={auction.startDate} renderer={renderWhenDone}>
                    <Timer endDate={auction.endDate} renderer={renderUntilDone}>
                        <>
                            {auction.property.address.project.developer.user
                                .username !== account.user.username &&
                                !auction.brokerAccess && (
                                    <BidInput
                                        auctionId={auction.id}
                                        exclusiveId={auction.brokerId}
                                        bidMin={auction.minimumBid}
                                        createStatusModal={createStatusModal}
                                        auctionTypeId={auction.auctionTypeId}
                                        createConfetti={createConfetti}
                                    />
                                )}
                            <Timer
                                endDate={auction.endDate}
                                renderer={renderSplit}
                            />
                        </>
                    </Timer>
                </Timer>
            )}

            <div className="gap-small" />

            <UserSummary
                username={
                    auction.property.address.project.developer.user.username
                }
            />

            <div className="gap" />

            <AuctionParagraph
                title="Description"
                text={property.description}
                noDivider
                paddingV={0}
            />
        </Box>
    );
}

const AuctionConcluded = ({ auction }) => {
    return (
        <Box marginBottom={5}>
            <Typography
                fontSize={23}
                fontWeight="medium"
                letterSpacing={1.5}
                textTransform="uppercase"
                color="status.danger"
                lineHeight={1.25}
            >
                Listing Sold
            </Typography>
            <Typography
                fontSize={17}
                fontWeight="medium"
                letterSpacing={1.5}
                textTransform="uppercase"
                color="status.danger"
                lineHeight={1}
            >
                {`Ended ${moment(auction.endDate).format(`MMMM D, YYYY`)}`}
            </Typography>
            {auction.slug !== auction.currentAuctionSlug && (
                <Typography
                    marginTop={2}
                    fontSize={17}
                    fontWeight="medium"
                    letterSpacing={1.5}
                    lineHeight={1}
                    textTransform="none"
                >
                    <Link
                        href={`/listing/${auction.currentAuctionSlug}`}
                        underline="hover"
                        color="dark"
                    >
                        View the Latest Listing
                    </Link>
                </Typography>
            )}
        </Box>
    );
};

const AuctionDisabled = ({ message }) => {
    return (
        <Box marginBottom={5}>
            <Typography
                fontSize={23}
                fontWeight="medium"
                letterSpacing={1.5}
                textTransform="uppercase"
                color="status.danger"
                lineHeight={1.25}
            >
                Listing Disabled
            </Typography>
            <Typography
                fontSize={17}
                fontWeight="medium"
                letterSpacing={1.5}
                textTransform="uppercase"
                color="status.danger"
                lineHeight={1}
            >
                {message}
            </Typography>
        </Box>
    );
};

/*const PanelHeader = ({ slug }) => {
    const [showShare, setShowShare] = useState(false);
    return (
        <Box display="flex" justifyContent="space-between" paddingBottom={2.5}>
            <Box
                display="flex"
                className="clickable underline"
                component={Link}
                href="/marketplace"
                color="#707070 !important"
                sx={{ textDecoration: 'none' }}
            >
                <ArrowIcon
                    style={{
                        transform: 'rotate(180deg)',
                        fontSize: 17,
                        margin: 'auto',
                    }}
                />
                <Typography marginTop={0.25} marginLeft={1}>
                    All Listings
                </Typography>
            </Box>
            <Box
                display="flex"
                className="clickable underline"
                color="#707070 !important"
                sx={{ textDecoration: 'none' }}
                onClick={() => setShowShare(true)}
            >
                <ShareIcon
                    style={{
                        fontSize: 17,
                        margin: 'auto',
                    }}
                />
                <Typography marginTop={0.25} marginLeft={1}>
                    Share
                </Typography>
            </Box>
            <ShareModal show={showShare} onClose={() => setShowShare(false)} />
        </Box>
    );
};*/

export default AuctionPanel;
