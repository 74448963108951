import React from 'react';
import { Box, CardMedia, Typography, Link } from '@mui/material';
import './CategoryCard.css';

function CategoryCard({ item: propertyType, height = 290, width }) {
    return (
        <Box sx={{ width: width, position: 'relative', color: 'dark' }}>
            <Link
                href={`/category/${propertyType.slug}`}
                sx={{
                    textDecoration: 'none',
                }}
                className="underline"
            >
                <Box className="category-card">
                    <CardMedia
                        component="img"
                        sx={{
                            height: {
                                xs: '40vw',
                                md: '20vw',
                                lg: '18vw',
                            },
                            borderRadius: '10px',
                        }}
                        width={width}
                        image={propertyType?.media?.url}
                        className="shadow1"
                    />
                </Box>
                <Typography
                    fontSize={22}
                    marginBottom={3}
                    fontWeight="medium"
                    letterSpacing={1}
                    paddingRight={2}
                    marginTop={1.5}
                    color="dark"
                    sx={{ textDecorationColor: 'dark' }}
                    fontFamily="urbanist"
                >
                    {propertyType.plural}
                </Typography>
            </Link>
        </Box>
    );
}

export default CategoryCard;
