import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

function AuctionSection({ title, children, noDivider, paddingV = 1 }) {
    return (
        <Box
            sx={{
                paddingTop: paddingV,
                paddingBottom: paddingV,
                width: '100%',
            }}
        >
            <Typography
                fontSize={24}
                marginBottom={1.5}
                lineHeight={2}
                variant="heading"
                component="div"
            >
                {title}
            </Typography>
            {children}
            <Box>
                {!noDivider && (
                    <Divider sx={{ marginTop: 6, marginBottom: 3 }} />
                )}
            </Box>
        </Box>
    );
}

export default AuctionSection;
