import React, { useState, useEffect } from 'react';
import { Dialog } from '@mui/material';

function FullscreenMedia({ media, setMedia }) {
    const [dim, setDim] = useState(getWindowDimensions());

    const MAX_WIDTH = 1200;
    const MARGIN = 10;

    const width = Math.min(dim.width - 3 * MARGIN, MAX_WIDTH);

    useEffect(() => {
        function handleResize() {
            setDim(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const url = media?.url;

    const handleClose = () => {
        setMedia(null);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={media !== null}
            scroll="paper"
            PaperProps={{
                style: {
                    width: `calc(90% - 3 * ${MARGIN}px)`,
                    height: `calc(100% - 3 * ${MARGIN}px)`,
                    maxWidth: width,
                    margin: MARGIN,
                    overflow: 'hidden',
                },
            }}
        >
            <embed src={url} width="100%" height="100%" />
        </Dialog>
    );
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default FullscreenMedia;
