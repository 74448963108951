import { Button, Checkbox, Typography, Box } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Loading from '../common/Loading';
import ViewAllContainer from './ViewAllContainer';

function Preferences() {
    const [preferences, setPreferences] = useState();

    useEffect(() => {
        axios
            .get(`/user/getPreferences`)
            .then((res) => {
                setPreferences(res.data);
            })
            .catch(function () {});
    }, []);

    // Display loading indication
    if (preferences === null || preferences === undefined) {
        return <Loading />;
    }

    return (
        <div className="user-content user-content-width">
            <ViewAllContainer title="Email Preferences" maxWidth={500}>
                <EmailFilters emailFilters={preferences?.emailFilters} />
            </ViewAllContainer>
        </div>
    );
}

const EmailFilters = ({ emailFilters = [] }) => {
    const [form, setForm] = useState({});
    const [hasChanges, setHasChanges] = useState(false);

    const handleSubmit = () => {
        axios
            .post('/user/updateEmailFilters', form)
            .then(function () {
                window.location.reload();
            })
            .catch(function () {});
    };

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked,
        });
        setHasChanges(true);
    };

    return (
        <Box>
            {emailFilters.map((item, i) => (
                <EmailFilter
                    item={item}
                    i={i}
                    key={item.id}
                    handleChange={handleChange}
                />
            ))}
            <Typography marginTop={4} marginBottom={2} fontSize={15}>
                Emails regarding your purchases are mandatory in order to inform
                and communicate regarding the transaction and can not be opted
                out of.
            </Typography>
            <Button
                variant="dark"
                fullWidth
                sx={{ marginTop: 2 }}
                disabled={!hasChanges}
                onClick={handleSubmit}
            >
                Save
            </Button>
        </Box>
    );
};

const EmailFilter = ({ item, i, handleChange }) => {
    return (
        <Box
            sx={{
                flexDirection: 'row',
                display: 'flex',
                width: '100%',
                paddingBottom: { xs: 3, md: 0 },
                borderBottom: '1px solid #ccc',
            }}
        >
            <Box padding={2} flex={1}>
                <Typography fontWeight="medium" fontSize={17} gutterBottom>
                    {item.title}
                </Typography>
                <Typography>{item.description}</Typography>
            </Box>
            <Box display="flex">
                <Checkbox
                    name={item.id.toString()}
                    style={{ margin: 'auto' }}
                    onChange={handleChange}
                    defaultChecked={item.userEmailFilters?.length === 0}
                />
            </Box>
        </Box>
    );
};

export default Preferences;
