import PinIcon from '@mui/icons-material/Room';

function Marker({ children, center, colour }) {
    return (
        <div
            style={{
                textAlign: 'center',
                transform: `translate(-50%, ${center ? '-50%' : '-95%'})`,
                position: 'absolute',
            }}
        >
            {children || (
                <PinIcon
                    style={{
                        color: colour || '#111',
                        fontSize: 40,
                    }}
                />
            )}
        </div>
    );
}

export default Marker;
