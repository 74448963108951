import React, { useState, useEffect } from 'react';
import PinIcon from '@mui/icons-material/RoomOutlined';

import GoogleMapReact from 'google-map-react';
import Marker from '../../components/map/Marker';
import AutoComplete from 'react-google-autocomplete';
import { Button, Typography } from '@mui/material';

import Field from '../cms/Field';
import { getBuilders, getProjects, loadForm, useYup } from '../../scripts/cms';
import InputForm from '../common/InputForm';
import { toast } from 'react-toastify';

function AddressSearch({ address, onPick, text, title }) {
    const defaultCenter = { lat: 43.45, lng: -80.49 };

    const [marker, setMarker] = useState({});
    const [customMarker, setCustomMarker] = useState({});
    const [center, setCenter] = useState(defaultCenter);
    const [zoom, setZoom] = useState(10);

    const height = 800;

    const inputs = [
        [{ Component: Search, props: { onSelect } }],
        [{ id: 'streetNumber' }, { id: 'streetName' }],
        [{ id: 'city' }, { id: 'province' }],
        [{ id: 'postalCode' }, { id: 'country' }],
        [
            {
                Component: CustomPin,
                props: { customMarker, setCustomMarker, marker, setCenter },
            },
        ],
        [{ id: 'title' }, { id: 'completionDate', optional: true }],
        [{ id: 'identifier' }, { id: 'architecturalCode' }],
        [
            { id: 'storeyCount', number: true },
            { id: 'unitCount', number: true, optional: true },
        ],
        [
            { id: 'unitSizeMin', number: true, optional: true },
            { id: 'unitSizeMax', number: true, optional: true },
        ],
        [
            {
                id: 'projectId',
                source: getProjects,
                label: 'Project',
                table: true,
                restricted: true,
            },
        ],
        [
            {
                id: 'builders',
                source: getBuilders,
                label: 'Builders',
                table: { multi: true },
                restricted: true,
                optional: true,
            },
        ],
    ];

    const yup = useYup(inputs);

    useEffect(() => {
        if (
            address &&
            (address.address !== undefined ||
                address.streetAddress !== undefined)
        ) {
            loadForm(address, yup.setValue);

            const builderList = [];
            for (let i = 0; i < address.addressBuilders.length; i++) {
                builderList.push(address.addressBuilders[i].builder.id);
            }
            yup.setValue('builders', builderList);

            const geo = { lat: address.lat, lng: address.lng };
            setMarker(geo);
            setCenter(geo);
            setZoom(20);
        }
    }, [address]);

    function onSubmit(form) {
        if (customMarker.lat && customMarker.lng) {
            form.lat = customMarker.lat;
            form.lng = customMarker.lng;
        } else {
            form.lat = marker.lat;
            form.lng = marker.lng;
        }

        if (onPick) {
            onPick(form);
        }
    }

    function onSelect(place) {
        const { setValue } = yup;

        const address_components = place.address_components;

        if (address_components) {
            // Reset location input
            setValue('streetNumber', '');
            setValue('streetName', '');
            setValue('city', '');
            setValue('postalCode', '');
            setValue('province', '');
            setValue('country', '');

            // Get address values based on map search
            for (let i = 0; i < address_components.length; i++) {
                const curr = address_components[i];

                // Street num
                if (curr.types.includes('street_number')) {
                    setValue('streetNumber', curr.long_name);
                } else if (curr.types.includes('route')) {
                    setValue('streetName', curr.long_name);
                } else if (curr.types.includes('locality')) {
                    setValue('city', curr.long_name);
                } else if (curr.types.includes('postal_code')) {
                    setValue('postalCode', curr.long_name);
                } else if (curr.types.includes('administrative_area_level_1')) {
                    setValue('province', curr.long_name);
                } else if (curr.types.includes('country')) {
                    setValue('country', curr.long_name);
                }
            }

            // Pin placement
            setPin({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            });
        } else {
            toast.warning('Please select a valid address.');
        }
    }

    function setPin(geo) {
        setMarker(geo);
        setCenter(geo);
        setZoom(20);

        setCustomMarker({});
    }

    return (
        <div
            style={{
                backgroundColor: '#ccc',
                position: 'relative',
                display: 'flex',
            }}
        >
            <div
                style={{
                    height: height,
                    overflowY: 'auto',
                    width: 500,
                    backgroundColor: 'white',
                    borderStyle: 'solid',
                    borderWidth: '0px 1px 0px 0px',
                    borderColor: '#ccc',
                    padding: 25,
                }}
            >
                <ul className="horizontal-list" style={{ marginBottom: 20 }}>
                    <li>
                        <Typography color="text.primary">
                            <PinIcon style={{ fontSize: 26 }} />
                        </Typography>
                    </li>
                    <li>
                        <Typography
                            gutterBottom
                            variant="div"
                            color="text.primary"
                            fontWeight={500}
                            fontSize={18}
                        >
                            {title || 'Address Selection'}
                        </Typography>
                    </li>
                </ul>
                <InputForm yup={yup} inputs={inputs} />
                <Button
                    variant="blue"
                    fullWidth
                    onClick={yup.handleSubmit(onSubmit)}
                >
                    Submit
                </Button>
            </div>
            <Map
                height={height}
                center={center}
                zoom={zoom}
                marker={marker}
                customMarker={customMarker}
                setCustomMarker={setCustomMarker}
                setCenter={setCenter}
            />
        </div>
    );
}

function Map({
    height,
    center,
    zoom,
    marker,
    customMarker,
    setCustomMarker,
    setCenter,
}) {
    return (
        <div style={{ width: '100%', height: height, flex: 1 }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                    libraries: ['places'],
                }}
                center={center}
                zoom={zoom}
                onClick={({ lat, lng }) => {
                    const geo = {
                        lat: parseFloat(lat.toFixed(7)),
                        lng: parseFloat(lng.toFixed(7)),
                    };
                    setCustomMarker(geo);
                    setCenter(geo);
                }}
            >
                {!customMarker.lat &&
                    !customMarker.lng &&
                    marker.lat &&
                    marker.lng && <Marker lat={marker.lat} lng={marker.lng} />}
                {customMarker.lat && customMarker.lng && (
                    <Marker
                        lat={customMarker.lat}
                        lng={customMarker.lng}
                        colour={'#ee3333'}
                    />
                )}
            </GoogleMapReact>
        </div>
    );
}

const Search = ({ onSelect }) => {
    return (
        <AutoComplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
            onPlaceSelected={onSelect}
            className={`form-control`}
            options={{
                types: ['geocode', 'establishment'],
                componentRestrictions: { country: 'ca' },
            }}
            placeholder="Location"
        />
    );
};

const CustomPin = ({ customMarker, setCustomMarker, marker, setCenter }) => {
    return (
        <div className="address-search-input fill-input-container">
            <div className="fill-input">
                <div className="input-label" style={{ marginTop: 0 }}>
                    Pin Adjustment
                </div>
                <input
                    type="text"
                    className={`form-control`}
                    placeholder="Click map to adjust pin"
                    value={`${
                        customMarker.lat && customMarker.lng
                            ? `${customMarker.lat}, ${customMarker.lng}`
                            : ``
                    }`}
                    disabled
                />
            </div>
            <input
                type="button"
                className="btn btn-danger side-button"
                style={{ marginTop: 23 }}
                onClick={() => {
                    setCustomMarker({});

                    // Reset center (if it has a safe fallback)
                    if (marker.lat && marker.lng) {
                        setCenter(marker);
                    }
                }}
                value="X"
            />
        </div>
    );
};

export default AddressSearch;
