import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { AccountContext } from '../supertokens/Account';

import EditIcon from '@mui/icons-material/Edit';

function AdminEditLink({ sx = { top: 0, right: 0 }, section, id }) {
    const account = useContext(AccountContext);

    // Display nothing if not admin
    if (account.user.role !== 'Admin') {
        return null;
    }

    const btnStyle = {
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        marginLeft: '5px',
        marginBottom: '5px',
    };

    return (
        <Box position="absolute" sx={sx} zIndex={20} margin={2}>
            <button
                style={btnStyle}
                className="btn btn-primary"
                onClick={() => {
                    window.location.href = `/administration/${section}/create/${id}`;
                }}
            >
                <EditIcon />
            </button>
        </Box>
    );
}

export default AdminEditLink;
