import React, { useState, useEffect } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import axios from 'axios';

import Admin from '../../assets/badge/admin.svg';
import Broker from '../../assets/badge/broker.svg';
import Developer from '../../assets/badge/developer.svg';
import Verified from '../../assets/badge/verified.svg';

function UserBadges({ username, sellerUsername, height = 25 }) {
    const [badges, setBadges] = useState();

    useEffect(() => {
        let mounted = true;

        axios
            .post(`/user/getBadges`, { username: username })
            .then((res) => {
                if (mounted) {
                    setBadges(res.data);
                }
            })
            .catch(function (err) {});

        // Unmount
        return () => {
            mounted = false;
        };
    }, [username]);

    if (!username) {
        return null;
    }

    // Determine badge
    let badge = {};

    if (badges?.admin) {
        badge.icon = Admin;
        badge.label = 'Admin';
        badge.height = height;
    } else if (username === sellerUsername) {
        badge.icon = Developer;
        badge.label = 'Developer';
        badge.height = height * 1.1;
    } else if (badges?.broker) {
        badge.icon = Broker;
        badge.label = 'Broker';
        badge.height = height * 1.1;
    } else if (badges?.verified) {
        badge.icon = Verified;
        badge.label = 'Verified';
        badge.height = height;
    }

    return (
        <Box display="flex" sx={{ flexFlow: 'wrap' }}>
            <Badge
                label={badge.label}
                icon={badge.icon}
                height={badge.height}
                enabled
            />
        </Box>
    );
}

const Badge = ({ label, enabled, icon, height }) => {
    if (!enabled) {
        return null;
    }

    return (
        <Tooltip
            title={
                <Typography padding={0.25} fontSize={14}>
                    {label}
                </Typography>
            }
            placement="top"
            enterTouchDelay={0}
        >
            <img
                height={height}
                src={icon}
                alt=""
                style={{
                    marginRight: 5,
                }}
            />
        </Tooltip>
    );
};

export default UserBadges;
