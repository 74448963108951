import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import BidModal from './BidModal';
import { Button, Typography } from '@mui/material';
import modalStatuses from '../../constants/modalStatuses';
import { AccountContext } from '../supertokens/Account';
import auctionTypes from '../../constants/auctionTypes';
import IDVModal from '../modal/IDVModal';

function BidInput({
    auctionId,
    exclusiveId,
    bidMin,
    createStatusModal,
    auctionTypeId,
    createConfetti,
}) {
    const account = useContext(AccountContext);

    const [bid, setBid] = useState('');
    const [bidError, setBidError] = useState(false);
    const [bidModalShow, setBidModalShow] = useState(false);

    const [showIDVModal, setShowIDVModal] = useState(false);

    const [buttonText, setButtonText] = useState('');
    const hasInputArea =
        auctionTypeId === auctionTypes.STANDARD.id ||
        auctionTypeId === auctionTypes.SILENT.id;

    useEffect(() => {
        switch (auctionTypeId) {
            case auctionTypes.STANDARD.id:
                setButtonText('Bid');
                break;
            case auctionTypes.BUY_NOW.id:
                setButtonText('Buy Now');
                break;
            case auctionTypes.REVERSE.id:
                setButtonText('Buy Now');
                break;
            case auctionTypes.SILENT.id:
                setButtonText('Bid');
                break;
            default:
                break;
        }
    }, [auctionTypeId]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!account.signedIn) {
            //setLoginModalShow(true);
            return;
        }
        // if (!account.user.verified) {
        //     setShowIDVModal(true);
        //     return;
        // }

        switch (auctionTypeId) {
            case auctionTypes.STANDARD.id:
                submitStandard();
                break;
            case auctionTypes.BUY_NOW.id:
                setBid(bidMin.toString());
                setBidModalShow(true);
                break;
            case auctionTypes.REVERSE.id:
                setBid(bidMin.toString());
                setBidModalShow(true);
                break;
            case auctionTypes.SILENT.id:
                submitStandard();
                break;
            default:
                break;
        }
    };

    const submitStandard = () => {
        // Remove commas/locale formatting from bid string
        const bidRaw = strip(bid);

        // Validate
        if (
            !bidRaw ||
            bidRaw === undefined ||
            bidRaw === '' ||
            +bidRaw < +bidMin
        ) {
            setBidError(true);
        }

        // Submit bid
        else {
            // Validate bid input
            axios
                .post(`/bid/validateSubmission/`, {
                    auctionId: auctionId,
                    amount: bidRaw,
                    brokerId: exclusiveId,
                })
                .then((validation) => {
                    if (validation.data.valid) {
                        setBidModalShow(true);
                    } else {
                        // Inform user their bid request failed
                        createStatusModal(
                            modalStatuses.DANGER,
                            validation.data.message.title,
                            validation.data.message.body
                        );

                        // Close bid modal
                        submitBidFail();
                    }
                })
                .catch(function () {});
        }
    };

    const submitBidSuccess = (title, body, link) => {
        createStatusModal(modalStatuses.SUCCESS, title, body, link, 'Next');
        setBidModalShow(false);
        setBid('');

        // Confetti!
        createConfetti();
    };

    const submitBidFail = () => {
        setBidError(true);
        setBidModalShow(false);
    };

    return (
        <div className="auction-padding">
            <div>
                <form style={{ display: 'flex' }}>
                    {hasInputArea && (
                        <Input
                            bid={bid}
                            setBid={setBid}
                            bidMin={bidMin}
                            bidError={bidError}
                            setBidError={setBidError}
                            bidModalShow={bidModalShow}
                        />
                    )}
                    <Button
                        onClick={handleSubmit}
                        variant="blue"
                        sx={{
                            width: hasInputArea ? '40%' : '100%',
                            height: 57,
                            marginLeft: { xs: 0, md: hasInputArea ? 2.5 : 0 },
                        }}
                        type="submit"
                    >
                        <Typography
                            fontSize={23}
                            fontWeight="bold"
                            fontFamily="urbanist"
                        >
                            {buttonText}
                        </Typography>
                    </Button>
                </form>
            </div>
            <BidModal
                show={bidModalShow}
                backdrop="static"
                onClose={() => setBidModalShow(false)}
                submitBidSuccess={submitBidSuccess}
                submitBidFail={submitBidFail}
                amount={strip(bid)}
                auctionId={auctionId}
                auctionTypeId={auctionTypeId}
                exclusiveId={exclusiveId}
                setShow={setBidModalShow}
                createStatusModal={createStatusModal}
            />
            <IDVModal
                show={showIDVModal}
                onClose={() => setShowIDVModal(false)}
            />
        </div>
    );
}

const Input = ({
    bid,
    setBid,
    bidMin,
    bidError,
    setBidError,
    bidModalShow,
}) => {
    const [focus, setFocus] = useState(false);

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
                <Typography
                    fontSize={36}
                    fontWeight="bold"
                    className={`underline-thin ${
                        focus || bid.length > 1 || bid > 0
                            ? 'input-focus'
                            : 'input-unfocused'
                    }`}
                    paddingLeft={{ xs: 0, md: 3 }}
                    fontFamily="urbanist"
                >
                    $
                </Typography>
                <Typography fontSize={36}>
                    <input
                        style={{ backgroundColor: 'transparent' }}
                        color="dark"
                        type="text"
                        min={bidMin}
                        className={`bid-input underline-thin ${
                            bidError ? 'is-invalid' : ''
                        }`}
                        value={bid}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === '') {
                                setBid(value);
                            } else {
                                setBid((+strip(value)).toLocaleString());
                            }
                            setBidError(false);
                        }}
                        onFocus={() => {
                            setFocus(true);
                            if (bid === 0 || bid === '0') {
                                setBid('');
                            }
                        }}
                        onBlur={() => {
                            setFocus(false);
                            if (bid === '') {
                                setBid('');
                            }
                        }}
                        disabled={bidModalShow}
                    />
                </Typography>
            </div>
            <Typography
                className="flex"
                fontSize={13}
                color={bidError ? 'red' : 'text.dark'}
                marginTop={1}
            >
                {`Place a quick bid. Bid a minimum of $${(+bidMin).toLocaleString()}.`}
            </Typography>
        </div>
    );
};

const strip = (str) => {
    return str.replace(/\D/g, '');
};

export default React.memo(BidInput);
