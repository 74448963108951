import Sortable from 'sortablejs';

const removeClone = (e) => {
    e.clone
        .getElementsByClassName('button-small')[0]
        .addEventListener('click', function (e) {
            e.target.parentNode.remove();
        });
};

const setupMedia = (id, clone) => {
    var el = document.getElementById(id);
    if (el) {
        Sortable.create(el, {
            animation: 50,
            group: {
                name: 'media',
                pull: clone ? 'clone' : true,
                put: clone ? false : true,
            },
            onClone: clone ? removeClone : null,
            sort: clone ? false : true,
        });
    }
};

const setupHighlights = (setValue) => {
    // Highlight List
    var elHighlights = document.getElementById('highlights');
    if (elHighlights) {
        Sortable.create(elHighlights, {
            animation: 50,
            onSort: function (e) {
                const ul =
                    elHighlights.getElementsByClassName('draggable-input');
                const items = [];
                for (let i = 0; i < ul.length; i++) {
                    const li = ul[i];
                    items.push(li.innerHTML);
                }
                setValue('highlights', items);
            },
        });
    }
};

export { setupMedia, setupHighlights };
