import React, { useEffect } from 'react';
import { Avatar as MUIAvatar } from '@mui/material';

function Avatar({ username, xs, sm, md, lg, xl }) {
    useEffect(() => {}, []);

    const font =
        (xs && '15px') ||
        (sm && '20px') ||
        (md && '35px') ||
        (lg && '45px') ||
        (xl && '65px');

    return (
        <MUIAvatar
            sx={{
                color: 'white',
                backgroundColor: '#222222',
                width: '100%',
                height: '100%',
                fontSize: font,
                fontWeight: 'medium',
                textTransform: 'uppercase',
                fontFamily: 'urbanist',
                border: '1px solid white',
            }}
        >
            <div>{username?.[0]}</div>
        </MUIAvatar>
    );
}

export default Avatar;
