import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import CollapseContainer from '../common/CollapseContainer';
import { DataGrid } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, IconButton, Box, Button } from '@mui/material';
import { getRow } from '../../scripts/table';
import PopupForm from '../common/PopupForm';
import { useYup } from '../../scripts/cms';
import { provinces } from '../../scripts/validation';
import { toast } from 'react-toastify';
import InputForm from '../common/InputForm';

function BrokerRequests() {
    const [brokers, setBrokers] = useState([]);

    const [row, setRow] = useState();
    const [showInfoDialog, setShowInfoDialog] = useState(false);

    useEffect(() => {
        axios
            .get(`/broker/getRegistrationRequests`)
            .then((res) => {
                setBrokers(res.data);
            })
            .catch(function () {});
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'info',
            headerName: 'Info',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setRow(getRow(e, params));
                    setShowInfoDialog(true);
                };

                return (
                    <Tooltip title="Information">
                        <IconButton onClick={onClick}>
                            <InfoIcon style={{ color: '#1874FF' }} />
                        </IconButton>
                    </Tooltip>
                );
            },
            sortable: false,
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
    ];

    return (
        <div>
            <CollapseContainer title="Broker Requests">
                <div style={{ height: 645, width: '100%' }}>
                    <DataGrid
                        rows={brokers.map((broker) => {
                            return {
                                id: broker.id,
                                username: broker.user.username,
                                email: broker.user.email,
                                firstName: broker.firstName,
                                lastName: broker.lastName,
                                updatedAt: moment(broker.updatedAt).format(
                                    `MMM D[,] h:mm A`
                                ),
                            };
                        })}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                    />
                </div>
            </CollapseContainer>

            <PopupForm
                title="Information"
                body={
                    <BrokerRequestInfo
                        broker={brokers?.filter((a) => a.id === row?.id)[0]}
                        brokers={brokers}
                        setBrokers={setBrokers}
                        onClose={() => setShowInfoDialog(false)}
                    />
                }
                show={showInfoDialog}
                setShow={setShowInfoDialog}
                width={600}
            />
        </div>
    );
}

function BrokerRequestInfo({ broker, brokers, setBrokers, onClose }) {
    const [showApproveDialog, setShowApproveDialog] = useState(false);
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const [editing, setEditing] = useState(false);

    const inputs = [
        [
            { id: 'brokerage', disabled: !editing },
            {
                id: 'brokeragePhone',
                label: 'Brokerage Phone Number',
                disabled: !editing,
                mask: '+99999999999',
            },
            {
                id: 'brokerageFax',
                label: 'Brokerage Fax Number',
                disabled: !editing,
            },
        ],
        [
            { id: 'firstName', disabled: !editing },
            { id: 'middleName', disabled: !editing, optional: true },
            { id: 'lastName', disabled: !editing },
        ],
        [
            { id: 'email', label: 'Broker Email', disabled: !editing },
            { id: 'title', label: 'Broker Title', disabled: !editing },
        ],
        [
            { id: 'country', disabled: true },
            { id: 'province', children: provinces, disabled: !editing },
        ],
        [
            { id: 'city', disabled: !editing },
            { id: 'postalCode', disabled: !editing, mask: 'a9a 9a9' },
        ],
        [
            { id: 'streetAddress', disabled: !editing },
            { id: 'unit', disabled: !editing },
        ],
    ];
    const yup = useYup(inputs, broker);

    const approve = (broker) => {
        axios
            .post(`/broker/approveBroker`, yup.getValues())
            .then((res) => {
                setBrokers(brokers.filter((a) => a.id !== broker.id));
                onClose();
            })
            .catch(function (err) {
                toast.error(err?.response?.data?.message || 'Request failed.');
            })
            .finally(function () {
                setShowApproveDialog(false);
            });
    };

    const reject = (broker, form) => {
        axios
            .post(`/broker/rejectBroker`, {
                id: broker.id,
                response: form.response,
                username: broker.user.username,
            })
            .then((res) => {
                setBrokers(brokers.filter((a) => a.id !== broker.id));
                onClose();
            })
            .catch(function () {})
            .finally(function () {
                setShowRejectDialog(false);
            });
    };

    if (!broker) {
        return null;
    }

    return (
        <Box>
            <InputForm yup={yup} inputs={inputs} />
            <Box display="flex" columnGap={1}>
                <Button
                    size="small"
                    fullWidth
                    variant="blue"
                    onClick={() => setEditing(!editing)}
                >
                    {editing ? 'Save' : 'Edit'}
                </Button>
                <Button
                    size="small"
                    fullWidth
                    variant="dark"
                    onClick={() => setShowApproveDialog(true)}
                    disabled={editing}
                >
                    Approve
                </Button>
                <Button
                    size="small"
                    fullWidth
                    variant="danger"
                    onClick={() => setShowRejectDialog(true)}
                >
                    Reject
                </Button>
            </Box>

            <PopupForm
                title="Approve broker request?"
                body={
                    <div>
                        Are you sure you want to approve this broker request and
                        make the user a broker?
                    </div>
                }
                action={() => approve(broker)}
                show={showApproveDialog}
                setShow={setShowApproveDialog}
            />

            <PopupForm
                title="Reject broker request?"
                body={
                    <div>
                        Are you sure you want to reject this broker request?
                    </div>
                }
                action={(form) => reject(broker, form)}
                show={showRejectDialog}
                setShow={setShowRejectDialog}
                inputs={[[{ id: 'response', rows: 4 }]]}
            />
        </Box>
    );
}

export default BrokerRequests;
