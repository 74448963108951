import React from 'react';
import { Modal } from 'react-bootstrap';
import AddressSearch from './AddressSearch';

function AddressSearchModal({ show, onHide, updatePick, address, text }) {
    return (
        <Modal show={show} onHide={onHide} size="xl" centered>
            <AddressSearch
                onPick={(location) => updatePick(location)}
                address={address}
                text={text}
            />
        </Modal>
    );
}

export default AddressSearchModal;
