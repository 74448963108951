import { useContext, useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import FullscreenDialog from '../modal/FullscreenDialog';
import axios from 'axios';
import { AccountContext } from '../supertokens/Account';
import { toast } from 'react-toastify';
import DynamicIcon from '../common/DynamicIcon';
import IDVModal from '../modal/IDVModal';

function AddBankButton({
    businessId,
    developerId,
    handleExit = () => {
        window.location.reload();
    },
    disabled,
}) {
    const account = useContext(AccountContext);

    const [showIDV, setShowIDV] = useState(false);

    function handleAddBankAccount() {
        axios
            .post(`/zum/getEmbedUrl`, { businessId, developerId })
            .then((res) => {
                window.ZumRailsSDK.init({
                    token: res.data,
                    options: {
                        testinstitution:
                            process.env.REACT_APP_ENV !== 'production',
                    },
                    // onLoad: function () {
                    //     console.log('onLoad');
                    // },
                    // onError: function (error) {
                    //     console.log('onError', error);
                    // },
                    onSuccess: function (result) {
                        handleExit(result.userId);
                    },
                    // onStepChanged: function (data) {
                    //     console.log('onStepChanged', data);
                    // },
                    // onConnectorClosed: function () {
                    //     console.log('onConnectorClosed');
                    // },
                });
            })
            .catch(function (err) {
                console.log(err);
                toast.error(
                    'Unable to complete your request. Please try again later.'
                );
            });
    }

    function handleClick() {
        if (account.user.verified) {
            handleAddBankAccount();
        }
    }

    return (
        <Box>
            {!account.user.verified && (
                <Box display="flex" gap={1} paddingBottom={2}>
                    <DynamicIcon icon="Cancel" colour="status.danger" />
                    <Typography>
                        You must complete an ID Verification before you can set
                        up online payments.
                    </Typography>
                </Box>
            )}

            <Box display="flex" gap={1}>
                <Button
                    variant="white"
                    size="tiny"
                    type="border"
                    onClick={handleClick}
                    disabled={!account.user.verified || disabled}
                >
                    Connect Bank Account
                </Button>
                {!account.user.verified && (
                    <Button
                        variant="white"
                        size="tiny"
                        type="border"
                        onClick={() => setShowIDV(true)}
                    >
                        Verify
                    </Button>
                )}
            </Box>

            <IDVModal show={showIDV} onClose={() => setShowIDV(false)} />
        </Box>
    );
}

export default AddBankButton;
