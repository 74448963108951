import React from 'react';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import Footer from '../components/common/Footer';

import image1 from '../assets/404_1.jpg';
import SocialButtons from '../components/common/SocialButtons';

import axios from 'axios';
import { useYup } from '../scripts/cms';
import InputForm from '../components/common/InputForm';
import { toast } from 'react-toastify';

function ContactUsPage() {
    const inputs = [
        [{ id: 'email' }],
        [{ id: 'name' }],
        [{ id: 'subject' }],
        [{ id: 'message', rows: 6 }],
    ];
    const yup = useYup(inputs);

    function handleSubmit(form) {
        axios
            .post(`/email/contactUs/`, form)
            .then((res) => {
                toast.success('Thank you!');
                // Clear form
                yup.setValue('email', '');
                yup.setValue('name', '');
                yup.setValue('subject', '');
                yup.setValue('message', '');
            })
            .catch(function (err) {
                const { errors } = err?.response?.data;

                for (var name in errors) {
                    const error = errors[name];
                    if (error?.length > 0) {
                        yup.setError(name, { message: error });
                    }
                }
            });
    }

    return (
        <Box>
            <Box
                sx={{
                    height: 'calc(100vh - 70px)',
                    backgroundColor: 'dark',
                    flexDirection: { xs: 'column', md: 'row' },
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    flex={1}
                    height="100%"
                    backgroundColor="dark"
                    position="relative"
                >
                    <CardMedia
                        component="img"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: 'rgba(0, 0, 0, 0.5)',
                        }}
                        image={image1}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: '#222222bb',
                        }}
                    />
                    <Box
                        backgroundColor="white"
                        sx={{
                            width: '100%',
                            maxHeight: {
                                xs: 'calc(100vh - 70px - 150px)',
                                md: 'calc(100vh - 70px)',
                            },
                            overflow: 'auto',
                            maxWidth: 500,
                            padding: 5,
                            margin: 1,
                            borderRadius: 4,
                        }}
                        zIndex={1}
                        margin="auto"
                    >
                        <Typography
                            fontWeight="medium"
                            fontSize={24}
                            marginBottom={3}
                            textAlign="center"
                        >
                            Contact Us
                        </Typography>
                        <InputForm yup={yup} inputs={inputs} />
                        {yup.errors?.server && (
                            <Typography
                                className="text-danger"
                                marginTop={2}
                                fontSize={14}
                                component="div"
                            >
                                {yup.errors?.server?.message}
                            </Typography>
                        )}
                        <Button
                            variant="dark"
                            fullWidth
                            sx={{ marginTop: 2 }}
                            onClick={yup.handleSubmit(() =>
                                handleSubmit(yup.getValues())
                            )}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: { xs: '100%', md: 200 },
                        height: { xs: 150, md: '100%' },
                        paddingTop: { xs: 0, md: '20vh' },
                        paddingBottom: { xs: 0, md: '20vh' },
                        paddingLeft: { xs: '20vw', md: 0 },
                        paddingRight: { xs: '20vw', md: 0 },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: 'auto',
                        flexDirection: { xs: 'row', md: 'column' },
                    }}
                    width={300}
                    backgroundColor="white"
                >
                    <SocialButtons />
                </Box>
            </Box>
            <Footer />
        </Box>
    );
}

export default ContactUsPage;
