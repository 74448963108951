const mediaTypes = {
    IMAGE: 1,
    VIDEO: 2,
    FLOOR_PLAN: 3,
    SITE_PLAN: 4,
    DOCUMENT: 5,
    LOGO: 6,
    FILES: [3, 4, 5],
};

// Return filtered list of media that match the given media type(s)
export const filterMedia = (
    list,
    typeIds = [],
    path = ['media', 'mediaType']
) => {
    var items = [];

    if (list) {
        items = list.filter((item) => {
            var mediaType = item;

            for (let i = 0; i < path.length; i++) {
                mediaType = mediaType?.[path[i]];
            }

            return compareMediaType(mediaType?.id, typeIds);
        });
    }

    return items;
};

// Check if current media type matches one in the list of given media type ids
export const compareMediaType = (mediaTypeId, typeIds) => {
    for (let i = 0; i < typeIds.length; i++) {
        if (mediaTypeId === typeIds[i]) {
            return true;
        }
    }

    return false;
};

export default mediaTypes;
