import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import CardList from '../common/CardList';
import Notification from '../common/Notification';
import ViewAllContainer from './ViewAllContainer';
import Loading from '../common/Loading';

function Notifications() {
    const [notifications, setNotifications] = useState();

    useEffect(() => {
        axios
            .get(`/notification/getAll`)
            .then((res) => {
                setNotifications(res.data);
            })
            .catch(function () {});
    }, []);

    const NotificationCard = ({ item }) => {
        //const border = item.read ? '1px solid #dedede' : '0px';
        return (
            <Box /*border={border}*/>
                <Notification item={item} setNotifications={setNotifications} />
            </Box>
        );
    };

    // Display loading indication
    if (notifications === null || notifications === undefined) {
        return <Loading />;
    }

    return (
        <div className="user-content user-content-width">
            <ViewAllContainer
                title="Notifications"
                length={notifications?.length}
                maxWidth={500}
            >
                <CardList
                    items={notifications}
                    Component={NotificationCard}
                    lg={12}
                    md={12}
                    xs={12}
                    noCenter
                    padding={0}
                />
            </ViewAllContainer>
        </div>
    );
}

export default Notifications;
