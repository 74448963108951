import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    TextField,
    Grid,
    Divider,
    IconButton,
} from '@mui/material';
import ModalCard from '../modal/ModalCard';
import axios from 'axios';
import moment from 'moment';
import { Add, Close } from '@mui/icons-material';

function UserSelectorModal({
    show,
    onClose,
    handleSubmit,
    existingSelection /* later */,
}) {
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const delay = setTimeout(() => {
            handleSearch(search);
        }, 500);

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function handleSearch(query) {
        axios
            .post(`/user/search?query=${query}`)
            .then((res) => {
                setSearchResults(res.data);
            })
            .catch(function () {});
    }

    function handleAdd(user) {
        // Add user to list if it isn't already there
        if (users.filter((u) => u.id === user.id).length === 0) {
            setUsers([...users, user]);
        }
    }
    function handleRemove(user) {
        // Remove user from user list
        setUsers(users.filter((u) => u.id !== user.id));
    }

    return (
        <Modal open={show} onClose={onClose} className="modal-center">
            <ModalCard
                show={show}
                onClose={onClose}
                width={800}
                overflowY="scroll"
            >
                <Typography
                    fontSize={18}
                    fontWeight="bold"
                    textAlign="center"
                    gutterBottom
                >
                    User Selector
                </Typography>
                <TextField
                    name="search"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    sx={{
                        input: { color: 'dark' },
                    }}
                    fullWidth
                    placeholder="Search"
                    variant="standard"
                />
                <Box marginTop={4}>
                    <Typography
                        fontWeight="bold"
                        gutterBottom
                    >{`Search Results (${searchResults.length})`}</Typography>
                    <UserList
                        users={searchResults}
                        handleAdd={handleAdd}
                        selectedUsers={users}
                    />
                </Box>
                <Box marginTop={4}>
                    <Typography
                        fontWeight="bold"
                        gutterBottom
                    >{`Selected Users (${users.length})`}</Typography>
                    <UserList
                        users={users}
                        handleRemove={handleRemove}
                        selectedUsers={users}
                    />
                </Box>
                <Box marginTop={5}>
                    <Button
                        fullWidth
                        variant="dark"
                        onClick={() => handleSubmit(users)}
                    >
                        Submit
                    </Button>
                </Box>
            </ModalCard>
        </Modal>
    );
}

function UserList({ users, selectedUsers, handleAdd, handleRemove }) {
    if (!users || users.length === 0) {
        return (
            <Typography padding={1} textAlign="center">
                No Users
            </Typography>
        );
    }

    return (
        <Box
            height={500}
            sx={{ overflowY: 'scroll' }}
            border="1px solid #ccc"
            borderRadius="8px 0px 0px 8px"
        >
            {users.map((user, i) => (
                <Box
                    key={user.id}
                    backgroundColor={i % 2 === 0 ? 'white' : '#fafafa'}
                >
                    <UserCard
                        user={user}
                        handleAdd={
                            selectedUsers.filter((u) => u.id === user.id)
                                .length === 0 && handleAdd
                        }
                        handleRemove={handleRemove}
                    />
                    {i + 1 < users.length && <Divider />}
                </Box>
            ))}
        </Box>
    );
}

function UserCard({ user, handleAdd, handleRemove }) {
    function TextPair({ title, value, colour = 'dark' }) {
        return (
            <Box display="flex" color={colour}>
                <Typography marginRight={1}>{title}:</Typography>
                <Typography>{value}</Typography>
            </Box>
        );
    }

    function Restrictions() {
        const restrictions = user.userRestrictions;

        if (!restrictions || restrictions.length === 0) {
            return null;
        }

        return (
            <TextPair
                title="Restrictions"
                value={restrictions.map((r) => (
                    <span key={r.id} style={{ marginRight: 5 }}>
                        {r.restriction.title}
                    </span>
                ))}
                colour="red"
            />
        );
    }

    return (
        <Grid container padding={3} position="relative">
            <Grid item xs={4}>
                <Typography color="text.light" fontSize={14} gutterBottom>
                    Identity
                </Typography>
                <Typography fontWeight="bold">{user.username}</Typography>
                <Typography fontWeight="bold">{user.email}</Typography>
                <Typography>{user.firstName}</Typography>
                <Typography>{user.middleName}</Typography>
                <Typography>{user.lastName}</Typography>
                <Typography fontSize={12}>{user.uuid}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography color="text.light" fontSize={14} gutterBottom>
                    Address
                </Typography>
                <Typography>{user.streetAddress}</Typography>
                <Typography>{user.postalCode}</Typography>
                <Typography>{user.city}</Typography>
                <Typography>{user.province}</Typography>
                <Typography>{user.country}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography color="text.light" fontSize={14} gutterBottom>
                    Stats
                </Typography>
                <TextPair
                    title="Signed Up"
                    value={moment(user.createdAt).format('MMMM DD, YYYY')}
                />
                <TextPair title="Verified" value={user.verified.toString()} />
                <TextPair title="Bids" value={user.bids.length} />
                <Restrictions />
            </Grid>
            {!handleRemove && (
                <IconButton
                    variant="blue"
                    size="tiny"
                    sx={{ position: 'absolute', bottom: 5, right: 5 }}
                    onClick={() => handleAdd(user)}
                    disabled={!handleAdd}
                >
                    <Add />
                </IconButton>
            )}
            {handleRemove && (
                <IconButton
                    variant="danger"
                    size="tiny"
                    sx={{ position: 'absolute', bottom: 5, right: 5 }}
                    onClick={() => handleRemove(user)}
                >
                    <Close />
                </IconButton>
            )}
        </Grid>
    );
}

export default UserSelectorModal;
