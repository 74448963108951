import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import CityList from '../components/city/CityList';
import Footer from '../components/common/Footer';
import { useParams } from 'react-router-dom';
import FullscreenMedia from '../components/modal/FullscreenMedia';
import AddressDetails from '../components/auction/AddressDetails';
import NotFound from './NotFound';
import Loading from '../components/common/Loading';
import CategoryList from '../components/common/CategoryList';
import PaddedBox from '../components/common/PaddedBox';

function ProjectPage() {
    const [project, setProject] = useState();
    const [failed, setFailed] = useState();

    const [fullscreenMedia, setFullscreenMedia] = useState(null);

    const { slug } = useParams();

    useEffect(() => {
        axios
            .get(`/project/get/${slug}`)
            .then((res) => {
                if (res.data) {
                    setProject(res.data);
                } else {
                    setFailed(true);
                }
            })
            .catch(function () {
                setFailed(true);
            });
    }, [slug]);

    if (failed) {
        return <NotFound />;
    } else if (!project) {
        return <Loading />;
    }

    return (
        <div>
            <AddressDetails
                project={project}
                setMedia={setFullscreenMedia}
                auctionTab
                mediaTab
                filesTab
            />
            <Box backgroundColor="tint" style={{ paddingBottom: 100 }}>
                <Typography
                    fontSize={35}
                    color="dark"
                    letterSpacing={0.5}
                    style={{
                        textAlign: 'center',
                        marginBottom: 70,
                        paddingTop: 110,
                    }}
                    variant="heading"
                    component="div"
                >
                    Browse By Category
                </Typography>
                <PaddedBox>
                    <CategoryList />
                </PaddedBox>
            </Box>
            <CityList />
            <Footer />
            <FullscreenMedia
                media={fullscreenMedia}
                setMedia={setFullscreenMedia}
            />
        </div>
    );
}

export default ProjectPage;
