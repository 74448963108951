const occupancyDeclaration = {
    PERSONAL: { id: 1, title: 'Primary residence of purchaser' },
    FAMILY: { id: 2, title: 'Primary residence of relative' },
    INVESTMENT: { id: 3, title: 'Investment property' },
};

export const occupancyDeclarations = [
    { id: 1, title: 'Primary residence of purchaser' },
    { id: 2, title: 'Primary residence of relative' },
    { id: 3, title: 'Investment property' },
];

export default occupancyDeclaration;
