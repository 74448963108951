import React from 'react';
import { Box, Typography } from '@mui/material';
import Footer from '../components/common/Footer';
import HTMLParse from '../components/common/HTMLParse';
import PaddedBox from '../components/common/PaddedBox';

function RawHTML({ file }) {
    return (
        <Box>
            <PaddedBox top={4} bottom={4}>
                <Typography component="div">
                    <HTMLParse value={file} />
                </Typography>
            </PaddedBox>
            <Footer />
        </Box>
    );
}

export default RawHTML;
