import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import NotFound from '../../pages/NotFound.js';

import { useYup } from '../../scripts/cms.js';
import CMSForm from './CMSForm.js';
import mediaTypes from '../../constants/mediaTypes.js';

function PropertyTypeForm() {
    const { id } = useParams();
    const [valid, setValid] = useState(true);

    const [data, setData] = useState();

    useEffect(() => {
        if (id) {
            axios
                .get(`/propertyType/get/${id}`)
                .then((res) => {
                    const propertyType = res.data;
                    setData({
                        ...propertyType,
                        media: { media: propertyType.media },
                    });
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [id]);

    const inputs = [
        [{ id: 'title' }, { id: 'plural' }, { id: 'slug' }],
        [{ id: 'description', paragraph: true }],
        [{ id: 'media', media: { limit: 1, mediaTypeId: mediaTypes.IMAGE } }],
    ];

    const yup = useYup(inputs);

    if (valid) {
        return (
            <CMSForm
                title="Property Type"
                api="propertyType"
                adminSlug="propertyTypes"
                data={data}
                inputs={inputs}
                _yup={yup}
            />
        );
    } else {
        return <NotFound />;
    }
}

export default PropertyTypeForm;
