import { useContext } from 'react';
import NotFound from '../../pages/NotFound';
import { AccountContext } from '../supertokens/Account';
import Header from '../common/Header';

function RequireDeveloper({ children, onLoad }) {
    const account = useContext(AccountContext);

    // TODO: Replace not found with not authorized
    return account.user.developerId > 0 ? (
        children
    ) : (
        <>
            <Header />
            <NotFound onLoad={onLoad} />
        </>
    );
}

export default RequireDeveloper;
