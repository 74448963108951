import React, { useRef } from 'react';
import {
    Box,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import AuctionParagraph from './AuctionParagraph';
import AuctionSection from './AuctionSection';
import { useSearchParams } from 'react-router-dom';
import CardList from '../common/CardList';
import BidHistory from './BidHistory';
import FileCard from '../cards/FileCard';
import CommentInput from './CommentInput';
import BidsAndComments from './BidsAndComments';
import mediaTypes, { filterMedia } from '../../constants/mediaTypes';
import { Amenity } from './AddressDetails';
import auctionTypes from '../../constants/auctionTypes';
import PaddedBox from '../common/PaddedBox';
import NoData from '../common/NoData';
import DepositSchedule from '../payment/DepositSchedule';

function AuctionDetails({ auction, setFullscreenMedia }) {
    // Simplify data structure
    const property = auction.property;
    const model = property.model;

    const replyComment = useRef(null);
    const [searchParams] = useSearchParams();

    const files = filterMedia(
        property.propertyMedia.map(function (propertyMedia) {
            return propertyMedia.projectMedia;
        }),
        mediaTypes.FILES
    );

    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Box paddingTop={6}>
            <Grid container spacing={{ xs: 0, md: 5, lg: 8 }}>
                <Grid item xs={12} md={6}>
                    <AuctionSection title="Highlights">
                        <Typography
                            className="highlights bullets"
                            component="ul"
                            fontSize={16}
                            color="dark"
                            lineHeight={1.8}
                        >
                            {property.highlights.map((item, i) => (
                                <li key={i}>{item.title}</li>
                            ))}
                        </Typography>
                    </AuctionSection>

                    {auction.bids.length > 0 &&
                        auction.auctionTypeId === auctionTypes.STANDARD.id && (
                            <AuctionSection
                                title={`Bid History (${auction.bidCount})`}
                            >
                                <BidHistory bids={auction.bids} />
                            </AuctionSection>
                        )}

                    <AuctionSection title="Deposit Schedule">
                        <DepositSchedule
                            depositSchedule={auction.depositSchedule}
                        />
                    </AuctionSection>
                    <AuctionSection title="Documents" noDivider={!xs}>
                        <CardList
                            items={files}
                            Component={FileCard}
                            xs={12}
                            md={12}
                            lg={6}
                            noCenter
                            padding={0}
                            setMedia={setFullscreenMedia}
                        />
                    </AuctionSection>
                </Grid>

                <Grid item xs={12} md={6}>
                    {auction.auctionIncentives?.length > 0 && (
                        <AuctionSection title="Incentives">
                            <Incentives
                                auctionIncentives={auction.auctionIncentives}
                            />
                        </AuctionSection>
                    )}

                    {property.upgrades?.length > 0 && (
                        <AuctionSection
                            title="Options"
                            noDivider={
                                !property.occupancyDate &&
                                !model.propertyTax &&
                                !model.purchaseProcedure &&
                                !model.mortgageRequirements
                            }
                        >
                            <UpgradeOptions upgrades={property.upgrades} />
                        </AuctionSection>
                    )}

                    <AuctionSection title="Building Amenities">
                        <Box paddingTop={2}>
                            <CardList
                                items={
                                    auction.property.address.project
                                        .projectAmenities
                                }
                                Component={Amenity}
                                xs={6}
                                md={4}
                                lg={3}
                                noCenter
                                vertical={0}
                            />
                        </Box>
                    </AuctionSection>

                    <AuctionParagraph
                        title="Estimated Occupancy Date"
                        text={property.occupancyDate}
                        noDivider={
                            !model.propertyTax &&
                            !model.purchaseProcedure &&
                            !model.mortgageRequirements
                        }
                    />

                    <AuctionParagraph
                        title="Property Tax"
                        text={model.propertyTax}
                        noDivider={
                            !model.purchaseProcedure &&
                            !model.mortgageRequirements
                        }
                    />

                    <AuctionParagraph
                        title="Purchase Procedure"
                        text={model.purchaseProcedure}
                        noDivider={!model.mortgageRequirements}
                    />

                    <AuctionParagraph
                        title="Mortgage Requirements"
                        text={model.mortgageRequirements}
                        noDivider
                    />
                </Grid>
            </Grid>

            <Divider sx={{ marginTop: 5 }} />

            {auction.auctionSystemMessages?.length > 0 && (
                <PaddedBox
                    left={{ xs: 0, md: '8vw', lg: '12vw' }}
                    right={{ xs: 0, md: '8vw', lg: '12vw' }}
                    top={6}
                >
                    <AuctionSection
                        title={`Comments (${auction.comments.length})`}
                        noDivider
                    >
                        {/* <CommentInput data={auction} replyComment={replyComment} /> */}
                        <BidsAndComments
                            //bids={auction.bids}
                            comments={auction.comments}
                            systemMessages={auction.auctionSystemMessages}
                            sellerUsername={
                                auction.property.address.project.developer.user
                                    .username
                            }
                            replyComment={replyComment}
                            anchorId={searchParams.get('commentId')}
                        />
                    </AuctionSection>
                </PaddedBox>
            )}
        </Box>
    );
}

function UpgradeOptions({ upgrades }) {
    const upgradeList = [];
    // Remove upgrade if there are no options to display
    for (let i = 0; i < upgrades.length; i++) {
        upgrades[i].upgradeOptions = upgrades[i].upgradeOptions.filter(
            (option) => option.quantity > 0 && option.available
        );
        if (upgrades[i].upgradeOptions.length > 0) {
            upgradeList.push(upgrades[i]);
        }
    }

    // Indicate when upgrade list is empty
    if (upgradeList.length === 0) {
        return (
            <NoData
                description="There are no upgrades available for this listing."
                paddingTop={5}
                paddingBottom={5}
            />
        );
    }

    return (
        <Box>
            {upgradeList.map((upgrade) => (
                <Box key={upgrade.id}>
                    <Box display="flex">
                        <Typography
                            fontWeight="bold"
                            gutterBottom
                            marginRight={1.5}
                        >
                            {upgrade.title}
                        </Typography>
                        {upgrade.maxQuantity > 0 && (
                            <Tooltip
                                title={
                                    <Typography fontSize={13}>
                                        A limit of {upgrade.maxQuantity} item(s)
                                        can be chosen in total
                                    </Typography>
                                }
                            >
                                <Typography
                                    marginBottom="auto"
                                    backgroundColor="#eee"
                                    paddingLeft={1.5}
                                    paddingRight={1.5}
                                    borderRadius={100}
                                    fontWeight="medium"
                                >
                                    {upgrade.maxQuantity}
                                </Typography>
                            </Tooltip>
                        )}
                    </Box>
                    {/* <Typography gutterBottom>{upgrade.description}</Typography> */}
                    <Box component="ul">
                        {upgrade.upgradeOptions.map((option) => (
                            <li key={option.id}>
                                <Box display="flex">
                                    <Typography
                                        marginRight={1}
                                        gutterBottom
                                        fontWeight="medium"
                                    >{`${option.title}:`}</Typography>
                                    <Typography>
                                        {option.price === 0
                                            ? 'Included with purchase'
                                            : `$${option.price.toLocaleString()}`}
                                    </Typography>
                                </Box>
                            </li>
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    );
}

function Incentives({ auctionIncentives }) {
    // Indicate when upgrade list is empty
    if (auctionIncentives.length === 0) {
        return (
            <NoData
                description="There are no incentives available for this listing."
                paddingTop={5}
                paddingBottom={5}
            />
        );
    }

    return (
        <Box>
            {auctionIncentives.map((auctionIncentive) => {
                const incentive = auctionIncentive.incentive;

                // Backup method to hide an incentive that shouldn't be listed here
                if (incentive.disableListing) {
                    return;
                }

                return (
                    <Box key={auctionIncentive.id} marginBottom={2}>
                        <Typography fontWeight="bold" gutterBottom>
                            {incentive.title}
                        </Typography>
                        <Typography gutterBottom>
                            {incentive.description}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
}

export default AuctionDetails;
