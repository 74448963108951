/*import React, { useState , useEffect } from 'react';
import CityCard from './CityCard';
import CardList from '../CardList';
import { Box } from '@mui/material';*/

function CityList() {
    return null;

    /*const [cities, setCities] = useState([]);

    useEffect(() => {
        axios
            .get(`/city/getAll`)
            .then((res) => {
                setCities(res.data);
            })
            .catch(function () {});
    }, []);


    return (
        <Box
            sx={{
                padding: { xs: 0, md: 8, lg: 12 },
                paddingTop: '0 !important',
            }}
        >
            <CardList xs={12} items={cities} Component={CityCard} />
        </Box>
    );*/
}

export default CityList;
