import React, { useState } from 'react';
import { getNextReverse } from '../../scripts/auction';
import Timer, { renderReverse, renderWhenDone } from '../common/Timer';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import auctionTypes, { auctionTypeList } from '../../constants/auctionTypes';
import { getBidValue, getBidValueHeader } from '../../scripts/language';
import AuctionTypeIndicator from './AuctionTypeIndicator';
import moment from 'moment';

function BidInfo({ auction, setAuction, concluded }) {
    const { bids, bidCount, auctionTypeId } = auction;

    const standard = auctionTypeId === auctionTypes.STANDARD.id;
    const silent = auctionTypeId === auctionTypes.SILENT.id;
    const full = standard || silent;

    const hasBids = bids.length > 0;

    const bidStatusPending = concluded && auction.sold === null;

    return (
        <Box marginTop={5}>
            <Box
                backgroundColor={concluded ? 'dark' : '#ededed'}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={60}
            >
                {concluded ? (
                    <ConcludedBar auction={auction} />
                ) : (
                    <AuctionTypeBar auctionTypeId={auction.auctionTypeId} />
                )}
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                backgroundColor="#f8f7f4"
                paddingTop={4}
                paddingBottom={4}
            >
                <div
                    style={{
                        flex: 1,
                        textAlign: 'center',
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        position="relative"
                    >
                        <Box>
                            {bidStatusPending ? (
                                <Box height={100} margin="auto" display="flex">
                                    <CircularProgress
                                        size={40}
                                        sx={{ margin: 'auto' }}
                                        color="dark"
                                    />
                                </Box>
                            ) : (
                                <>
                                    <Typography
                                        fontSize={14}
                                        letterSpacing={4.2}
                                        fontWeight="light"
                                        textTransform="uppercase"
                                        height={20}
                                        marginBottom={1.5}
                                        sx={{
                                            ':empty': {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        {getBidValueHeader(auction, concluded)}
                                    </Typography>
                                    <Typography
                                        fontSize={32}
                                        letterSpacing={4.5}
                                        fontFamily="urbanist"
                                        fontWeight="bold"
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        {getBidValue(auction, concluded)}
                                    </Typography>
                                    {hasBids && (!silent || auction.winner) && (
                                        <Typography
                                            fontSize={14}
                                            letterSpacing={1.2}
                                            display="flex"
                                            justifyContent="center"
                                            marginTop={1.5}
                                        >
                                            {auction.sold
                                                ? 'Purchased by'
                                                : 'Bid by'}
                                            <Typography
                                                fontSize={14}
                                                fontWeight="bold"
                                                display="flex"
                                                justifyContent="center"
                                                color="status.info"
                                                marginLeft={0.5}
                                                component={Link}
                                                href={`/user/${bids[0].user.username}/purchases`}
                                                className="link"
                                            >
                                                {`@${bids[0].user.username}`}
                                            </Typography>
                                        </Typography>
                                    )}
                                    {!concluded && (
                                        <Timer
                                            endDate={auction.startDate}
                                            renderer={renderWhenDone}
                                        >
                                            <ReverseAuction
                                                auction={auction}
                                                setAuction={setAuction}
                                            />
                                        </Timer>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                </div>
                {full && bidCount > 0 && (
                    <div
                        style={{
                            flex: 0.65,
                            textAlign: 'center',
                            borderLeft: '1px solid #ccc',
                        }}
                    >
                        <Typography
                            fontSize={14}
                            letterSpacing={4.2}
                            fontWeight="light"
                            textTransform="uppercase"
                            height={20}
                            marginBottom={1.5}
                        >
                            # of Bids
                        </Typography>
                        <Typography
                            fontSize={32}
                            letterSpacing={4.5}
                            fontFamily="urbanist"
                            fontWeight="bold"
                            display="flex"
                            justifyContent="center"
                        >
                            {bidCount}
                        </Typography>
                    </div>
                )}
            </Box>
        </Box>
    );
}

const ReverseAuction = ({ auction, setAuction }) => {
    const handleComplete = () => {
        const _next = getNextReverse(auction);
        if (!_next.endDate.isAfter(moment(auction.endDate))) {
            setNext(_next);
            setAuction({
                ...auction,
                minimumBid:
                    auction.startingBid - _next.ticks * auction.bidIncrement,
            });
        }
    };

    const [next, setNext] = useState(getNextReverse(auction));

    if (auction.finished || auction.auctionTypeId !== auctionTypes.REVERSE.id) {
        return null;
    }

    return (
        <Timer
            endDate={next.endDate}
            renderer={renderReverse}
            auction={auction}
            handleComplete={handleComplete}
            overtime={true}
            rate={auction.timeIncrement}
        />
    );
};

const ConcludedBar = ({ auction }) => {
    return (
        <Typography
            marginLeft={0.5}
            marginRight={1}
            fontWeight="bold"
            letterSpacing={1}
            fontSize={15}
            color="white"
        >
            {auction.sold ? 'Sold' : 'Not Sold'}
        </Typography>
    );
};

const AuctionTypeBar = ({ auctionTypeId }) => {
    return (
        <>
            <AuctionTypeIndicator auctionTypeId={auctionTypeId} />
            <Typography
                marginLeft={0.5}
                marginRight={1}
                fontWeight="bold"
                letterSpacing={1}
                fontSize={15}
            >
                {
                    auctionTypeList.filter(
                        (auctionType) => auctionType.id === auctionTypeId
                    )[0]?.title
                }
            </Typography>
        </>
    );
};

export default BidInfo;
