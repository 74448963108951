import moment from 'moment';

function getNextReverse(auction) {
    const start = moment(auction.startDate);
    const now = moment();

    const timeSinceStart = moment.duration(now.diff(start));
    const time = timeSinceStart.asMilliseconds();
    const ticks = Math.floor(time / auction.timeIncrement);
    const endDate = start.add(
        (ticks + 1) * auction.timeIncrement,
        'milliseconds'
    );

    return { ticks, endDate };
}

export { getNextReverse };
