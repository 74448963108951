import { Box } from '@mui/material';

function PaddedBox({
    top,
    bottom,
    left = { xs: 2, md: '8vw', lg: '12vw' },
    right = { xs: 2, md: '8vw', lg: '12vw' },
    disabled,
    backgroundColor,
    children,
    sx,
    ...props
}) {
    if (disabled) {
        return children;
    }
    return (
        <Box
            sx={{
                paddingLeft: left,
                paddingRight: right,
                paddingTop: top,
                paddingBottom: bottom,
                ...sx,
            }}
            backgroundColor={backgroundColor}
            {...props}
        >
            {children}
        </Box>
    );
}

export default PaddedBox;
