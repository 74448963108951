import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import UserSelectorModal from './UserSelectorModal';

// Handles launching the user selector modal, and saving the user selection in the desired foramt
function UserSelectorButton({ setValue, getValues, attribute = 'users' }) {
    const [showModal, setShowModal] = useState(false);

    function handleSubmit(users) {
        setValue(attribute, users);
        setShowModal(false);
    }

    return (
        <Box>
            <Button variant="dark" onClick={() => setShowModal(true)}>{`${
                getValues()[attribute]?.length || 'All'
            } Users`}</Button>
            <UserSelectorModal
                show={showModal}
                onClose={() => setShowModal(false)}
                handleSubmit={handleSubmit}
            />
        </Box>
    );
}

export default UserSelectorButton;
