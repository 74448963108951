import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    MenuItem,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import ModalCard from '../../modal/ModalCard';
import { getMediaElements, uploadFiles } from '../../../scripts/cms';
import { toast } from 'react-toastify';
import axios from 'axios';
import DragArea from './DragArea';
import mediaTypes from '../../../constants/mediaTypes';

function MediaUploader({ setValue, watch }) {
    const [show, setShow] = useState(false);

    useEffect(() => {}, []);

    const newMediaId = 'new-media-upload';

    const uploadedMedia = watch('new-media');

    return (
        <Box>
            {uploadedMedia?.length > 0 && (
                <DragArea
                    id={'new-media'}
                    source={uploadedMedia}
                    title="Available Media"
                />
            )}
            <Button variant="dark" fullWidth onClick={() => setShow(true)}>
                Upload Media
            </Button>
            <MediaUploadModal
                show={show}
                onClose={() => setShow(false)}
                setValue={setValue}
                newMediaId={newMediaId}
            />
        </Box>
    );
}

const MediaUploadModal = ({ show, onClose, setValue, newMediaId }) => {
    const [newMedia, setNewMedia] = useState([]);

    const [mediaTypes, setMediaTypes] = useState();
    const [mediaType, setMediaType] = useState();
    const [mediaInput, setMediaInput] = useState('');

    useEffect(() => {
        // Get media types
        axios
            .get(`/media/getMediaTypes`)
            .then((res) => {
                setMediaType(res.data[0]);
                setMediaTypes(res.data);
            })
            .catch(function () {});
    }, []);

    const handleSubmit = () => {
        setValue('new-media', getMediaElements(newMediaId));
        onClose();
    };

    return (
        <Modal open={show} onClose={onClose} className="modal-center">
            <ModalCard show={show} onClose={onClose} width={700}>
                <Typography fontWeight="bold" fontSize="large">
                    Uploads
                </Typography>
                <hr />
                <div>
                    <div style={{ display: 'flex' }}>
                        <MediaButton
                            mediaInput={mediaInput}
                            mediaType={mediaType}
                            newMedia={newMedia}
                            setNewMedia={setNewMedia}
                            setMediaInput={setMediaInput}
                        />
                        <MediaSelect
                            mediaType={mediaType}
                            mediaTypes={mediaTypes}
                            setMediaType={setMediaType}
                        />
                    </div>
                    {mediaType?.title === 'Video' && (
                        <input
                            className="form-control"
                            style={{
                                marginTop: 6,
                                width: '100%',
                            }}
                            value={mediaInput}
                            onChange={(e) => {
                                setMediaInput(e.target.value);
                            }}
                            placeholder="https://www.youtube.com/watch?v=nAd4LELJZM0"
                        />
                    )}
                    {newMedia?.length > 0 && (
                        <>
                            <DragArea id={newMediaId} source={newMedia} />
                            <Button
                                variant="dark"
                                fullWidth
                                onClick={handleSubmit}
                            >
                                Add to Editor
                            </Button>
                        </>
                    )}
                </div>
            </ModalCard>
        </Modal>
    );
};

const addURL = (
    mediaInput,
    mediaType,
    newMedia,
    setNewMedia,
    setMediaInput
) => {
    if (mediaInput?.length > 0) {
        // YouTube
        const split = mediaInput.split('youtube.com/watch?v=');
        let id = null;

        if (split.length === 2) {
            id = split[1];
            const item = {
                media: {
                    url: id,
                    id: null,
                    mediaTypeId: mediaTypes.VIDEO,
                    title: id,
                    src: id,
                    mediaType: mediaType,
                },
            };

            setNewMedia([...newMedia, item]);
            setMediaInput('');
        } else {
            toast.warning('YouTube link is invalid');
        }
    }
};

const MediaButton = ({
    mediaInput,
    mediaType,
    newMedia,
    setNewMedia,
    setMediaInput,
}) => {
    let child;

    if (mediaType?.id === mediaTypes.VIDEO) {
        child = (
            <button
                className="btn btn-danger btn-wide"
                onClick={() =>
                    addURL(
                        mediaInput,
                        mediaType,
                        newMedia,
                        setNewMedia,
                        setMediaInput
                    )
                }
                disabled={mediaInput?.length === 0}
            >
                Upload
            </button>
        );
    } else {
        child = (
            <div>
                <label
                    className="btn btn-primary btn-wide"
                    htmlFor="file-input"
                >
                    {`Upload (${mediaType?.fileType || 'Custom'})`}
                </label>
                <input
                    id="file-input"
                    type="file"
                    accept={mediaType?.fileType}
                    onChange={() =>
                        uploadFiles(newMedia, setNewMedia, mediaType)
                    }
                    multiple
                />
            </div>
        );
    }

    return (
        <div className="flex" style={{ marginRight: 3 }}>
            {child}
        </div>
    );
};

const MediaSelect = ({ mediaType, mediaTypes, setMediaType }) => {
    return (
        <div style={{ width: '40%' }}>
            {mediaTypes && (
                <TextField
                    select
                    size="small"
                    // @ts-ignore [2]
                    value={mediaType}
                    label="Media Type"
                    onChange={(e) => {
                        setMediaType(e.target.value);
                    }}
                    style={{
                        width: '100%',
                        marginLeft: 3,
                    }}
                >
                    {mediaTypes.map((mediaType) => (
                        <MenuItem key={mediaType.id} value={mediaType}>
                            {mediaType.title}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </div>
    );
};

export default MediaUploader;
