import React from 'react';

import { Link } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

function SocialButtons({ spacing = 3 }) {
    const socials = [
        { Icon: FacebookIcon, path: '' },
        { Icon: InstagramIcon, path: '' },
        // { Icon: TwitterIcon, path: 'https://x.com/upsidewithus' },
        // {
        //     Icon: LinkedInIcon,
        //     path: 'https://www.linkedin.com/company/upsidewithus',
        // },
        { Icon: EmailIcon, path: 'mailto:hello@upside.re' },
    ];

    return (
        <>
            {socials.map((social, i) => (
                <Item
                    key={i}
                    Icon={social.Icon}
                    path={social.path}
                    spacing={spacing}
                />
            ))}
        </>
    );
}

const Item = ({ Icon, path, spacing }) => {
    if (path?.length <= 0) {
        return null;
    }

    return (
        <Link href={path} color="dark" paddingRight={spacing} fontSize={30}>
            <Icon fontSize="inherit" />
        </Link>
    );
};

export default SocialButtons;
