import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import Input from './Input';
import axios from 'axios';
import PopupForm from '../../common/PopupForm';

function UserInfo({ user, setUser }) {
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [errors, setErrors] = useState();

    const handleUpdatePassword = (form) => {
        axios
            .post(`/auth/changePassword`, form)
            .then((res) => {
                toast.success('Password changed successfully');
                setShowChangePassword(false);
            })
            .catch(function (err) {
                setErrors(err?.response?.data);
            });
    };

    function handleUpdateUser(form) {
        axios
            .post(`/user/updatePersonal`, form)
            .then((res) => {
                setUser({ ...user, ...form });
                setShowUpdate(false);
            })
            .catch(function (err) {
                setErrors(err?.response?.data);
            });
    }

    return (
        <Box>
            <Grid container spacing={1}>
                <Input
                    label="Username"
                    value={user?.username}
                    md={4}
                    disabled
                />
                <Input label="Email" value={user?.email} md={4} disabled />
                <Input
                    label="Preferred First Name"
                    value={user?.contactName}
                    md={4}
                    disabled
                />
            </Grid>
            <Grid container gap={1} spacing={1} marginBottom={2}>
                <Button
                    onClick={() => setShowChangePassword(!showChangePassword)}
                    display="inline-block"
                    variant="dark"
                    item
                >
                    Update Password
                </Button>
                <Button
                    onClick={() => setShowUpdate(!showUpdate)}
                    display="inline-block"
                    variant="dark"
                    item
                >
                    Update Name
                </Button>
            </Grid>
            <PopupForm
                title="Change Password"
                yes="Submit"
                show={showChangePassword}
                setShow={setShowChangePassword}
                inputs={[
                    [{ id: 'currentPassword', password: true }],
                    [{ id: 'newPassword', password: true }],
                ]}
                action={handleUpdatePassword}
                errors={errors}
                setErrors={setErrors}
            />
            <PopupForm
                title="Change Preferred First Name"
                yes="Submit"
                show={showUpdate}
                setShow={setShowUpdate}
                inputs={[
                    [{ id: 'contactName', label: 'First Name', maxLength: 21 }],
                ]}
                action={handleUpdateUser}
                errors={errors}
                setErrors={setErrors}
                defaults={user}
            />
        </Box>
    );
}

export default UserInfo;
