import React from 'react';
import Header from '../common/Header';
import imgHero from '../../assets/home_hero.jpg';

function ShowcaseRoute({ children }) {
    return (
        <>
            <Header />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 70px)',
                }}
            >
                <div
                    style={{
                        margin: 'auto',
                        maxHeight: '100%',
                        width: '100%',
                        zIndex: 2,
                        overflow: 'auto',
                    }}
                >
                    {children}
                </div>
            </div>
            <div className="home-header-overlay" />
            <img
                className="home-header-image"
                src={imgHero}
                alt=""
                style={{ filter: 'blur(2px)' }}
            />
        </>
    );
}

export default ShowcaseRoute;
