import { Box, Typography } from '@mui/material';

function ExclusivityIndicator({
    brokerId,
    userExclusive,
    brokerAccess,
    adminAccess,
    height,
}) {
    let text = null;

    // Broker Viewing Client Listing
    if (brokerAccess) {
        if (brokerId) {
            text = 'Broker Exclusive';
        } else {
            text = 'Client Exclusive';
        }
    }
    // User Exclusive
    else if (userExclusive) {
        text = 'Exclusive';
    }
    // Broker Exclusive
    else if (brokerId) {
        text = 'Exclusive';
    }

    if (!text) {
        return null;
    }

    return (
        <Box
            paddingTop={1}
            paddingBottom={1}
            height={`${height}px`}
            display="flex"
        >
            <Box
                sx={{
                    background:
                        'linear-gradient(90deg, rgba(18, 21, 23, 0.9) 25%, rgba(18, 21, 23, 0) 75%)',
                }}
                display="flex"
                width="100%"
            >
                <Typography
                    fontSize={18}
                    marginTop="auto"
                    marginBottom="auto"
                    marginRight={5}
                    marginLeft={2}
                    color="white"
                    letterSpacing={1.5}
                >
                    {text}
                </Typography>
            </Box>
        </Box>
    );
}

export default ExclusivityIndicator;
