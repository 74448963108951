import { ImageList, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { setupMedia } from '../../../scripts/sortable';
import DraggableMedia from '../DraggableMedia';

function DragArea({ id, source = [], title, canDelete, cols = 4, link }) {
    useEffect(() => {
        setupMedia(id, !canDelete);
    }, [id, canDelete]);

    const hasItems = source?.length > 0;

    return (
        <div style={{ marginBottom: 20 }}>
            {title && (
                <Typography
                    gutterBottom
                    component={link && Link}
                    href={link}
                    style={{
                        textTransform: 'none',
                        textDecoration: 'none',
                    }}
                >
                    {title}
                </Typography>
            )}
            {!hasItems && !canDelete && (
                <Typography color="text.secondary" variant="subtitle2">
                    No media available
                </Typography>
            )}
            <div
                className="image-container"
                style={{
                    display: hasItems || canDelete ? 'block' : 'none',
                }}
            >
                <ImageList
                    cols={cols}
                    className={canDelete ? 'can-delete' : ''}
                    id={id}
                    style={{ marginBottom: 0, minHeight: 118 }}
                >
                    {source.map((item, i) => (
                        <DraggableMedia key={i} item={item} />
                    ))}
                </ImageList>
            </div>
        </div>
    );
}

export default DragArea;
