import { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

function TableContainer({ title, selectedRows = [], buttons = [], children }) {
    const BORDER_RADIUS = '10px';

    const HeaderButton = ({ action, variant = 'white', children }) => {
        return (
            <Button
                variant={variant}
                size="tiny"
                sx={{ marginLeft: 1 }}
                onClick={action}
                disabled={selectedRows.length === 0}
            >
                {children}
            </Button>
        );
    };

    return (
        <Box>
            <Typography fontWeight="bold" fontSize={22} marginBottom={2}>
                {title}
            </Typography>
            <Box border="1px solid #ccc" borderRadius={BORDER_RADIUS}>
                <Box
                    backgroundColor="status.info"
                    color="white"
                    borderRadius={`${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`}
                    display="flex"
                    padding={1}
                >
                    <Typography flex={1} padding={1}>
                        {`${selectedRows.length} Rows Selected`}
                    </Typography>

                    {buttons.map((button) => (
                        <HeaderButton
                            key={button.label}
                            action={button.action}
                            variant={button.variant}
                        >
                            {button.label}
                        </HeaderButton>
                    ))}
                </Box>
                {children}
            </Box>
        </Box>
    );
}

export default TableContainer;
