import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import GoogleMapReact from 'google-map-react';

import SelectedImage from './SelectedImage';
import CloseModal from '../common/CloseModal';
import mediaTypes, { filterMedia } from '../../constants/mediaTypes';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import mapStyle from '../../constants/mapStyle';
import GalleryMedia from './GalleryMedia';

const ImageGallery = ({ auction, setFullscreenMedia }) => {
    const [media, setMedia] = useState([]);
    const [images, setImages] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [open, setOpen] = useState(false);

    const [mapShow, setMapShow] = useState(false);

    useEffect(() => {
        let items = auction.property.propertyMedia.map(function (
            propertyMedia
        ) {
            return propertyMedia.projectMedia.media;
        });

        // Filter to only include images / videos
        setMedia(
            filterMedia(
                items,
                [mediaTypes.IMAGE, mediaTypes.VIDEO],
                ['mediaType']
            )
        );
        // Filter media to include just the images (for fullscreen image viewer)
        setImages(filterMedia(items, [mediaTypes.IMAGE], ['mediaType']));
    }, [auction.property.propertyMedia]);

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : 'auto';
    }, [open]);

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Box
            sx={{
                height: { xs: 'calc(70vh - 70px)', md: 'calc(100vh - 70px)' },
            }}
            className="auction-media auction-media-width"
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {mapShow ? (
                    <Map property={auction.property} setMapShow={setMapShow} />
                ) : (
                    <>
                        <SelectedImage
                            auction={auction}
                            setFullscreenMedia={setFullscreenMedia}
                            images={media}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            setMapShow={setMapShow}
                            handleOpen={handleOpen}
                        />
                        <ScrollContainer className="thumbnail-container">
                            {media.map((media, i) => (
                                <GalleryMedia
                                    key={i}
                                    media={media}
                                    i={i}
                                    thumbnail
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}
                                />
                            ))}
                        </ScrollContainer>
                        {open && (
                            <Lightbox
                                mainSrc={images[selectedIndex].url}
                                nextSrc={
                                    images[(selectedIndex + 1) % images.length]
                                        .url
                                }
                                prevSrc={
                                    images[
                                        (selectedIndex + images.length - 1) %
                                            images.length
                                    ].url
                                }
                                onCloseRequest={() => setOpen(false)}
                                onMovePrevRequest={() =>
                                    setSelectedIndex(
                                        (selectedIndex + images.length - 1) %
                                            images.length
                                    )
                                }
                                onMoveNextRequest={() =>
                                    setSelectedIndex(
                                        (selectedIndex + 1) % images.length
                                    )
                                }
                            />
                        )}
                    </>
                )}
            </div>
        </Box>
    );
};

const Map = ({ property, setMapShow }) => {
    const point = {
        lat: property.address.lat,
        lng: property.address.lng,
    };

    function handleLoaded(map, maps, point) {
        // Display pin on map + street view
        new maps.Marker({
            position: point,
            map,
            //icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=cafe|FFFF00',
        });

        // TODO: Rotate the street view panoramoa towards the marker
    }

    return (
        <div
            style={{
                height: '100%',
                position: 'relative',
            }}
            className="auction-media-width"
        >
            <Box
                position="absolute"
                right={10}
                top={10}
                height={40}
                width={40}
                display="flex"
                backgroundColor="white"
                zIndex={1}
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                borderRadius={10}
            >
                <CloseModal
                    onClose={() => setMapShow(false)}
                    top={0}
                    right={0}
                />
            </Box>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                    libraries: ['places'],
                }}
                center={point}
                defaultZoom={17}
                options={{
                    gestureHandling: 'greedy',
                    fullscreenControl: false,
                    streetViewControl: true,
                    styles: mapStyle,
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) =>
                    handleLoaded(map, maps, point)
                }
            />
        </div>
    );
};

export default ImageGallery;
