import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ClientAccountCard from './ClientAccountCard';
import AddBankButton from './AddBankButton';
import axios from 'axios';
import Loading from '../common/Loading';

function ClientAccountManager({ businessId, developerId }) {
    const [clientAccounts, setClientAccounts] = useState();

    useEffect(() => {
        axios
            .post(`/zum/getClientAccounts`, {
                businessId,
                developerId,
            })
            .then((res) => {
                setClientAccounts(res.data);
            })
            .catch(function () {});
    }, []);

    // Loading
    if (!clientAccounts) {
        return <Loading height={100} />;
    }

    return (
        <Box>
            {clientAccounts.map((clientAccount, i) => (
                <Box key={i}>
                    <ClientAccountCard clientAccount={clientAccount} />
                </Box>
            ))}
            <AddBankButton />
        </Box>
    );
}

export default ClientAccountManager;
