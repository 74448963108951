import { Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef } from 'react';
import { useWatch } from 'react-hook-form';

const SelectionTable = ({
    error,
    rows,
    columns,
    column,
    columnName,
    control,
    setValue,
    id,
    multi,
    nullable,
    disabled,
}) => {
    const model = useWatch({ control, name: id });

    const tableRef = useRef(null);

    useEffect(() => {
        if (error) {
            tableRef?.current?.scrollIntoView();
        }
    }, [error]);

    if (disabled && (model > 0 || model?.length > 0)) {
        if (multi) {
            rows = rows.filter((row) => model?.includes(row.id));
        } else {
            rows = rows.filter((row) => row.id === model);
        }
    }

    const MAX = 5;
    const AUTO_HEIGHT = rows?.length <= MAX;

    return (
        <div ref={tableRef}>
            <Box height={AUTO_HEIGHT ? undefined : 370} width="100%" flex={1}>
                <DataGrid
                    autoHeight={AUTO_HEIGHT}
                    height={200}
                    rows={rows}
                    columns={
                        columns || [
                            {
                                field: column,
                                headerName: columnName,
                                flex: 1,
                                headerAlign: 'center',
                                align: 'center',
                            },
                        ]
                    }
                    pageSize={MAX}
                    rowsPerPageOptions={[MAX]}
                    checkboxSelection={multi}
                    selectionModel={model || []}
                    onSelectionModelChange={(ids) => {
                        if (!disabled) {
                            if (multi) {
                                setValue(id, ids);
                            } else {
                                setValue(id, ids[0]);
                            }
                        }
                    }}
                    keepNonExistentRowsSelected
                    disableSelectionOnClick={disabled}
                    sx={{ cursor: disabled ? 'not-allowed' : undefined }}
                />
            </Box>
            {nullable && !disabled && (
                <Button
                    size="tiny"
                    fullWidth
                    variant="dark"
                    sx={{ marginTop: 2 }}
                    onClick={() => setValue(id, null)}
                >
                    Clear
                </Button>
            )}
        </div>
    );
};

export default SelectionTable;
