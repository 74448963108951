import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailVerificationClaim } from 'supertokens-auth-react/recipe/emailverification';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import BetaHome from '../../pages/BetaHome';
import Loading from '../common/Loading';
import { SecondFactorClaim } from '../supertokens/SecondFactor';

function RequireLogin({ optional = false, children }) {
    const session = useSessionContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!session.loading) {
            // Re-route to necessary claim solution
            if (
                session.invalidClaims.find(
                    (a) => a.validatorId === SecondFactorClaim.id
                )
            ) {
                navigate('/second-factor');
            } else if (
                session.invalidClaims.find(
                    (a) => a.validatorId === EmailVerificationClaim.id
                )
            ) {
                navigate('/auth/verify-email');
            }
        }
    }, [session, navigate]);

    // Load nothing if the user isn't fully logged in
    if (session.loading || session.invalidClaims?.length > 0) {
        return <Loading />;
    }

    // Display the beta home page if not signed in
    return session.doesSessionExist || optional ? children : <BetaHome />;
}

export default RequireLogin;
