import React, { useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Drawer,
    Grid,
    Link,
    Typography,
} from '@mui/material';
import imgHero from '../assets/home_hero.jpg';

import upsideLogo from '../assets/UpsideLogo_White.svg';
import { track } from '../scripts/segment';
import { redirectToAuth } from 'supertokens-auth-react';
import MenuIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';

function BetaHome() {
    const [hamburger, setHamburger] = useState(false);

    // Redirect user to home (cleans up the url if it's displaying beta page on a url they can't visit)
    if (window.location.pathname !== '/') {
        window.location.href = '/';
    }

    return (
        <Box
            height="100vh"
            width={1}
            backgroundColor="#333"
            display="flex"
            flexDirection="column"
        >
            <Box
                position="absolute"
                width={1}
                height="100vh"
                backgroundColor="dark"
                sx={{ opacity: 0.6, zIndex: 1 }}
            />

            <Box
                display="flex"
                sx={{
                    zIndex: 2,
                }}
                justifyContent="space-between"
                padding="5vh"
            >
                <Link href={process.env.REACT_APP_CLIENT}>
                    <Box
                        component="img"
                        sx={{
                            width: 200,
                        }}
                        src={upsideLogo}
                    />
                </Link>
                {/* <Box>
                    <Button
                        variant="white"
                        sx={{
                            borderRadius: '100px',
                            padding: '15px 0',
                            width: 0,
                        }}
                        onClick={() => setHamburger(true)}
                    >
                        <MenuIcon fontSize="large" />
                    </Button>
                </Box> */}
            </Box>
            <Box
                sx={{
                    zIndex: 2,
                }}
                margin="auto"
            >
                <Grid
                    container
                    paddingLeft={{ xs: 2, md: 10 }}
                    paddingRight={{ xs: 2, md: 10 }}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        paddingRight={{ md: 10 }}
                        width={{ md: 700 }}
                    >
                        <Box
                            marginLeft="auto"
                            display="flex"
                            flexDirection="column"
                        >
                            <Typography
                                color="white"
                                sx={{
                                    fontSize: 55,
                                }}
                                variant="heading"
                                lineHeight={1.2}
                                textAlign="center"
                            >
                                Welcome to Upside!
                            </Typography>
                            <Typography
                                color="white"
                                sx={{
                                    fontSize: 18,
                                }}
                                margin={5}
                                textAlign="center"
                            >
                                Join the waitlist, or sign up using the link
                                sent to you through your email.
                            </Typography>
                            <LinkButton
                                href="https://upsidere.typeform.com/beta-waitlist"
                                text="Join The Waitlist"
                                onClick={() => {
                                    track('Waitlist Registration Started');
                                }}
                                variant="blue"
                                type={null}
                                target="_blank"
                            />

                            <Divider
                                sx={{
                                    borderColor: '#ccc !important',
                                    marginTop: 3,
                                    marginBottom: 5,
                                    opacity: 1,
                                    marginLeft: 3,
                                    marginRight: 3,
                                    display: { md: 'none' },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        borderLeft={{ md: '1px solid #ccc' }}
                        paddingLeft={{ md: 10 }}
                    >
                        <Box marginTop="auto" marginBottom="auto" width={1}>
                            <LinkButton
                                text="Log In"
                                variant="white"
                                handleClick={() =>
                                    redirectToAuth({
                                        show: 'signin',
                                        redirectBack: false,
                                    })
                                }
                            />
                            <LinkButton
                                text="Sign Up"
                                variant="dark-transparent"
                                handleClick={() =>
                                    redirectToAuth({
                                        show: 'signup',
                                        redirectBack: false,
                                    })
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <img
                className="home-header-image"
                src={imgHero}
                alt=""
                style={{ filter: 'blur(2px)' }}
            />

            {/* <Drawer
                anchor="right"
                open={hamburger}
                onClose={() => setHamburger(false)}
            >
                <Box
                    backgroundColor="dark"
                    height={1}
                    display="flex"
                    flexDirection="column"
                    padding={{ md: 8 }}
                    paddingTop={8}
                >
                    <Box position="absolute" top={0} right={0} padding="5vh">
                        <Button
                            variant="white"
                            sx={{
                                borderRadius: '100px',
                                padding: '15px 0',
                                width: 0,
                            }}
                            onClick={() => setHamburger(false)}
                        >
                            <CloseIcon fontSize="large" />
                        </Button>
                    </Box>
                    <Box marginTop={10}>
                        {[
                            {
                                label: 'Learn More',
                                href: 'https://upside.typedream.app',
                                target: '_blank',
                            },
                            { label: 'Contact Us', href: '/contact-us' },
                            //{
                            //    label: 'Blog',
                            //    href: `${process.env.REACT_APP_BLOG}`,
                            //    target: '_blank',
                            //},
                        ].map((item) => (
                            <Box key={item.label} padding={6}>
                                <Typography
                                    fontSize={32}
                                    color="white"
                                    letterSpacing={1}
                                    variant="heading"
                                    fontWeight="light"
                                >
                                    <Link
                                        href={item.href}
                                        underline="hover"
                                        sx={{ color: 'white !important' }}
                                        target={item.target}
                                    >
                                        {item.label}
                                    </Link>
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Drawer> */}
        </Box>
    );
}

const LinkButton = ({
    href,
    text,
    variant = 'white',
    type = 'border',
    handleClick,
}) => {
    return (
        <Box display="flex" width={1}>
            <Button
                component={Link}
                href={href}
                variant={variant}
                type={type}
                width={{ xs: '100%', md: 300 }}
                sx={{
                    marginBottom: 2,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                onClick={handleClick}
            >
                <Typography fontWeight="bold" fontFamily="urbanist">
                    {text}
                </Typography>
            </Button>
        </Box>
    );
};

export default BetaHome;
