import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import CollapseContainer from '../common/CollapseContainer';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/CheckCircle';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import AuctionIcon from '@mui/icons-material/Gavel';
import { Tooltip, Button, IconButton } from '@mui/material';
import { getRow } from '../../scripts/table';
import PopupForm from '../common/PopupForm';

function FlaggedComments() {
    const [data, setData] = useState([]);
    const [selectedComment, setSelectedComment] = useState();

    const [row, setRow] = useState();
    const [showApproveDialog, setShowApproveDialog] = useState(false);
    const [showRejectDialog, setShowRejectDialog] = useState(false);

    useEffect(() => {
        axios
            .post(`/comment/getFlags`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const columnsComment = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'commenter',
            headerName: 'Commenter',
            width: 130,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'comment',
            headerName: 'Comment',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="tooltip-admin">
                        <div>{params.value}</div>
                        <span className="tooltiptext-admin">
                            {params.value}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'flags',
            headerName: 'Flags',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    const row = getRow(e, params);
                    const curr = data.filter((a) => a.id === row.id)[0];
                    setSelectedComment(curr);
                };

                return <Button onClick={onClick}>({params.value})</Button>;
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'auction',
            headerName: 'Listing',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    const row = getRow(e, params);
                    // Open auction page of comment
                    window.location.href = `/listing/${
                        data.filter((a) => a.id === row.id)[0].auction.slug
                    }`;
                };

                return (
                    <Tooltip title="View listing">
                        <IconButton onClick={onClick}>
                            <AuctionIcon style={{ color: '#166cdb' }} />
                        </IconButton>
                    </Tooltip>
                );
            },
            sortable: false,
        },
        {
            field: 'approve',
            headerName: 'Approve',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setRow(getRow(e, params));
                    setShowApproveDialog(true);
                };

                return (
                    <Tooltip title="Approve comment">
                        <IconButton onClick={onClick}>
                            <CheckIcon style={{ color: '#22aa22' }} />
                        </IconButton>
                    </Tooltip>
                );
            },
            sortable: false,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setRow(getRow(e, params));
                    setShowRejectDialog(true);
                };

                return (
                    <Tooltip title="Reject comment">
                        <IconButton onClick={onClick}>
                            <DeleteIcon style={{ color: '#ee3333' }} />
                        </IconButton>
                    </Tooltip>
                );
            },
            sortable: false,
        },
    ];

    const columnsFlags = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'reporter',
            headerName: 'Reporter',
            width: 130,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'reason',
            headerName: 'Reason',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="tooltip-admin">
                        <div>{params.value}</div>
                        <span className="tooltiptext-admin">
                            {params.value}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
    ];

    const approveComment = (row) => {
        axios
            .post(`/comment/approve`, { id: row.id })
            .then((res) => {
                setData(data.filter((a) => a.id !== row.id));
                setShowApproveDialog(false);
            })
            .catch(function () {});
    };

    const rejectComment = (row) => {
        axios
            .post(`/comment/deleteAdmin`, { id: row.id })
            .then((res) => {
                setData(data.filter((a) => a.id !== row.id));
                setShowRejectDialog(false);
            })
            .catch(function () {});
    };

    return (
        <div>
            {!selectedComment ? (
                <CollapseContainer title="Flagged Comments">
                    <div style={{ height: 645, width: '100%' }}>
                        <DataGrid
                            rows={data.map((item) => {
                                return {
                                    id: item.id,
                                    commenter: item.user.username,
                                    comment: item.text,
                                    flags: item.comment_flags.length,
                                    createdAt: moment(item.createdAt).format(
                                        `MMM D[,] h:mm A`
                                    ),
                                };
                            })}
                            columns={columnsComment}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            /*checkboxSelection*/
                            disableSelectionOnClick
                        />
                    </div>
                </CollapseContainer>
            ) : (
                <CollapseContainer title="Flags">
                    <Button
                        onClick={() => {
                            setSelectedComment(null);
                        }}
                        className="tall-button"
                    >
                        <BackIcon style={{ color: '#333' }} />
                    </Button>
                    <div style={{ height: 645, width: '100%' }}>
                        <DataGrid
                            rows={selectedComment.comment_flags.map((item) => {
                                return {
                                    id: item.id,
                                    reporter: item.user.username,
                                    reason: item.reason,
                                    createdAt: moment(item.createdAt).format(
                                        `MMM D[,] h:mm A`
                                    ),
                                };
                            })}
                            columns={columnsFlags}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            /*checkboxSelection*/
                            disableSelectionOnClick
                        />
                    </div>
                </CollapseContainer>
            )}

            <PopupForm
                title="Approve comment?"
                body={
                    <div>
                        Are you sure you want to approve this comment and
                        resolve all flags?
                    </div>
                }
                action={() => approveComment(row)}
                show={showApproveDialog}
                setShow={setShowApproveDialog}
            />

            <PopupForm
                title="Delete comment?"
                body={
                    <div>
                        Are you sure you want to delete this comment and resolve
                        all flags?
                    </div>
                }
                action={() => rejectComment(row)}
                show={showRejectDialog}
                setShow={setShowRejectDialog}
            />
        </div>
    );
}

export default FlaggedComments;
