import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import SystemIcon from '@mui/icons-material/AdminPanelSettings';
import PinIcon from '@mui/icons-material/PushPin';
import { AccountContext } from '../supertokens/Account';
import PopupForm from '../common/PopupForm';
import axios from 'axios';
import HTMLParse from '../common/HTMLParse';

function AuctionSystemMessage({ item }) {
    const [showDelete, setShowDelete] = useState(false);
    const account = useContext(AccountContext);

    const handleDelete = () => {
        axios
            .post(`/auction/systemMessage/delete/${item.id}`)
            .catch(function () {});
    };

    return (
        <Box
            sx={{
                display: 'flex',
                marginTop: 2.5,
            }}
            boxShadow="0px 2px 2px 1px #00000018"
        >
            <Box backgroundColor={`status.${item.status.value}`} width={20} />
            <Box
                style={{
                    borderStyle: 'solid',
                    borderWidth: '1px 1px 1px 0px',
                    borderColor: '#ccc',
                    padding: '20px',
                    flex: 1,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Box
                        color={`status.${item.status.value}`}
                        style={{
                            marginRight: 15,
                            fontSize: 32,
                            position: 'relative',
                            width: 30,
                        }}
                    >
                        <SystemIcon
                            fontSize="inherit"
                            style={{
                                position: 'absolute',
                                top: 2,
                            }}
                        />
                    </Box>
                    <Typography
                        color="dark"
                        fontSize={20}
                        fontWeight="bold"
                        letterSpacing={1}
                        component="span"
                        marginTop="5px"
                        flex={1}
                    >
                        System
                    </Typography>
                    <Box
                        color={`status.${item.status.value}`}
                        marginLeft="auto"
                        flexDirection="row"
                        display="flex"
                        fontSize={20}
                    >
                        {item.pinned && <PinIcon fontSize="inherit" />}
                        <Typography
                            fontSize={14}
                            color="text.secondary"
                            marginLeft={1}
                        >
                            {moment(item.createdAt).format(`MMM D [at] h:mm A`)}
                        </Typography>
                    </Box>
                </div>
                <div
                    style={{
                        marginTop: 20,
                        whiteSpace: 'pre-line',
                        display: 'flex',
                    }}
                >
                    <Typography
                        fontSize={17}
                        color="dark"
                        fontWeight="light"
                        component="div"
                        className="parser"
                    >
                        <HTMLParse value={item.value} />
                    </Typography>
                </div>
                <div style={{ display: 'flex' }}>
                    {account.isAdmin() && (
                        <Typography
                            fontSize={14}
                            color="text.secondary"
                            style={{
                                marginTop: 10,
                                cursor: 'pointer',
                            }}
                            className="underline"
                            onClick={() => {
                                setShowDelete(true);
                            }}
                        >
                            Delete
                        </Typography>
                    )}
                </div>
            </Box>

            <PopupForm
                title="Delete System Message?"
                body={
                    <Box display="flex" flexDirection="column">
                        <div>
                            Are you sure you want to delete this system message?
                        </div>
                    </Box>
                }
                action={handleDelete}
                show={showDelete}
                setShow={setShowDelete}
                yes="Delete"
            />
        </Box>
    );
}

export default AuctionSystemMessage;
