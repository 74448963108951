import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import upsideLogo from '../../assets/UpsideLogo_White.svg';

import {
    AppBar,
    Badge,
    Box,
    Button,
    IconButton,
    Link,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from '@mui/material';
import Avatar from '../user/Avatar';

import LogoutIcon from '@mui/icons-material/Logout';
import AdminIcon from '@mui/icons-material/SupervisorAccount';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UnverifiedIcon from '@mui/icons-material/ErrorOutline';
import { socket } from '../../scripts/socket';
import { toast } from 'react-toastify';
import Notification from '../common/Notification';

import muiTheme from '../../muiTheme';
import { AccountContext } from '../supertokens/Account';
import PaddedBox from './PaddedBox';
import ScrollContainer from 'react-indiana-drag-scroll';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';
import DynamicIcon from './DynamicIcon';
import IDVModal from '../modal/IDVModal';

function Header() {
    const MAX = 25;
    const isHome = window.location.pathname === '/';

    // Determine bar colour
    const account = useContext(AccountContext);
    let fillVariant = 'dark';
    // Change app bar colour if admin
    if (account.user?.role === 'Admin') {
        // Handle based on env
        switch (process.env.REACT_APP_ENV) {
            case 'production':
                fillVariant = 'admin';
                break;
            case 'development':
                fillVariant = 'development';
                break;
            default:
                break;
        }
    }

    const [variant, setVariant] = useState(
        isHome && window.scrollY <= MAX ? 'transparent' : fillVariant
    );

    useEffect(() => {
        if (isHome) {
            document.addEventListener('scroll', handleScroll);
        }
    }, [isHome]);

    const handleScroll = () => {
        const scrolled = window.scrollY > MAX;
        if (scrolled) {
            setVariant(fillVariant);
        } else if (!scrolled) {
            setVariant('transparent');
        }
    };

    return (
        <Box sx={{ flexGrow: 1, marginBottom: '70px' }}>
            <Bar variant={variant} />
        </Box>
    );
}

const Bar = ({ variant }) => {
    const account = useContext(AccountContext);

    const pages = account.signedIn
        ? [
              { title: 'My Opportunities', path: '/marketplace' },
              /*{ title: 'Categories', path: '/category' },*/
              /*{ title: 'Cities', path: '/city' },*/
              /*{ title: 'Submit Property', path: '/submit' },*/
              /*{
                  title: 'Blog',
                  path: `${process.env.REACT_APP_BLOG}`,
                  target: '_blank',
                  linkIndicator: true,
              },*/
          ]
        : [];

    // Developer dashboard
    if (account.user.developerId) {
        // TODO: Developer dashboard access
        // pages.push({
        //     title: 'Developers',
        //     path: '/developer',
        // });
    }

    return (
        <AppBar variant={variant} elevation={0} position="fixed">
            <PaddedBox>
                <Toolbar style={{ height: '70px', padding: 0 }}>
                    <Link href="/">
                        <Box
                            component="img"
                            sx={{
                                width: 150,
                                paddingLeft: 1,
                            }}
                            src={upsideLogo}
                        />
                    </Link>
                    <Typography marginTop={2} marginLeft={1} fontSize={14}>
                        Beta
                    </Typography>

                    <ScrollContainer
                        style={{
                            marginLeft: 30,
                            overflowX: 'scroll',
                            whiteSpace: 'nowrap',
                            scrollBehavior: 'smooth',
                            position: 'relative',
                            flexGrow: 1,
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.title}
                                component={Link}
                                href={page.path}
                                variant="white-transparent"
                                sx={{
                                    textTransform: 'none',
                                    marginLeft: 5,
                                    display: { xs: 'none', md: 'inline-flex' },
                                }}
                                size="small"
                                target={page.target}
                            >
                                <Typography
                                    fontSize={14}
                                    letterSpacing={1}
                                    fontFamily="urbanist"
                                >
                                    {page.title}
                                </Typography>
                                {page.linkIndicator && (
                                    <DynamicIcon
                                        icon="OpenInNew"
                                        sx={{ marginLeft: 1 }}
                                        size={18}
                                    />
                                )}
                            </Button>
                        ))}
                    </ScrollContainer>

                    <Unverified account={account} />
                    <Notifications account={account} />
                    <Account account={account} />
                </Toolbar>
            </PaddedBox>
        </AppBar>
    );
};

const Account = ({ account }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    async function handleSignout() {
        await signOut();
        window.location.href = process.env.REACT_APP_CLIENT;
    }

    if (account.signedIn) {
        return (
            <>
                <IconButton
                    onClick={handleClick}
                    sx={{ height: 60, width: 60 }}
                >
                    <Avatar username={account.user.username} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    disableScrollLock={true}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    type="dropdown"
                    border="split"
                    PaperProps={{
                        style: {
                            width: 220,
                        },
                    }}
                >
                    <DropdownItem
                        title="My Account"
                        path={`/user/${account.user.username}/account`}
                    />
                    {/* {account.user.developerId && (
                        <DropdownItem
                            title="Listings"
                            path={`/user/${account.user.username}/listings`}
                        />
                    )} */}
                    {/* <DropdownItem
                        title="Following"
                        path={`/user/${account.user.username}/following`}
                    /> */}
                    <DropdownItem
                        title="My Purchases"
                        path={`/user/${account.user.username}/purchases`}
                    />
                    {account.isAdmin() && (
                        <DropdownItem
                            title="Administration"
                            Icon={AdminIcon}
                            path="/administration"
                        />
                    )}
                    <DropdownItem
                        title="Log out"
                        Icon={LogoutIcon}
                        handleClick={handleSignout}
                    />
                </Menu>
            </>
        );
    } else {
        return <></>;
    }
};

const DropdownItem = ({ Icon, title, path, handleClick }) => {
    return (
        <MenuItem
            style={{ padding: 20 }}
            display="flex"
            component={Link}
            href={path}
            className="link"
            onClick={handleClick}
        >
            {Icon && (
                <ListItemIcon sx={{ color: 'darker', fontSize: 20 }}>
                    <Icon fontSize="inherit" />
                </ListItemIcon>
            )}
            <Typography
                fontWeight="medium"
                letterSpacing={0.5}
                color="dark"
                fontFamily="urbanist"
            >
                {title}
            </Typography>
        </MenuItem>
    );
};

const Unverified = ({ account }) => {
    const [showIDVModal, setShowIDVModal] = useState(false);

    // Display nothing if already verified
    if (!account.signedIn || account.user.verified) {
        return null;
    }

    return (
        <>
            <IconButton
                onClick={() => setShowIDVModal(true)}
                sx={{
                    height: 60,
                    width: 60,
                    color: '#ff0d0d',
                    fontSize: 35,
                    marginRight: -1,
                }}
            >
                <UnverifiedIcon fontSize="inherit" />
            </IconButton>
            <IDVModal
                show={showIDVModal}
                onClose={() => setShowIDVModal(false)}
            />
        </>
    );
};

const Notifications = ({ account }) => {
    const [notifications, setNotifications] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        // Get the signed in user's payment methods
        if (account.signedIn) {
            axios
                .get(`/notification/getRecent`)
                .then((res) => {
                    setNotifications(res.data);
                })
                .catch(function () {});
        }
    }, [account.signedIn]);

    useEffect(() => {
        const route = 'notification';
        socket.off(route).on(route, (data) => {
            pushNotification(data, true);
        });

        const pushNotification = (data, popup) => {
            // Display popup notification if requested
            if (popup) {
                toast(
                    <Notification
                        item={data}
                        discrete
                        setNotifications={setNotifications}
                    />,
                    {
                        style: {
                            backgroundColor:
                                muiTheme.palette.background[data.status.value],
                            padding: 0,
                        },
                    }
                );
            }

            // Push notification to start of array
            setNotifications((notifications) => {
                const _notifications = [...notifications];
                _notifications.unshift(data);
                return _notifications;
            });
        };
    }, [notifications]);

    if (!account.signedIn) {
        return null;
    }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);

        /*if (countUnread() > 0) {
            markAllAsRead();
        }*/
    };

    /*const markAllAsRead = () => {
        axios.post(`/notification/readAll`);

        const _notifications = [...notifications];
        for (let i = 0; i < _notifications.length; i++) {
            _notifications[i].read = true;
        }
        setNotifications(_notifications);
    };*/

    const countUnread = () => {
        let unread = 0;
        for (let i = 0; i < notifications.length; i++) {
            if (notifications[i].read === false) {
                unread++;
            }
        }
        return unread;
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                style={{ height: 60, width: 60, color: 'white', fontSize: 35 }}
            >
                <Badge
                    badgeContent={countUnread()}
                    overlap="circular"
                    type="notifications"
                >
                    <NotificationsIcon fontSize="inherit" />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                type="dropdown"
                PaperProps={{
                    style: {
                        width: 350,
                        borderRadius: 20,
                        marginTop: 5,
                    },
                }}
                disablePortal
            >
                <Box display="flex" color="dark">
                    <Typography
                        fontWeight="bold"
                        letterSpacing={0.25}
                        fontSize={20}
                        color="dark"
                        padding={2}
                        paddingBottom={1}
                        marginLeft={1}
                        fontFamily="urbanist"
                    >
                        Notifications
                    </Typography>
                </Box>
                <Box maxHeight={400} overflow="auto">
                    {notifications.map((item) => (
                        <Notification
                            key={item.id}
                            item={item}
                            setNotifications={setNotifications}
                        />
                    ))}
                </Box>
                {notifications?.length === 0 ? (
                    <Typography
                        width="100%"
                        textAlign="center"
                        color="text.secondary"
                        paddingTop={4}
                        paddingBottom={4}
                    >
                        You have no notifications
                    </Typography>
                ) : (
                    <Link
                        href={`/user/${account.user.username}/notifications`}
                        className="link"
                    >
                        <Typography
                            width="100%"
                            textAlign="center"
                            color="dark"
                            padding={1.5}
                            backgroundColor="#fefefe"
                            fontWeight="bold"
                            className="underline"
                            fontFamily="urbanist"
                        >
                            View All
                        </Typography>
                    </Link>
                )}
            </Menu>
        </>
    );
};

export default Header;
