import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import NotFound from '../../pages/NotFound.js';
import CMSForm from './CMSForm.js';
import { getDevelopers } from '../../scripts/cms.js';

function UpgradeTypeForm() {
    const { id } = useParams();
    const [valid, setValid] = useState(true);

    const [data, setData] = useState();

    useEffect(() => {
        if (id) {
            axios
                .get(`/upgrade/getType/${id}`)
                .then((res) => {
                    setData(res.data);
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [id]);

    const inputs = [
        [{ id: 'title' }],
        [{ id: 'deleted', checkbox: true }],
        [
            {
                id: 'upgradeSubtypes',
                inputs: [
                    [{ id: 'title' }],
                    [
                        {
                            id: 'developerId',
                            label: 'Developer',
                            source: getDevelopers,
                            sourceAttribute: 'user.username',
                            placeholder: '(All)',
                            optional: true,
                        },
                        { id: 'deleted', checkbox: true },
                    ],
                ],
                title: 'Upgrade Subtype',
            },
        ],
    ];

    if (valid) {
        return (
            <CMSForm
                title="Upgrade Type"
                api="upgrade"
                apiSuffix="Type"
                adminSlug="upgradeTypes"
                data={data}
                attribute="id"
                inputs={inputs}
            />
        );
    } else {
        return <NotFound />;
    }
}

export default UpgradeTypeForm;
