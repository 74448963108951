import React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import mediaTypes, { compareMediaType } from '../../constants/mediaTypes';
import PDFIcon from '../../assets/icon-pdf.svg';

function DraggableMedia({ item, handleRemove }) {
    const mediaParent = item?.projectMedia || item;

    const remove = (e) => {
        // Custom remove (single media picker)
        if (handleRemove) {
            handleRemove(e);
        }
        // Remove item from list
        else {
            e.target.parentNode.remove();
        }
    };

    const rename = (e) => {
        const el = document.getElementsByClassName('media-image');

        for (let i = 0; i < el.length; i++) {
            const curr = JSON.parse(el[i].getAttribute('item'));
            if (curr.media.url === mediaParent.media.url) {
                curr.media.title = e.target.value;
                el[i].setAttribute('item', JSON.stringify(curr));
            }
        }
    };

    let url = mediaParent?.media?.url;

    // Type: Video
    const mediaTypeId = mediaParent?.media?.mediaTypeId;
    if (mediaTypeId === mediaTypes.VIDEO) {
        url = `http://img.youtube.com/vi/${url}/0.jpg`;
    }
    // Type: Floorplan / Siteplan / Document
    else if (compareMediaType(mediaTypeId, mediaTypes.FILES)) {
        url = PDFIcon;
    }

    // Image
    if (url) {
        return (
            <ImageListItem
                style={{ overflow: 'hidden', lineHeight: 0 }}
                id={mediaParent.media.url}
                className="draggable-media"
            >
                <img
                    className="media-image"
                    item={JSON.stringify(mediaParent)}
                    src={url}
                    style={{
                        objectFit: 'cover',
                        height: '118px',
                    }}
                    alt=""
                />
                {mediaParent.media.mediaType?.title && (
                    <div className="media-label media-label-type">
                        <p style={{ marginTop: '8px', marginLeft: '4px' }}>
                            {mediaParent.media.mediaType.title}
                        </p>
                    </div>
                )}
                <input
                    type="text"
                    className={`media-label media-label-title`}
                    style={{ flex: 1 }}
                    onChange={rename}
                    defaultValue={mediaParent.media.title}
                />
                <input
                    type="button"
                    className="btn-danger top-right-button button-small"
                    value="X"
                    onClick={remove}
                />
            </ImageListItem>
        );
    } else {
        return (
            <div
                style={{
                    textAlign: 'center',
                    padding: 40,
                    maxHeight: 118,
                    backgroundColor: '#eee',
                    borderRadius: '5%',
                }}
            >
                No Image
            </div>
        );
    }
}

export default DraggableMedia;
