import { Typography, Link, Box, Button } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import { AccountContext } from '../supertokens/Account';

import upsideLogo from '../../assets/UpsideLogo_Black.svg';
import SocialButtons from './SocialButtons';

// Payment methods
import Amex from '../../assets/payment/card-amex.svg';
import MasterCard from '../../assets/payment/card-mastercard-alt.svg';
import Visa from '../../assets/payment/card-visa-alt.svg';
import Discover from '../../assets/payment/card-discover.png';
import JCB from '../../assets/payment/card-jcb.svg';
import UnionPay from '../../assets/payment/card-union.svg';
import Diners from '../../assets/payment/card-diners.svg';
import MailingListModal from '../modal/MailingListModal';

function Footer() {
    const account = useContext(AccountContext);

    if (!account.signedIn) {
        return <BetaFooter />;
    }

    return <MainFooter account={account} />;
}

const Section = ({ title, children }) => {
    return (
        <Box paddingRight={{ md: /*5*/ 12, lg: 12 }}>
            <Typography
                padding={1}
                textAlign="left"
                fontWeight="bold"
                fontSize={18}
                fontFamily="urbanist"
            >
                {title}
            </Typography>
            {children}
        </Box>
    );
};

const Item = ({ title, path }) => {
    return (
        <Typography
            padding={1}
            textAlign="left"
            fontSize={15}
            color="dark"
            letterSpacing={1}
            fontFamily="urbanist"
        >
            <Link href={path} underline="hover" color="dark">
                {title}
            </Link>
        </Typography>
    );
};

const BannerItem = ({ title, path }) => {
    return (
        <Typography
            color="dark"
            letterSpacing={1}
            marginRight={3}
            component={path && Link}
            href={path}
            underline="hover"
        >
            {title}
        </Typography>
    );
};

const Logo = () => {
    return (
        <Link href="/">
            <Box
                component="img"
                sx={{
                    width: 200,
                    marginBottom: 3,
                }}
                src={upsideLogo}
            />
        </Link>
    );
};

const MainFooter = ({ account }) => {
    return (
        <footer>
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: 'white',
                    boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
                }}
                padding={{ xs: 5, md: 5, lg: 10 }}
            >
                <LeftPanel />
                <RightPanel account={account} />
            </Box>
        </footer>
    );
};

const LeftPanel = () => {
    const [showMailingModal, setShowMailingModal] = useState(false);

    return (
        <Box
            sx={{
                padding: { md: '20px' },
            }}
            margin="auto"
        >
            <Box maxWidth={320}>
                <Logo />
                <ul style={{ padding: 0 }}>
                    <SocialButtons />
                </ul>
                {/* <div>
                    <Typography
                        marginTop={5}
                        textAlign="left"
                        fontWeight="bold"
                        fontSize={20}
                        fontFamily="urbanist"
                        marginBottom={1}
                    >
                        Stay In The Loop
                    </Typography>
                    <Typography
                        textAlign="left"
                        fontSize={16}
                        marginBottom={2}
                        fontFamily="urbanist"
                    >
                        Join our mailing list to be the first to receive the
                        latest news, offerings & events.
                    </Typography>
                </div>
                <Button
                    variant="dark"
                    onClick={() => setShowMailingModal(true)}
                    fullWidth
                >
                    Join
                </Button>
                <MailingListModal
                    show={showMailingModal}
                    onClose={() => setShowMailingModal(false)}
                /> */}
            </Box>
        </Box>
    );
};

const RightPanel = ({ account }) => {
    //const [categories, setCategories] = useState([]);
    //const [cities, setCities] = useState([]);

    useEffect(() => {
        let mounted = true;

        /*axios
            .get(`/propertyType/getAll`)
            .then((res) => {
                if (mounted) {
                    setCategories(res.data);
                }
            })
            .catch(function () {});*/

        /*axios
            .get(`/city/getAll`)
            .then((res) => {
                if (mounted) {
                    setCities(res.data);
                }
            })
            .catch(function () {});*/

        // Unmount
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <Box margin="auto" display={{ xs: 'none', md: 'initial' }}>
            <Box display="flex" flexWrap="wrap">
                {/* {<Section title="Explore Cities">
                <Item title="All Cities" path="/city" />
                {cities.map((city) => (
                    <Item
                        title={city.title}
                        path={`/city/${city.slug}`}
                        key={city.id}
                    />
                ))}
            </Section>} */}

                {/* <Section title="Explore Properties">
                    <Item title="All Property Types" path="/category" />
                    {categories.map((category) => (
                        <Item
                            title={category.plural}
                            path={`/category/${category.slug}`}
                            key={category.id}
                        />
                    ))}
                </Section> */}

                <Section title="My Account">
                    <Item
                        title="Account"
                        path={`/user/${account.user.username}/account`}
                    />
                    {/* <Item
                        title="Listings"
                        path={`/user/${account.user.username}/listings`}
                    /> */}
                    <Item
                        title="Following"
                        path={`/user/${account.user.username}/following`}
                    />
                    <Item
                        title="My Purchases"
                        path={`/user/${account.user.username}/purchases`}
                    />
                    {/* <Item
                        title="Comments"
                        path={`/user/${account.user.username}/comments`}
                    /> */}
                </Section>

                {/* <Section title="Company">
                    <Item title="About" path="/about" />
                    <Item title="Careers" path="/careers" />
                    <Item title="FAQ" path="/faq" />
                </Section> */}
            </Box>

            {/* <PaymentIcons
                icons={[
                    MasterCard,
                    Amex,
                    Discover,
                    Diners,
                    JCB,
                    Visa,
                    UnionPay,
                ]}
            /> */}

            <Box display="flex" flexWrap="wrap" paddingTop={6}>
                <BannerItem title="© 2024 Upside Real Estate Technologies" />
                <BannerItem title="Privacy Policy" path="/privacy-policy" />
                <BannerItem
                    title="Terms & Conditions"
                    path="/terms-conditions"
                />
                <BannerItem title="Contact Us" path="/contact-us" />
                {/* <BannerItem title="About Us" path="/about" /> */}
            </Box>
        </Box>
    );
};

const BetaFooter = () => {
    return null;
};

const PaymentIcons = ({ icons = [] }) => {
    return (
        <Box display="flex" flexWrap="wrap" marginTop={6}>
            {icons.map((icon) => (
                <img
                    key={icon}
                    height={40}
                    src={icon}
                    alt=""
                    style={{
                        objectFit: 'contain',
                        marginRight: 30,
                        marginBottom: 20,
                    }}
                />
            ))}
        </Box>
    );
};

export default Footer;
