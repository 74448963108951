import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import CollapseContainer from '../../components/common/CollapseContainer';

function Builders() {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(`/builder/getAdmin`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    window.location.href = `/administration/builders/create/${params.row.id}`;
                };

                return (
                    <IconButton onClick={onClick}>
                        <EditIcon style={{ color: '#166cdb' }} />
                    </IconButton>
                );
            },
            sortable: false,
        },
    ];

    return (
        <div className="admin-content">
            <CollapseContainer title={`Builder List (${data?.length || 0})`}>
                <div style={{ height: 410, width: '100%' }}>
                    <DataGrid
                        rows={data.map((item) => {
                            return {
                                id: item.id,
                                title: item.title,
                                createdAt: moment(item.createdAt).format(
                                    `MMM D[,] h:mm A`
                                ),
                            };
                        })}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                    />
                </div>
            </CollapseContainer>
        </div>
    );
}

export default Builders;
