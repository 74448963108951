import React from 'react';
import { Circle, Link as LinkIcon } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import moment from 'moment';
import axios from 'axios';

function Notification({ item, discrete, setNotifications }) {
    const handleClick = () => {
        markAsRead();
    };

    const handleMouseLeave = () => {
        // Update notification to be marked as read
        if (!item.read && !discrete && setNotifications) {
            markAsRead();
        }
    };

    const markAsRead = () => {
        axios.post(`/notification/read/${item.id}`);

        setNotifications((items) =>
            items.map((obj) => {
                if (obj.id === item.id) {
                    return { ...obj, read: true };
                }

                return obj;
            })
        );
    };

    return (
        <Box
            display="flex"
            backgroundColor={`background.${item.status.value}`}
            padding={discrete ? 1.5 : 2}
            position="relative"
            onClick={handleClick}
            onMouseLeave={handleMouseLeave}
            component={Link}
            href={item.path}
            sx={{ textDecoration: 'none' }}
        >
            {!discrete && <div className="menu-item-overlay" />}
            <Box
                fontSize={17}
                color={`status.${item.status.value}`}
                width={36}
                paddingTop={0.15}
            >
                {!item.read && <Circle fontSize="inherit" color="inherit" />}
                {item.path && (
                    <LinkIcon
                        fontSize="inherit"
                        color="inherit"
                        sx={{ display: 'block', marginTop: 0.75 }}
                    />
                )}
            </Box>
            <Box flex={1}>
                <Typography
                    fontWeight={item.read ? 'medium' : 'bold'}
                    fontSize={16}
                    color="dark"
                    paddingBottom={discrete ? 0 : 0.7}
                    paddingTop={0.5}
                >
                    {item.value}
                </Typography>
                {!discrete && (
                    <Typography color="text.secondary" fontSize={14}>
                        {moment(item.createdAt).fromNow()}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default Notification;
