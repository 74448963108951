import { Box, Collapse, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';

function ViewMoreContainer({ children, height = 130, expanded }) {
    const [useCollapse, setUseCollapse] = useState(false);
    const [expand, setExpand] = useState(expanded);
    const ref = useRef(null);

    useEffect(() => {
        setUseCollapse(ref.current.clientHeight >= height);
    }, [children, height]);

    return (
        <div style={{ position: 'relative' }} ref={ref}>
            {useCollapse ? (
                <>
                    <Collapse collapsedSize={height} in={expand}>
                        {children}
                    </Collapse>
                    <ViewMoreButton
                        height={height}
                        expand={expand}
                        setExpand={setExpand}
                        containerRef={ref}
                    />
                </>
            ) : (
                children
            )}
        </div>
    );
}

const ViewMoreButton = ({ height, expand, setExpand, containerRef }) => {
    const padding = 30;

    return (
        <>
            {!expand && (
                <Box
                    padding={`${padding}px`}
                    className="view-more-fade ignore-click"
                />
            )}
            <Box
                sx={{ marginTop: 2 }}
                className="clickable"
                onClick={() => {
                    const pageHeight =
                        document.documentElement.scrollTop ||
                        document.body.scrollTop;
                    const elementHeight = containerRef.current.clientHeight;

                    if (expand && elementHeight > window.innerHeight) {
                        // Scroll the page up to where it should be now that the expansion has adjusted
                        window.scrollTo({
                            top: pageHeight - elementHeight + height + padding,
                            behavior: 'instant',
                        });
                    }
                    setExpand(!expand);
                }}
            >
                <Typography fontSize={14} letterSpacing={1.5} fontWeight="bold">
                    {expand ? '- VIEW LESS' : '+ VIEW MORE'}
                </Typography>
            </Box>
        </>
    );
};

export default ViewMoreContainer;
