import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import CollapseContainer from '../../components/common/CollapseContainer';

import GoogleMapReact from 'google-map-react';
import Marker from '../../components/map/Marker';
import AddressSearchModal from '../../components/map/AddressSearchModal';
import { toast } from 'react-toastify';

function Addresses() {
    const [data, setData] = useState([]);

    const [marker, setMarker] = useState({});
    const [center, setCenter] = useState({ lat: 43.45, lng: -80.49 });

    const [showAddressModal, setShowAddressModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState();

    useEffect(() => {
        axios
            .get(`/address/getAdmin`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 90,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'province',
            headerName: 'Province',
            flex: 0.75,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 0.75,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'postal_code',
            headerName: 'Postal Code',
            width: 110,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 1.25,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'properties',
            headerName: 'Properties',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'locate',
            headerName: 'Locate',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const { lat, lng } = data.filter(
                    (a) => a.id === params.row.id
                )[0];

                return (
                    <IconButton onClick={() => adjustMarker(lat, lng)}>
                        <GpsFixedIcon style={{ color: '#ee3333' }} />
                    </IconButton>
                );
            },
            sortable: false,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    // Set selected address to the address row data
                    /*
                    const address = data.filter(
                        (a) => a.id === params.row.id
                    )[0];
                    address.address = address.streetAddress;
                    address.postal_code = address.postalCode;
                    setSelectedAddress(address);

                    setShowAddressModal(true);
                    */
                    window.location.href = `/administration/addresses/create/${params.row.id}`;
                };

                return (
                    <IconButton onClick={onClick}>
                        <EditIcon style={{ color: '#166cdb' }} />
                    </IconButton>
                );
            },
            sortable: false,
        },
    ];

    const modifyAddress = (location) => {
        // Attach id to modification attempt (can't do sooner since id isn't part of modal)
        location.address_id = selectedAddress.id;

        axios
            .post(`/address/update`, location)
            .then((res) => {
                // Update existing address in data array
                const items = [...data];
                const index = items.findIndex(
                    (item) => item.id === selectedAddress.id
                );
                // Update address row
                items[index].streetAddress = location.address;
                items[index].postalCode = location.postal_code;
                items[index].city = location.city;
                items[index].province = location.province;
                items[index].country = location.country;
                items[index].lat = location.lat;
                items[index].lng = location.lng;

                // Reset modal
                setSelectedAddress(null);
                setShowAddressModal(false);
            })
            .catch(function (err) {
                if (err.response && err.response.data) {
                    toast.error(err.response.data);
                }
            });
    };

    const adjustMarker = (lat, lng) => {
        const geo = { lat: lat, lng: lng };
        setMarker(geo);
        setCenter(geo);
    };

    const isFocus = (lat, lng) => {
        return lat === marker.lat && lng === marker.lng;
    };

    return (
        <div className="admin-content">
            <CollapseContainer title={`Address List (${data?.length || 0})`}>
                <div
                    style={{
                        height: 'calc(100vh - 505px)',
                        backgroundColor: '#ccc',
                        position: 'relative',
                    }}
                >
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                            libraries: ['places'],
                        }}
                        center={center}
                        defaultZoom={10}
                        yesIWantToUseGoogleMapApiInternals={true}
                        /*onGoogleApiLoaded={({ map, maps }) =>
                            handleApiLoaded(map, maps)
                        }*/
                    >
                        {data.map((address) => (
                            <Marker
                                key={address.id}
                                lat={address.lat}
                                lng={address.lng}
                                colour={
                                    isFocus(address.lat, address.lng)
                                        ? '#ee3333'
                                        : null
                                }
                            />
                        ))}
                    </GoogleMapReact>
                </div>
                <div style={{ height: 385, width: '100%' }}>
                    <DataGrid
                        rows={data.map((item) => {
                            return {
                                id: item.id,
                                country: item.country,
                                province: item.province,
                                city: item.city,
                                postal_code: item.postalCode,
                                address: item.streetAddress,
                                properties: item.properties.length,
                                createdAt: moment(item.createdAt).format(
                                    `MMM D[,] h:mm A`
                                ),
                            };
                        })}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        /*checkboxSelection*/
                        disableSelectionOnClick
                    />
                </div>
            </CollapseContainer>

            <AddressSearchModal
                show={showAddressModal}
                onHide={() => setShowAddressModal(false)}
                updatePick={(location) => modifyAddress(location)}
                address={selectedAddress}
                text="Update"
            />
        </div>
    );
}

export default Addresses;
