import Countdown from 'react-countdown';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import config from '../../config';

var moment = require('moment');

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const isEndingSoon = (days, hours, minutes, seconds, _seconds) => {
    seconds = getSeconds(days, hours, minutes, seconds);

    return seconds < _seconds;
};

export const renderTimer = ({
    days,
    hours,
    minutes,
    seconds,
    total,
    completed,
}) => {
    // Convert hours and minutes to seconds
    seconds = getSeconds(days, hours, minutes, seconds);

    let output;
    let colour = null;
    let format = 'H:mm:ss';

    if (days > 0) {
        output = `${days} day${days > 1 ? 's' : ''}`;
    } else if (seconds != null) {
        if (hours === 0) {
            format = 'm:ss';
        }
        // IMPORTANT: .utc is required, 'total' doesn't have a time zone, so it thinks it's utc
        output = moment.utc(total).format(format);
    }

    if (seconds <= config.auction.endingSoon) {
        colour = '#d12317';
    }

    if (completed) {
        output = '0:00';
    }

    return (
        <Typography gutterBottom variant="div" color={colour}>
            {output}
        </Typography>
    );
};

const Text = ({ title, value, endingSoon }) => {
    return (
        <div className="flex">
            <Typography
                fontSize={14}
                marginBottom={1}
                fontFamily="urbanist"
                textTransform="uppercase"
                letterSpacing={1}
            >
                {title}
            </Typography>
            <Typography
                fontSize={22}
                fontWeight="bold"
                color={endingSoon ? 'status.danger' : 'dark'}
                fontFamily="urbanist"
            >
                {value}
            </Typography>
        </div>
    );
};
export const renderSplit = ({ days, hours, minutes, seconds, props }) => {
    const endingSoon = isEndingSoon(
        days,
        hours,
        minutes,
        seconds,
        config.auction.endingSoon
    );

    return (
        <Box
            backgroundColor={endingSoon ? 'background.danger' : undefined}
            paddingTop={2.5}
            marginTop={3.5}
            paddingBottom={2.5}
        >
            <Box
                padding={1}
                paddingBottom={2}
                //boxShadow="0px 3px 3px 1px #00000020"
                //borderRadius={50}
            >
                <div style={{ display: 'flex' }}>
                    <Text title="Days" value={days} endingSoon={endingSoon} />
                    <Text title="Hours" value={hours} endingSoon={endingSoon} />
                    <Text
                        title="Minutes"
                        value={minutes}
                        endingSoon={endingSoon}
                    />
                    <Text
                        title="Seconds"
                        value={seconds}
                        endingSoon={endingSoon}
                    />
                </div>
            </Box>
            <Typography className="flex" fontSize={14} letterSpacing={1}>
                {`Ends ${moment(props.date).format(
                    'MMMM D, YYYY [at] h:mm A'
                )}`}
            </Typography>
        </Box>
    );
};

export const renderLaunching = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    if (completed) {
        return null;
    }

    const Square = ({ title, value }) => {
        return (
            <Box
                paddingLeft={1}
                paddingRight={1}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    borderColor="dark"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 50,
                        height: 45,
                        borderStyle: 'solid',
                        borderWidth: '1px',
                    }}
                >
                    <Typography
                        fontSize={22}
                        fontWeight="bold"
                        letterSpacing={2}
                        paddingTop={0.2}
                        paddingLeft={0.2}
                    >
                        {value}
                    </Typography>
                </Box>
                <Typography fontSize={13} letterSpacing={2} marginTop={1.5}>
                    {title}
                </Typography>
            </Box>
        );
    };

    return (
        <Box
            width="100%"
            backgroundColor="#f8f7f4"
            paddingTop={3.5}
            paddingBottom={3.5}
            marginBottom={5}
        >
            <Typography
                fontSize={14}
                letterSpacing={4.2}
                textAlign="center"
                textTransform="uppercase"
                marginBottom={2}
                fontWeight="light"
            >
                Launching In:
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Square title="DAYS" value={days} />
                <Square title="HOURS" value={hours} />
                <Square title="MINS" value={minutes} />
                <Square title="SECS" value={seconds} />
            </Box>
        </Box>
    );
};

export const renderSplitProject = ({
    days,
    hours,
    minutes,
    seconds,
    props,
}) => {
    const Square = ({ title, value, endingSoon }) => {
        const size = 42;
        return (
            <div
                style={{
                    color: 'white',
                    width: size + 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: size,
                        height: size,
                        borderColor: endingSoon ? '#52AE61' : 'white',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                    }}
                >
                    <Typography fontSize={20} fontWeight="medium">
                        {value}
                    </Typography>
                </div>
                <Typography
                    fontSize={11}
                    letterSpacing={2}
                    marginTop={1}
                    color="text.light"
                >
                    {title}
                </Typography>
            </div>
        );
    };

    const endingSoon = isEndingSoon(days, hours, minutes, seconds, DAY);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Square title="DAYS" value={days} endingSoon={endingSoon} />
            <Square title="HOURS" value={hours} endingSoon={endingSoon} />
            <Square title="MINS" value={minutes} endingSoon={endingSoon} />
            <Square title="SECS" value={seconds} endingSoon={endingSoon} />
        </div>
    );
};

export const renderUntilDone = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    props,
}) => {
    const { accentColour, accentTime, children } = props;

    // Convert hours and minutes to seconds
    seconds = getSeconds(days, hours, minutes, seconds);

    if (!completed) {
        if (accentColour && seconds <= accentTime) {
            return <Box color={accentColour}>{children}</Box>;
        }

        return <>{children}</>;
    }

    return null;
};

export const renderWhenDone = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    props,
}) => {
    const { accentColour, accentTime, children } = props;

    // Convert hours and minutes to seconds
    seconds = getSeconds(days, hours, minutes, seconds);

    if (completed) {
        if (accentColour && seconds <= accentTime) {
            return <Box color={accentColour}>{children}</Box>;
        }

        return <>{children}</>;
    }

    return null;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#eee',
        borderRadius: 200,
        backgroundImage:
            'repeating-linear-gradient( -45deg, #ddd, #ddd 4px, #eee 4px, #eee 11px )',
        backgroundSize: '16px 16px',
        animation: 'background-scroll-left 60s infinite linear',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 0,
        backgroundColor: '#3271E6',
    },
}));
export const renderReverse = ({
    days,
    hours,
    minutes,
    seconds,
    total,
    completed,
    props,
}) => {
    const { auction, rate } = props;

    return (
        <Box marginTop={1}>
            <Box width={250} margin="auto">
                <BorderLinearProgress
                    variant="determinate"
                    value={(total / rate) * 100}
                />
            </Box>
            <ReverseAuctionInfo
                auction={auction}
                days={days}
                hours={hours}
                seconds={seconds}
                total={total}
            />
        </Box>
    );
};

const ReverseAuctionInfo = ({ auction, days, hours, seconds, total }) => {
    let changeIn;
    let format = 'H:mm:ss';

    if (days > 0) {
        changeIn = `${days} day${days > 1 ? 's' : ''}`;
    } else if (seconds != null) {
        if (hours === 0) {
            format = 'm:ss';
        }
        // Get time string (ex. 8:09:54)
        // IMPORTANT: .utc is required, 'total' doesn't have a time zone, so it thinks it's utc
        changeIn = moment.utc(total).format(format);
    }

    return (
        <Box display="flex" marginTop={1.5} justifyContent="center">
            <Typography fontSize={14} marginRight={0.5}>
                Next Price Drop:
            </Typography>
            <Typography
                fontSize={14}
                fontWeight="bold"
                marginRight={0.5}
            >{`$${auction.bidIncrement.toLocaleString()}`}</Typography>
            <Typography fontSize={14} marginRight={0.5}>
                in
            </Typography>
            <Typography fontSize={14} fontWeight="bold">
                {changeIn}
            </Typography>
        </Box>
    );
};

export const renderReverseValue = ({ total, props }) => {
    const { amount } = props;

    return (
        <Typography
            letterSpacing={2}
            fontFamily="urbanist"
            fontWeight="bold"
            marginTop={0.2}
            fontSize={15}
        >
            ${amount?.toLocaleString()}
        </Typography>
    );
};

function Timer({ endDate, renderer, overtime = false, children, ...props }) {
    // Determine time remaining in seconds
    const end = moment(endDate);
    const now = moment();
    let timeRemaining = moment.duration(end.diff(now)).asSeconds();

    return (
        <Countdown
            date={Date.now() + timeRemaining * 1000}
            renderer={renderer}
            onComplete={props.handleComplete}
            overtime={overtime}
            intervalDelay={0}
            precision={3}
            {...props}
        >
            {children}
        </Countdown>
    );
}

const getSeconds = (days, hours, minutes, seconds) => {
    hours += 24 * days;
    minutes += 60 * hours;
    seconds += 60 * minutes;

    return seconds;
};

export default Timer;
