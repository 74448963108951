import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from './SetupForm';
import { Box, Modal, Typography } from '@mui/material';
import ModalCard from '../modal/ModalCard';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

function AddPaymentModal({ success, show, onClose }) {
    const [secret, setSecret] = useState();

    const options = {
        clientSecret: secret,
        appearance: {
            theme: 'stripe',
            variables: {
                borderRadius: '0px',
            },
        },
        locale: 'en-CA',
    };

    useEffect(() => {
        // Get new secret (1 use per sessionn)
        if (show) {
            axios
                .get(`/stripe/secret`)
                .then((res) => {
                    setSecret(res.data);
                })
                .catch(function () {});
        }
        // Reset secret
        else {
            setSecret();
        }
    }, [show]);

    const Stripe = () => {
        if (secret) {
            return (
                <Elements stripe={stripePromise} options={options}>
                    <SetupForm success={success} />
                </Elements>
            );
        } else {
            return <Box height={360} width="100%"></Box>;
        }
    };

    return (
        <Modal
            open={show}
            onClose={onClose}
            onBackdropClick={onClose}
            className="modal-center"
        >
            <ModalCard show={show} onClose={onClose} width={450}>
                <Typography fontSize={22} fontWeight="medium" marginBottom={3}>
                    Add Payment Method
                </Typography>
                <Stripe />
            </ModalCard>
        </Modal>
    );
}

export default AddPaymentModal;
