import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';

import axios from 'axios';
import UserInfo from './myAccount/UserInfo';
import AddressInfo from './myAccount/AddressInfo';
import PaymentInfo from './myAccount/PaymentInfo';
import BrokerInfo from './myAccount/BrokerInfo';
import ViewAllContainer from './ViewAllContainer';
import IdentityInfo from './myAccount/IdentityInfo';
import Loading from '../common/Loading';

function MyAccount() {
    const [userInfo, setUserInfo] = useState();
    const [stripeEnabled, setStripeEnabled] = useState(false);

    useEffect(() => {
        axios
            .get(`/user/getPrivate`)
            .then((res) => {
                setUserInfo(res.data);
            })
            .catch(function () {});

        // Check if stripe is enabled
        axios
            .get(`/stripe/enabled`)
            .then((res) => {
                setStripeEnabled(res.data);
            })
            .catch(function () {});
    }, []);

    // Display loading indication
    if (userInfo === null || userInfo === undefined) {
        return <Loading />;
    }

    return (
        <div className="user-content user-content-width">
            <Grid container spacing={2} paddingBottom={8}>
                <Section title="Account">
                    <UserInfo user={userInfo} setUser={setUserInfo} />
                </Section>
                <Section title="Personal Information">
                    <IdentityInfo user={userInfo} setUser={setUserInfo} />
                </Section>
                {stripeEnabled && (
                    <Section title="Payment">
                        <PaymentInfo />
                    </Section>
                )}
                <Section title="Brokers">
                    <BrokerInfo user={userInfo} setUser={setUserInfo} />
                </Section>
            </Grid>
        </div>
    );
}

const Section = ({ title, children }) => {
    return (
        <Grid item xs={12}>
            <ViewAllContainer title={title}>{children}</ViewAllContainer>
        </Grid>
    );
};

export default MyAccount;
