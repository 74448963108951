import auctionTypes from '../constants/auctionTypes';

const getBidValueHeader = (auction, concluded) => {
    const hasBids = auction.bids.length > 0;
    const sold = auction.sold;

    switch (auction.auctionTypeId) {
        case auctionTypes.STANDARD.id:
            if (hasBids) {
                if (concluded) {
                    if (sold) {
                        return 'Sold For';
                    } else {
                        return 'Highest Bid';
                    }
                } else {
                    return 'Current Bid';
                }
            } else {
                return 'Minimum Bid';
            }
        case auctionTypes.BUY_NOW.id:
            if (sold) {
                return 'Sold For';
            }
            return 'Purchase Price';
        case auctionTypes.REVERSE.id:
            if (concluded) {
                if (sold) {
                    return 'Sold For';
                } else {
                    return 'Starting Price';
                }
            }
            return 'Current Price';
        case auctionTypes.SILENT.id:
            if (concluded) {
                if (sold && auction.winner) {
                    return 'Sold For';
                } else {
                    return null;
                }
            } else {
                if (hasBids) {
                    return 'Your Bid';
                } else {
                    return 'Minimum Bid';
                }
            }
        default:
            return null;
    }
};

const getBidValue = (auction, concluded) => {
    const hasBids = auction.bids.length > 0;
    const sold = auction.sold;

    const bid = hasBids ? auction.bids[0].amount : +auction.minimumBid;

    switch (auction.auctionTypeId) {
        case auctionTypes.REVERSE.id:
            if (concluded && !sold) {
                return `$${auction.startingBid.toLocaleString()}`;
            } else {
                return `$${bid.toLocaleString()}`;
            }
        case auctionTypes.SILENT.id:
            if (concluded && !auction.winner) {
                if (sold) {
                    return 'SOLD';
                } else {
                    return 'NOT SOLD';
                }
            } else {
                return `$${bid.toLocaleString()}`;
            }
        default:
            return `$${bid.toLocaleString()}`;
    }
};

function getPriceString(amount) {
    return `${amount >= 0 ? '' : '-'}$${Math.abs(amount).toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`;
}

function pluralize(str, num, suffix) {
    return `${str}${num > 1 ? 's' : ''}${suffix}`;
}

export { getBidValueHeader, getBidValue, getPriceString, pluralize };
