import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { AccountContext } from '../supertokens/Account';

const WatchButton = ({
    groupId,
    watching: _watching,
    width,
    variant = 'white',
    type = 'shadow',
    className,
}) => {
    const account = useContext(AccountContext);

    const [watching, setWatching] = useState(_watching);
    const toggleWatch = () => {
        if (!account.signedIn) {
            //setLoginModalShow(true);
            return;
        }

        axios
            .post(`/auction/${watching ? 'unfollow' : 'follow'}`, { groupId })
            .then((res) => {
                setWatching(res.data);
            })
            .catch(function () {});
    };

    // Exit if the watching status wasn't retrieved properly
    if (watching === null || watching === undefined) {
        return null;
    }

    return (
        <>
            <Button
                variant={watching ? 'dark' : variant}
                type={type}
                sx={{
                    width: width,
                    zIndex: 30,
                    marginTop: 'auto',
                    marginBottom: 'auto',
                }}
                onClick={toggleWatch}
                className={className}
            >
                <Typography
                    fontSize={16}
                    fontWeight="bold"
                    letterSpacing={1.25}
                    fontFamily="urbanist"
                >
                    {watching ? 'Following' : 'Follow'}
                </Typography>
            </Button>
        </>
    );
};

export default WatchButton;
