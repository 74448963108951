import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import CollapseContainer from '../../components/common/CollapseContainer';
import DynamicIcon from '../../components/common/DynamicIcon';
import { toast } from 'react-toastify';
import PopupForm from '../../components/common/PopupForm';

function Models() {
    const [data, setData] = useState([]);
    const [showHotglue, setShowHotglue] = useState(false);

    useEffect(() => {
        axios
            .get(`/model/getAdmin`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    function pushHotglue() {
        axios
            .get(`/model/updateCRM/${showHotglue}`)
            .catch(function (err) {
                toast.error(`Failed to create job (${err.message})`);
            })
            .finally(function () {
                setShowHotglue(false);
            });
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'identifier',
            headerName: 'Identifier',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'layout',
            headerName: 'Layout',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'project',
            headerName: 'Project',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'hotglue',
            headerName: 'Hotglue',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setShowHotglue(params.row.id);
                };

                return (
                    <IconButton onClick={onClick}>
                        <DynamicIcon icon="Whatshot" colour="#ee3333" />
                    </IconButton>
                );
            },
            sortable: false,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    window.location.href = `/administration/models/create/${params.row.id}`;
                };

                return (
                    <IconButton onClick={onClick}>
                        <EditIcon style={{ color: '#166cdb' }} />
                    </IconButton>
                );
            },
            sortable: false,
        },
    ];

    return (
        <div className="admin-content">
            <CollapseContainer title={`Model List (${data?.length || 0})`}>
                <div style={{ height: 670, width: '100%' }}>
                    <DataGrid
                        rows={data.map((item) => {
                            return {
                                id: item.id,
                                identifier: item.identifier,
                                title: item.title,
                                layout: item.layout,
                                project: item.project.title,
                                createdAt: moment(item.createdAt).format(
                                    `MMM D[,] h:mm A`
                                ),
                            };
                        })}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                    />
                </div>
            </CollapseContainer>
            <PopupForm
                title="Update CRM?"
                body="Are you sure you want to update any enabled CRMs for this model's listings?"
                action={() => pushHotglue()}
                show={showHotglue}
                setShow={setShowHotglue}
            />
        </div>
    );
}

export default Models;
