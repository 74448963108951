import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import auctionTypes from '../../constants/auctionTypes';
import DynamicIcon from '../common/DynamicIcon';

function AuctionTypeIndicator({ auctionTypeId, body, sx }) {
    var auctionType;

    switch (auctionTypeId) {
        case auctionTypes.STANDARD.id:
            auctionType = {
                title: 'Standard Auction',
                description: `This is an auction where every subsequent bid is higher than the previous. The highest bidder wins, and a reserve price is applied.`,
                icon: 'TrendingUp',
                iconColour: 'status.success',
            };
            break;
        case auctionTypes.BUY_NOW.id:
            auctionType = {
                title: 'Buy Now',
                description: `This listing has a fixed price, and the first user to buy it is awarded the listing.`,
                icon: 'ShoppingCartCheckout',
                iconColour: 'status.info',
            };
            break;
        case auctionTypes.REVERSE.id:
            auctionType = {
                title: 'Dutch Auction',
                description: `The price of this auction drops in a given time interval. The first user to grab it wins the auction. When the auction hits the minimum threshold, the listing isn't available for purchase.`,
                icon: 'TrendingDown',
                iconColour: 'status.danger',
            };
            break;
        case auctionTypes.SILENT.id:
            auctionType = {
                title: 'Silent Auction',
                description: `This is an auction where bidders can submit any bid (above minimum) they see fit. Bids are not publicly visible, and the highest bidder at the time of conclusion wins the auction.`,
                icon: 'VisibilityOff',
                iconColour: '#ED9215',
            };
            break;
        default:
            break;
    }

    return (
        <Tooltip
            title={
                <Box padding={2} maxWidth={200} textAlign="center">
                    <Typography fontWeight="medium" fontSize={17} gutterBottom>
                        {auctionType?.title}
                    </Typography>
                    <Typography>{auctionType?.description}</Typography>
                    <Typography>{body}</Typography>
                </Box>
            }
            variant="white"
            placement="top"
            enterTouchDelay={0}
        >
            <IconButton sx={{ marginBottom: 0.25, ...sx }}>
                <DynamicIcon
                    icon={auctionType?.icon}
                    colour={auctionType?.iconColour}
                />
            </IconButton>
        </Tooltip>
    );
}

export default AuctionTypeIndicator;
