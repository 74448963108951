const moment = require('moment');

const getRow = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api.getAllColumns()
        .filter((c) => c.field !== '__check__' && !!c)
        .forEach((c) => {
            thisRow[c.field] = params.row[c.field];
        });

    return thisRow;
};

function getDate(date, time) {
    if (date) {
        return moment(date).format(`MMM DD[,] YYYY${time ? ', h:mm A' : ''}`);
    }
}

module.exports = {
    getRow,
    getDate,
};
