import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AuctionTable from './AuctionTable';
import CardList from '../common/CardList';
import Loading from '../common/Loading';
import PropertyCard from '../cards/PropertyCard';
import ViewAllContainer from './ViewAllContainer';

function BidsAndOffers() {
    const [winningBids, setWinningBids] = useState();
    // const [bids, setBids] = useState();

    const { username } = useParams();

    useEffect(() => {
        axios
            .post(`/auction/getBids/${username}`)
            .then((res) => {
                setWinningBids(res.data);
            })
            .catch(function () {});

        // axios
        //     .post(`/bid/getUser/${username}`)
        //     .then((res) => {
        //         setBids(res.data);
        //     })
        //     .catch(function () {});
    }, [username]);

    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // Display loading indication
    if (!winningBids) {
        return <Loading />;
    }

    const columns = [
        {
            title: 'Listing Name',
            value: ['auction', 'title'],
            width: xs ? 4 : 4,
        },
        {
            title: 'City',
            value: ['auction', 'property', 'address', 'city'],
            width: xs ? 0 : 3,
        },
        {
            title: 'Purchase Amount',
            value: ['amount'],
            width: xs ? 3 : 2,
            type: 'money',
        },
        {
            title: 'Purchase Date/Time',
            value: ['createdAt'],
            width: xs ? 5 : 3,
            type: 'datetime',
        },
    ];

    return (
        <div className="user-content user-content-width">
            <ViewAllContainer
                title="My Purchases"
                length={winningBids?.length}
                hideEmpty
                maxWidth={1}
            >
                <CardList
                    items={winningBids}
                    Component={PropertyCard}
                    lg={6}
                    spacing={6.25}
                    padding={0}
                    noCenter
                />
            </ViewAllContainer>
            {/* <ViewAllContainer title="Bids" length={bids?.length} maxWidth={1}>
                <AuctionTable
                    data={bids}
                    columns={columns}
                    //media={[
                    //    'auction',
                    //    'property',
                    //    'propertyMedia',
                    //    0,
                    //    'media',
                    //    'url',
                    //]}
                    path={['auction', 'slug']}
                />
            </ViewAllContainer> */}
        </div>
    );
}

export default BidsAndOffers;
