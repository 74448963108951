import { Box, CardMedia, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import CardList from '../components/common/CardList';
import CityList from '../components/city/CityList';
import Footer from '../components/common/Footer';
import CategoryCard from '../components/cards/CategoryCard';
import { useParams } from 'react-router-dom';
import PropertyCard from '../components/cards/PropertyCard';
import { setTitle } from '../scripts/style';
import PaddedBox from '../components/common/PaddedBox';
import Loading from '../components/common/Loading';
import NotFound from './NotFound';

function CategoryPage() {
    const [categories, setCategories] = useState();
    const [category, setCategory] = useState();
    const [auctions, setAuctions] = useState();

    const { slug } = useParams();

    setTitle(category ? category.plural : 'Categories');

    useEffect(() => {
        if (slug) {
            axios
                .get(`/propertyType/getBy/${slug}`)
                .then((res) => {
                    setCategory(res.data);
                })
                .catch(function () {});
        }
    }, [slug]);

    useEffect(() => {
        if (category) {
            axios
                .post(`/auction/getCategory/${category.id}`)
                .then((res) => {
                    setAuctions(res.data);
                })
                .catch(function () {});
        }
        if (category || !slug) {
            axios
                .get(`/propertyType/getAll`)
                .then((res) => {
                    // Remove current category from list (if there is one)
                    if (category) {
                        setCategories(
                            res.data.filter((item) => item.id !== category.id)
                        );
                    } else {
                        setCategories(res.data);
                    }
                })
                .catch(function () {});
        }
    }, [category, slug]);

    // Display loading indication
    // Loading a category
    if (slug) {
        if (category === undefined) {
            return <Loading />;
        } else if (category === null) {
            return <NotFound />;
        }
    }
    // Loading category list
    else if (!slug && (categories === null || categories === undefined)) {
        return <Loading />;
    }

    return (
        <Box backgroundColor="tint">
            {slug && category && (
                <CurrentCategory auctions={auctions} category={category} />
            )}
            <div style={{ paddingBottom: 70 }}>
                <AllCategories categories={categories} slug={slug} />
            </div>
            <CityList />
            <Footer />
        </Box>
    );
}

const AllCategories = ({ categories, slug }) => {
    return (
        <div>
            <Typography
                fontSize={35}
                variant="heading"
                textAlign="center"
                sx={{
                    marginBottom: 8,
                    paddingTop: slug ? 6 : 12,
                }}
                component="div"
            >
                {slug ? 'Other Categories' : 'Browse By Category'}
            </Typography>
            <PaddedBox>
                <CardList
                    items={categories}
                    Component={CategoryCard}
                    md={6}
                    lg={6}
                    height={350}
                    spacing={5}
                    padding={0}
                />
            </PaddedBox>
        </div>
    );
};

const CurrentCategory = ({ auctions, category }) => {
    return (
        <div>
            <div style={{ position: 'relative' }}>
                <CardMedia
                    sx={{
                        width: '100%',
                        height: { xs: 150, md: 200, lg: 250 },
                        objectFit: 'cover',
                    }}
                    image={category.media.url}
                />
                <div className="category-page-header-overlay">
                    <Typography
                        sx={{
                            fontSize: { xs: 50, md: 60, lg: 70 },
                        }}
                        color="white"
                        textAlign="center"
                        lineHeight={1}
                        variant="heading"
                    >
                        {category?.plural}
                    </Typography>
                </div>
            </div>
            <PaddedBox>
                <CardList
                    items={auctions}
                    Component={PropertyCard}
                    lg={6}
                    vertical={12.5}
                    spacing={6.25}
                />
            </PaddedBox>
        </div>
    );
};

export default CategoryPage;
