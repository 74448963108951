import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PaddedBox from '../common/PaddedBox';

// Assets
import intro1 from '../../assets/introduction/intro1.svg';
import intro2 from '../../assets/introduction/intro2.svg';
import intro3 from '../../assets/introduction/intro3.svg';
import intro4 from '../../assets/introduction/intro4.svg';

function Introduction() {
    return (
        <PaddedBox backgroundColor="white" top={12} bottom={12}>
            <Box width="100%">
                <Typography
                    textAlign="center"
                    color="dark"
                    fontSize={38}
                    marginBottom={8}
                    variant="heading"
                    component="div"
                >
                    Find Your Perfect Home In 4 Easy Steps
                </Typography>
                <Grid container marginBottom={8} rowSpacing={{ xs: 5, md: 8 }}>
                    <Step
                        image={intro2}
                        text="Access Top Projects"
                        width={250}
                    />
                    <Step image={intro1} text="Secure Your Unit" />
                    <Step
                        image={intro4}
                        text="Sign Electronically"
                        width={250}
                    />
                    <Step
                        image={intro3}
                        text="Pay Your Deposits Online"
                        width={250}
                    />
                </Grid>
            </Box>
        </PaddedBox>
    );
}

const Step = ({ text, image, width = 200 }) => {
    return (
        <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <Box
                component="img"
                sx={{
                    width: 270,
                    height: 200,
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                src={image}
            />
            <Typography
                fontSize={19}
                fontWeight="regular"
                width={width}
                textAlign="center"
                marginLeft="auto"
                marginRight="auto"
                marginBottom="auto"
                fontFamily="urbanist"
                marginTop={4}
            >
                {text}
            </Typography>
        </Grid>
    );
};

export default Introduction;
