import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

import ModalCard from './ModalCard';
import axios from 'axios';

import { usePlaidLink } from 'react-plaid-link';

function IDVModal({ show, onClose, callback }) {
    const [token, setToken] = useState();

    const { open, ready } = usePlaidLink({
        token: token,
        onSuccess: (public_token, metadata) => {
            axios
                .post(`/user/idv/submit`, {
                    sessionId: metadata.link_session_id,
                })
                .then((res) => {
                    if (callback) {
                        callback(res.data);
                    }
                });
        },
    });

    useEffect(() => {
        // Get persona accountId on load
        if (show) {
            setToken(undefined);
            axios.get(`/user/idv/getToken`).then((res) => {
                setToken(res.data);
            });
        }
    }, [show]);

    return (
        <Modal
            open={show}
            onClose={onClose}
            onBackdropClick={onClose}
            className="modal-center"
        >
            <ModalCard show={show} onClose={onClose} width={500} padInside>
                <Typography
                    fontSize={30}
                    fontWeight="bold"
                    paddingBottom={0.5}
                    fontFamily="urbanist"
                >
                    Identity Verification
                </Typography>
                <Box marginBottom={2}>
                    <Typography
                        fontSize={22}
                        fontWeight="bold"
                        paddingBottom={0.5}
                        fontFamily="urbanist"
                    >
                        What's This?
                    </Typography>
                    <Typography fontSize={16} letterSpacing={0.5}>
                        Verifying your identity is required when you buy or sell
                        real estate as part of Canada's FINTRAC regulations.
                    </Typography>
                    <Typography fontSize={16} letterSpacing={0.5}>
                        On Upside, we help you do this in a simple, secure and
                        straightforward way that only takes approximately 2
                        minutes.
                    </Typography>
                </Box>
                <Box marginBottom={2}>
                    <Typography
                        fontSize={22}
                        fontWeight="bold"
                        paddingBottom={0.5}
                        fontFamily="urbanist"
                    >
                        What do I need?
                    </Typography>
                    <Typography fontSize={16} letterSpacing={0.5}>
                        To complete your identity verification, you'll need the
                        following:
                    </Typography>
                    <Typography className="bullets" component="ul">
                        <li>
                            A government-issued photo ID (such as a driver's
                            license or passport)
                        </li>
                        <li>
                            A smartphone camera or webcam (we can help you
                            easily transition to another device)
                        </li>
                    </Typography>
                </Box>
                <Box marginBottom={4}>
                    <Typography fontSize={16} letterSpacing={0.5}>
                        All information you provide as part of the process is
                        transmitted securely, and your information will never be
                        shared or sold by us to unauthorized third parties
                        without your consent. Upside fully complies with all
                        privacy and information protection laws in Canada
                        (PIPEDA).
                    </Typography>
                </Box>
                <Button
                    variant="dark"
                    fullWidth
                    onClick={() => {
                        onClose();
                        // Open the IDV popup on a delay. It remembers the 'scroll' state of <body>
                        // It needs to not be in the modal state that causes it to be 'scroll: hidden'
                        setTimeout(function () {
                            open();
                        }, 100);
                    }}
                    disabled={!ready || !token}
                    sx={{ marginTop: 1 }}
                >
                    Get Started
                </Button>
            </ModalCard>
        </Modal>
    );
}

export default IDVModal;
