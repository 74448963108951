import React from 'react';
import mediaTypes from '../../constants/mediaTypes';
import DynamicIcon from '../common/DynamicIcon';
import { Box } from '@mui/material';

const GalleryMedia = ({
    media,
    i,
    thumbnail,
    selectedIndex,
    setSelectedIndex,
    handleOpen = () => {},
}) => {
    const type = media.mediaTypeId;

    const thumbnailClass = `thumbnail-image ${
        selectedIndex === i && 'thumbnail-selected'
    }`;

    // Image
    if (type === mediaTypes.IMAGE) {
        return thumbnail ? (
            <img
                key={i}
                className={thumbnailClass}
                src={media.url}
                onClick={() => {
                    setSelectedIndex(i);
                }}
                alt={media.url}
            />
        ) : (
            <img
                className="auction-image"
                src={media.url}
                onClick={handleOpen}
                alt={media.url}
            />
        );
    }

    // Embedded YouTube Video
    if (type === mediaTypes.VIDEO) {
        return thumbnail ? (
            <span
                key={i}
                className={thumbnailClass}
                style={{ position: 'relative' }}
            >
                <Box
                    component="span"
                    width="100%"
                    height="139px"
                    backgroundColor="#00000055"
                    sx={{
                        position: 'absolute',
                        pointerEvents: 'none',
                    }}
                />
                <DynamicIcon
                    icon="PlayCircle"
                    sx={{
                        position: 'absolute',
                        left: 'calc(50% - 25px)',
                        top: 'calc(50% - 22px)',
                        pointerEvents: 'none',
                    }}
                    size={50}
                    colour="white"
                />
                <img
                    className={thumbnailClass}
                    src={`http://img.youtube.com/vi/${media.url}/0.jpg`}
                    onClick={() => {
                        setSelectedIndex(i);
                    }}
                    alt={media.url}
                />
            </span>
        ) : (
            <iframe
                className="auction-image"
                src={`https://www.youtube.com/embed/${media.url}?rel=0&enablejsapi=1"`} /*&autoplay=1*/
                title={media.url}
            />
        );
    }

    return <></>;
};

export default GalleryMedia;
