const auctionTypes = {
    STANDARD: { id: 1, title: 'Standard' },
    BUY_NOW: { id: 2, title: 'Buy Now' },
    REVERSE: { id: 3, title: 'Reverse' },
    SILENT: { id: 4, title: 'Silent' },
};

export const auctionTypeList = [
    { id: 1, title: 'Standard' },
    { id: 2, title: 'Buy Now' },
    { id: 3, title: 'Reverse' },
    { id: 4, title: 'Silent' },
];

export const auctionTypesById = {
    1: 'Standard',
    2: 'Buy Now',
    3: 'Dutch',
    4: 'Silent',
};

export default auctionTypes;
