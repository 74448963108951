import { useContext } from 'react';
import NotFound from '../../pages/NotFound';
import { AccountContext } from '../supertokens/Account';

function RequireUser({ username, children, onLoad }) {
    const account = useContext(AccountContext);

    return account.user.username === username ? (
        children
    ) : (
        <NotFound onLoad={onLoad} />
    );
}

export default RequireUser;
