import YesIcon from '@mui/icons-material/CheckCircle';
import NoIcon from '@mui/icons-material/Cancel';
import moment from 'moment';

function booleanCell(params) {
    return params.value ? (
        <YesIcon style={{ color: '#22aa22' }} />
    ) : (
        <NoIcon style={{ color: '#ee3333' }} />
    );
}

function dateCell(params) {
    return moment(params.value).format(`MMM D YYYY[,] h:mm A`);
}

export { booleanCell, dateCell };
