import React from 'react';
import { Box, Typography } from '@mui/material';
import DocumentIcon from '@mui/icons-material/FindInPageOutlined';

function FileCard({ item: file, height, setMedia }, props) {
    const media = file.media;

    return (
        <Box
            height={height || 235}
            border="1px solid #707070"
            backgroundColor="#f8f8f8"
            padding={3}
            display="flex"
            flexDirection="column"
            style={{ cursor: 'pointer' }}
            onClick={() => setMedia(media)}
        >
            <Typography fontSize={22} fontWeight="medium" marginBottom={3}>
                {media.mediaType.title}
            </Typography>
            <Typography fontSize={16} fontWeight="light" className="clamp3">
                {media.title}
            </Typography>
            <Box display="flex" marginTop="auto" fontSize={35}>
                <Typography
                    fontSize={16}
                    fontWeight="medium"
                    flex={1}
                    marginTop="auto"
                    sx={{ textDecoration: 'underline' }}
                >
                    View Document
                </Typography>
                <DocumentIcon fontSize="inherit" />
            </Box>
        </Box>
    );
}

export default FileCard;
