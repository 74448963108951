import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import CollapseContainer from '../common/CollapseContainer';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip, IconButton, Box, Button } from '@mui/material';
import { getRow } from '../../scripts/table';
import PopupForm from '../common/PopupForm';
import { useYup } from '../../scripts/cms';
import { toast } from 'react-toastify';
import InputForm from '../common/InputForm';

function ReservedUsernames() {
    const [reserved, setReserved] = useState([]);
    const [row, setRow] = useState();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const inputs = [[{ id: 'username' }]];
    const yup = useYup(inputs);

    useEffect(() => {
        axios
            .get(`/user/getReservedUsernames`)
            .then((res) => {
                setReserved(res.data);
            })
            .catch(function () {});
    }, []);

    function handleCreateUsername() {
        axios
            .post(`/user/createReservedUsername`, yup.getValues())
            .then((res) => {
                setReserved([...reserved, res.data]);
                yup.setValue('username', '');
            })
            .catch(function (err) {
                let message = err.message;
                if (err.response && err.response.data) {
                    message = err.response.data;
                }
                toast.error(message);
            });
    }

    const handleDeleteUsername = (row) => {
        axios
            .post(`/user/deleteReservedUsername`, { id: row.id })
            .then((res) => {
                setReserved(reserved.filter((a) => a.id !== row.id));
                setShowDeleteDialog(false);
            })
            .catch(function (err) {
                let message = err.message;
                if (err.response && err.response.data) {
                    message = err.response.data;
                }
                toast.error(message);
            });
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setRow(getRow(e, params));
                    setShowDeleteDialog(true);
                };

                return (
                    <Tooltip title="Reject comment">
                        <IconButton onClick={onClick}>
                            <DeleteIcon style={{ color: '#ee3333' }} />
                        </IconButton>
                    </Tooltip>
                );
            },
            sortable: false,
        },
    ];

    return (
        <div>
            <CollapseContainer title="Reserved Usernames">
                <div style={{ height: 645, width: '100%' }}>
                    <DataGrid
                        rows={reserved.map((item) => {
                            return {
                                id: item.id,
                                username: item.username,
                                createdAt: moment(item.createdAt).format(
                                    `MMM D[,] h:mm A`
                                ),
                            };
                        })}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                    />
                </div>
                <Box padding={2}>
                    <InputForm yup={yup} inputs={inputs} />
                    <Button
                        fullWidth
                        variant="dark"
                        onClick={handleCreateUsername}
                    >
                        Create Reserved Username
                    </Button>
                </Box>
            </CollapseContainer>
            <PopupForm
                title="Delete username?"
                body={
                    <div>
                        Are you sure you want to delete this reserved username?
                    </div>
                }
                action={() => handleDeleteUsername(row)}
                show={showDeleteDialog}
                setShow={setShowDeleteDialog}
            />
        </div>
    );
}

export default ReservedUsernames;
