const analytics = (window.analytics = window.analytics || []);

const identify = (uuid, traits) => {
    if (process.env.REACT_APP_ENV === 'production') {
        if (uuid) {
            analytics.identify(uuid, traits);
        } else {
            analytics.identify(traits);
        }
    }
};

const track = (event, properties) => {
    if (process.env.REACT_APP_ENV === 'production') {
        analytics.track(event, properties);
    }
};

export { identify, track };
