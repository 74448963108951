import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import DragArea from './media/DragArea';

function AuctionFormReference({ watch, slug }) {
    const [otherProperties, setOtherProperties] = useState();
    const [modelMedia, setModelMedia] = useState([]);
    const [projectMedia, setProjectMedia] = useState([]);

    const addressId = watch('addressId');
    const propertyId = watch('propertyId');
    const modelId = watch('modelId');
    const projectId = watch('projectId');

    useEffect(() => {
        setModelMedia([]);
        if (modelId) {
            axios
                .get(`/model/getMedia/${modelId}`)
                .then((res) => {
                    setModelMedia(res.data);
                })
                .catch(function () {});
        }
    }, [modelId]);

    useEffect(() => {
        setProjectMedia([]);
        if (projectId) {
            axios
                .get(`/project/getMedia/${projectId}`)
                .then((res) => {
                    setProjectMedia(res.data);
                })
                .catch(function () {});
        }
    }, [projectId]);

    function loadOtherPropertyMedia() {
        setOtherProperties([]);
        axios
            .post(`/address/getRelatedMedia/${addressId}`)
            .then((res) => {
                var properties = res.data.properties;

                // Remove current property from list
                if (propertyId) {
                    properties = properties.filter((a) => a.id !== propertyId);
                }

                setOtherProperties(properties);
            })
            .catch(function () {
                setOtherProperties();
            });
    }

    return (
        <Box
            height="calc(100vh - 100px)"
            top={0}
            backgroundColor="white"
            className="auction-media"
            padding={2}
            overflow="auto"
        >
            <Typography fontSize={18} textAlign="center">
                Existing Media
            </Typography>
            <DragArea
                id="project-media"
                source={projectMedia}
                title="Project Media"
                cols={3}
            />
            <hr />
            <DragArea
                id="model-media"
                source={modelMedia}
                title="Model Media"
                cols={3}
            />
            {!otherProperties && (
                <Button
                    variant="dark"
                    size="small"
                    fullWidth
                    disabled={!(addressId && ((slug && propertyId) || !slug))}
                    onClick={() => loadOtherPropertyMedia()}
                >
                    Load Other Listings
                </Button>
            )}
            <OtherProperties properties={otherProperties} />
        </Box>
    );
}

const OtherProperties = ({ properties }) => {
    if (!properties || properties.length === 0) {
        return null;
    }

    return (
        <>
            <hr />
            <Typography fontSize={18} textAlign="center">
                Other Listings
            </Typography>
            {properties.map((property) => (
                <DragArea
                    key={property.id}
                    id={`property-media-${property.id}`}
                    source={property.propertyMedia}
                    title={`${property.unit ? `${property.unit} - ` : ''}${
                        property.auction.title
                    }`}
                    cols={3}
                    link={`/listing/${property.auction.slug}`}
                />
            ))}
        </>
    );
};

export default AuctionFormReference;
